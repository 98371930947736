import { Button, styled as MUIStyled } from '@mui/material'
import Loading from 'components/Loading'
import ProtectedPage from 'components/ProtectedPage'
import { CLIENT_USER_TYPES, HTTP_STATUS } from 'constants/common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getClientUserDetail } from 'services/api'
import { isSuccessRequest } from 'utils/common'
import { BrokerDetail } from './BrokerDetail'
import { BuyerShipperDetail } from './BuyerShipperDetail'

let fetchingData = false

const UserDetailPage = MUIStyled(({ className }) => {
  const { userType, userId } = useParams()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [userData, setUserData] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const fetchUserDetail = async () => {
      setUserData(null)
      fetchingData = true
      try {
        const response = await getClientUserDetail(userId, accessToken)
        if (isSuccessRequest(response.status)) {
          setUserData(response.data.data)
        }
      } catch (error) {
        if (error?.response?.status === HTTP_STATUS.NOT_FOUND) {
          navigate('/404', { replace: true })
        } else {
          navigate('/500', { replace: true })
        }
      }
      fetchingData = false
    }

    if (accessToken && userId && !fetchingData) {
      fetchUserDetail()
    }
  }, [userId, accessToken, navigate])

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  return (
    <ProtectedPage
      className={['user-detail-page', className].join(' ')}
      breadcrumbs={[
        {
          path: `/users${userType ? `?type=${userType.toLocaleUpperCase()}` : ''}`,
          label: t('users_management_page.title'),
        },
        { label: t(`user_detail_page.${userType?.toUpperCase()}_title`) },
      ]}
      title={
        <>
          <div className="main-title">{t(`user_detail_page.${userType?.toUpperCase()}_title`)}</div>
          <Button variant="contained" color="primary" onClick={handleBackButtonClick}>
            {t('user_detail_page.back_button_label')}
          </Button>
        </>
      }
    >
      {userData === null && <Loading />}
      {userType?.toUpperCase() === CLIENT_USER_TYPES.BROKER && userData && <BrokerDetail userData={userData} />}
      {userType?.toUpperCase() === CLIENT_USER_TYPES.BUYER_SHIPPER && userData && (
        <BuyerShipperDetail userData={userData} />
      )}
    </ProtectedPage>
  )
})({
  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiButton-root': {
      textTransform: 'none',
    },
  },
  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',

    '& .session': {
      '& .session-title': {
        backgroundColor: 'rgba(130, 151, 224, 0.33)',
        color: 'rgba(0, 0, 0, 0.79)',
        fontWeight: 600,
        fontSize: 20,
        padding: '12px 24px',
      },

      '& .session-content': {
        padding: '12px 36px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,

        '& .field-item': {
          display: 'flex',
          columnGap: 24,

          '& .field-label': {
            fontWeight: 500,
            fontSize: 16,
            width: 260,
          },
        },
      },
    },
  },
})

export default UserDetailPage
