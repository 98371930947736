import { Search } from '@mui/icons-material'
import { Button, MenuItem, styled as MUIStyled, TextField } from '@mui/material'
import DatePicker from 'components/DatePicker'
import ProtectedPage from 'components/ProtectedPage'
import StyledTable from 'components/StyledTable'
import { HTTP_STATUS } from 'constants/common'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getSubadminActivityLogs } from 'services/api'
import { isSuccessRequest } from 'utils/common'

const PAGE_SIZE = 10
const defaultFilters = {
  page: 1,
  start_date: null,
  end_date: null,
  controller: 'all',
  query: '',
}
const TABLE_COLUMNS = [
  { label: 'subadmin_activity_logs_page.index_column_label', key: 'index' },
  { label: 'subadmin_activity_logs_page.timestamp_column_label', key: 'createdAt' },
  { label: 'subadmin_activity_logs_page.section_column_label', key: 'controller' },
  { label: 'subadmin_activity_logs_page.activity_column_label', key: 'action' },
]
let fetchingActivityLogs = false

export const AdminActivityLogsPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('subadmin_activity_logs_page.title')
  const { adminId } = useParams()
  const [filters, setFilters] = useState(defaultFilters)
  const [pageData, setPageData] = useState({})
  const [fetchingData, setFetchingData] = useState(false)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const disabledClearFilters =
    Object.entries(filters).filter(([key, value]) => key !== 'page' && Boolean(value)).length === 0

  useEffect(() => {
    const fetchAdminActivityLogs = async () => {
      fetchingActivityLogs = true
      setFetchingData(true)
      try {
        const queryStr = Object.entries(filters)
          .filter(
            ([key, value]) =>
              (key === 'page' && value > 1) ||
              (key === 'controller' && value !== 'all') ||
              (key !== 'page' && key !== 'controller' && Boolean(value))
          )
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&')
        const response = await getSubadminActivityLogs(`${adminId}${queryStr ? `?${queryStr}` : ''}`, accessToken)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch (error) {
        if (error?.response?.status === HTTP_STATUS.NOT_FOUND) {
          navigate('/404', { replace: true })
        } else {
          navigate('/500', { replace: true })
        }
      }
      fetchingActivityLogs = false
      setFetchingData(false)
    }

    if (adminId && accessToken && !fetchingActivityLogs) {
      fetchAdminActivityLogs()
    }
  }, [accessToken, adminId, navigate, filters])

  const handleFilterChange = (filterName) => (event, value) => {
    let newValue

    switch (filterName) {
      case 'page':
        newValue = { ...filters, [filterName]: value }
        break

      case 'start_date':
      case 'end_date':
        newValue = { ...filters, [filterName]: value, page: 1 }
        break

      case 'query':
        newValue = { ...filters, [filterName]: event.target.value, page: 1 }
        break

      default:
        newValue = filters
    }

    setFilters(newValue)
  }

  const handleClearFilters = () => {
    setFilters(defaultFilters)
  }

  const renderColumn = (name, value, record) => {
    switch (name) {
      case 'createdAt':
        return dayjs(value).format('MM/DD/YYYY hh:mm A')

      case 'controller':
        return t(`sidebar_menu.${value}_menu_item_label`)

      default:
        return value
    }
  }

  if (!adminId) {
    navigate('/404', { replace: true })
    return null
  }

  return (
    <ProtectedPage
      title={
        <>
          <div className="main-title">{title}</div>
          <Button variant="contained" href="/subadmins">
            {t('subadmin_activity_logs_page.back_button_label')}
          </Button>
        </>
      }
      breadcrumbs={[{ label: t('subadmins_management_page.title'), path: '/subadmins' }, { label: title }]}
      className={['subadmins-activity-logs-page', className].join(' ')}
    >
      <div className="top-page">
        <div className="filters-wrapper">
          <TextField
            InputProps={{ startAdornment: <Search /> }}
            placeholder={t('subadmin_activity_logs_page.filter_input_placeholder')}
            onChange={handleFilterChange('query')}
            value={filters.query}
          />
          <div className="date-filters">
            <div className="date-field from-field">
              <div className="field-label">{t('subadmin_activity_logs_page.filter_by_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('start_date')}
                value={filters.start_date}
              />
            </div>
            <div className="date-field">
              <div className="field-label"> {t('subadmin_activity_logs_page.filter_to_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('end_date')}
                value={filters.end_date}
                minDate={filters.start_date ? dayjs(filters.start_date) : undefined}
              />
            </div>
            <TextField select value={filters.controller}>
              <MenuItem value="all">{t('subadmin_activity_logs_page.all_section_label')}</MenuItem>
            </TextField>
            <Button variant="contained" color="primary" onClick={handleClearFilters} disabled={disabledClearFilters}>
              {t('subadmin_activity_logs_page.clear_filters_button_label')}
            </Button>
          </div>
        </div>
      </div>
      <StyledTable
        columns={TABLE_COLUMNS}
        loading={fetchingData}
        data={pageData.data?.map((row, index) => ({ ...row, index: index + 1 }))}
        pagination={{
          page: filters.page,
          count: Math.ceil((pageData.pagination?.total || 0) / PAGE_SIZE),
        }}
        onPageChange={handleFilterChange('page')}
        renderColumn={renderColumn}
        emptyMessage={t('subadmin_activity_logs_page.empty_message')}
      />
    </ProtectedPage>
  )
})({
  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiButton-root': {
      textTransform: 'none',
    },
  },

  '& .top-page': {
    display: 'flex',
    columnGap: 60,
    justifyContent: 'space-between',
    position: 'relative',

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },

    '& .filters-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1200,
      rowGap: 16,

      '& .date-filters': {
        display: 'flex',
        columnGap: 16,

        '& .date-field': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
          flex: 1,

          '&.from-field .field-label': {
            minWidth: 75,
          },

          '& .date-picker': {
            flex: 1,

            '& .MuiFormControl-root': {
              flex: 1,
              width: '100%',
            },
          },
        },
      },

      '& input': {
        padding: '12px 14px 12px 14px',
        height: 24,
      },

      '& .MuiButton-root': {
        borderWidth: 2,
        fontWeight: 500,
      },
    },

    '& .add-wrapper': {
      display: 'flex',
      alignItems: 'flex-end',

      '& .MuiButton-root': {
        height: 56,
        fontWeight: 600,
      },
    },
  },

  '& .table-col-index': {
    width: 50,
    textAlign: 'center',
  },

  '& .table-col-createdat': {
    width: 250,
    textAlign: 'center',
  },
})
