import { Button, MenuItem, styled as MUIStyled } from '@mui/material'
import { routesConfig } from 'constants/routesConfig'
import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const SidebarItems = [
  {
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    label: 'Users Management',
    url: '/users',
  },
  {
    label: 'RFQs Management',
    url: '/rfqs',
  },
  {
    label: 'Reviews Management',
    url: '/reviews',
  },
  {
    label: 'Subscriptions Management',
    url: '/subscriptions',
  },
  {
    label: 'Payments/Transaction',
    url: '/transactions',
  },
  {
    label: 'Notification Management',
    url: '/notifications',
  },
  {
    label: 'Subadmins Management',
    url: '/subadmins',
  },
  {
    label: 'Static Content Management',
    url: '/static-contents',
  },
]

const SideBar = MUIStyled(({ className }) => {
  const { pathname } = useLocation()
  const { user } = useSelector((state) => state.auth, shallowEqual)

  const userPermissions = useMemo(() => {
    return user?.permissions?.map((perm) => perm.name) || []
  }, [user])

  const routeMap = useMemo(() => {
    let map = {}
    routesConfig.forEach((route) => {
      map[route.path] = route
    })

    return map
  }, [])

  const renderItems = useMemo(() => {
    return SidebarItems.filter(
      (item) =>
        userPermissions.includes('ALL') ||
        (routeMap[item.url]?.permission && userPermissions.includes(routeMap[item.url].permission))
    )
  }, [routeMap, userPermissions])

  return (
    <div className={['sidebar', className].join(' ')}>
      {renderItems.map((item, index) => (
        <MenuItem
          key={`sidebar-item-${index}`}
          className={pathname.substring(1).indexOf(item.url.substring(1)) === 0 ? 'active' : ''}
        >
          <Button href={item.url}>{item.label}</Button>
        </MenuItem>
      ))}
    </div>
  )
})({
  width: 340,
  minWidth: 340,
  maxWidth: 340,
  backgroundColor: '#fff',

  '& .MuiMenuItem-root': {
    borderBottom: '1px solid #A0A0A0',

    '& a': {
      fontSize: 16,
      lineHeight: 1.25,
    },

    '&.active': {
      backgroundColor: '#90AFFF',

      '& a': {
        color: '#fff',
        fontWeight: 600,
      },
    },
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export default SideBar
