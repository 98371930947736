import { Delete } from '@mui/icons-material'
import { Button, Dialog, IconButton, InputLabel, styled as MUIStyled } from '@mui/material'
import { EditIcon } from 'components/Icons'
import ProtectedPage from 'components/ProtectedPage'
import StyledInput from 'components/StyledInput'
import StyledTable from 'components/StyledTable'
import TextareaAutosize from 'components/TextareaAutoSize'
import { MESSAGE_TYPES } from 'constants/common'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { layoutActions } from 'redux/slices/layoutSlice'
import { deleteSubscriptionFeature, getSubscriptionFeatures, saveSubscriptionFeature } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'
import * as Yup from 'yup'

const AddEditFeatureDialog = MUIStyled(({ className, data, ...other }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('form_validation.required_field')),
    code: Yup.string().required(t('form_validation.required_field')),
  })
  const { highestSubscriptionFeatureOrder } = useSelector((state) => state.layout, shallowEqual)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const { name, description, code, order } = data || {}

  const { errors, handleSubmit, handleChange, setFieldValue, touched, values } = useFormik({
    initialValues: {
      name: name || '',
      description: description || '',
      code: code || '',
      order: order || highestSubscriptionFeatureOrder + 1,
      changedCode: (name || '').replace(/[^A-Z0-9]+/gi, '_') !== (code || ''),
    },
    validationSchema,
    onSubmit: async ({ changedCode, ...submittedValues }, formActions) => {
      formActions.setSubmitting(true)

      try {
        const response = await saveSubscriptionFeature(data?.id, submittedValues, accessToken)
        if (isSuccessRequest(response.status)) {
          if (!data) {
            formActions.resetForm()
          }

          dispatch(
            createMessage({
              type: MESSAGE_TYPES.SUCCESS,
              content: t(`subscription_features_management_page.${data ? 'update' : 'add'}_feature_success`),
            })
          )

          if (typeof other.onSuccess === 'function') {
            other.onSuccess()
          }
        }
      } catch {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.ERROR,
            content: t(`subscription_features_management_page.${data ? 'update' : 'add'}_feature_failed`),
          })
        )
      }

      if (data && typeof other.onClose === 'function') {
        other.onClose()
      }

      formActions.setSubmitting(false)
    },
  })

  useEffect(() => {
    if (!data) {
      setFieldValue('order', highestSubscriptionFeatureOrder + 1)
    }
  }, [highestSubscriptionFeatureOrder, data, setFieldValue])

  const handleFieldChange = (name) => (event) => {
    if (name === 'name' && !values.changedCode) {
      setFieldValue('code', event.target.value.replace(/[^A-Z0-9]+/gi, '_').toUpperCase())
    } else if (name === 'code') {
      setFieldValue('changedCode', true)
    }
    handleChange(event)
  }

  return (
    <Dialog {...other} className={['add-edit-feature-dialog', className].join(' ')}>
      <form onSubmit={handleSubmit}>
        <div className="form-title">
          {t(`subscription_features_management_page.${data ? 'edit' : 'add'}_feature_dialog_title`)}
        </div>
        <StyledInput
          name="name"
          onChange={handleFieldChange('name')}
          value={values.name}
          label={t('subscription_features_management_page.featue_name_field_label')}
          error={errors.name && touched.name}
          helperText={touched.name && errors.name}
        />
        <StyledInput
          name="code"
          onChange={handleFieldChange('code')}
          value={values.code}
          label={t('subscription_features_management_page.featue_code_field_label')}
          error={errors.code && touched.code}
          helperText={touched.code && errors.code}
        />
        <div className="form-input-wrapper">
          <InputLabel htmlFor="feature-description">
            {t('subscription_features_management_page.featue_description_field_label')}
          </InputLabel>
          <TextareaAutosize
            name="description"
            onChange={handleFieldChange('description')}
            value={values.description}
            id="feature-description"
            minRows={5}
          />
        </div>
        <StyledInput
          name="order"
          onChange={handleFieldChange('order')}
          value={values.order}
          label={t('subscription_features_management_page.featue_order_field_label')}
          type="number"
          inputProps={{ min: 0 }}
        />
        <div className="actions-wrapper">
          <Button variant="outlined" color="primary" onClick={other.onClose}>
            {t('subscription_features_management_page.cancel_feature_button_label')}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {t('subscription_features_management_page.save_feature_button_label')}
          </Button>
        </div>
      </form>
    </Dialog>
  )
})({
  '& form': {
    minWidth: 600,
    padding: '40px 24px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,

    '& .form-title': {
      fontSize: 20,
      fontWeight: 700,
    },

    '& .form-input-wrapper': {
      display: 'flex',
      columnGap: 16,

      '& label': {
        fontWeight: 500,
        minWidth: 100,
      },

      '& .textarea-autosize-field': {
        flex: 1,
      },
    },

    '& .actions-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      columnGap: 24,

      '& .MuiButton-root': {
        textTransform: 'none',
        width: 100,
      },
    },
  },
})

const DeleteFeatureConfirmDialog = MUIStyled(({ className, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dialog open {...other} className={['confirm-delete-feature-dialog', className].join(' ')}>
      <div className="message-wrapper">
        <div className="confirm-message">
          {t('subscription_features_management_page.delete_feature_confirm_message')}
        </div>
        <div className="sub-message">{t('subscription_features_management_page.delete_feature_sub_message')}</div>
      </div>
      <div className="actions-wrapper">
        <Button color="primary" variant="contained" onClick={other.onClose}>
          {t('subscription_features_management_page.delete_feature_dialog_cancel_button_label')}
        </Button>
        <Button color="error" variant="contained" onClick={other.onConfirm}>
          {t('subscription_features_management_page.delete_feature_dialog_delete_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },

  '& .message-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    alignItems: 'center',
  },

  '& .confirm-message': {
    fontSize: 24,
    fontWeight: 600,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 24,
    justifyContent: 'center',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: 100,
  },
})

const TABLE_COLUMNS = [
  {
    label: 'subscription_features_management_page.index_column_label',
    key: 'index',
  },
  {
    label: 'subscription_features_management_page.featue_name_field_label',
    key: 'name',
  },
  {
    label: 'subscription_features_management_page.featue_code_field_label',
    key: 'code',
  },
  {
    label: 'subscription_features_management_page.featue_description_field_label',
    key: 'description',
  },
  {
    label: 'subscription_features_management_page.actions_column_label',
    key: 'actions',
  },
]

export const SubscriptionFeaturesManagementPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const [addingEditingNewFeature, setAddingEditingNewFeature] = useState({ open: false, editing: null })
  const [fetchingData, setFetchingData] = useState(true)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [pageData, setPageData] = useState([])
  const [deletingFeature, setDeletingFeature] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchingTime, setFetchingTime] = useState(Date.now())

  useEffect(() => {
    const fetchSubscriptionFeatures = async () => {
      setFetchingData(true)
      try {
        const response = await getSubscriptionFeatures(accessToken)
        if (isSuccessRequest(response.status)) {
          const features = response.data.data?.sort((a, b) => a.order - b.order)
          setPageData(features)
          dispatch(
            layoutActions.updateHighestSubscriptionFeatureOrder(Math.max(features.at(-1)?.order || 0, features.length))
          )
        }
      } catch {
        navigate('/500')
      }
      setFetchingData(false)
    }

    if (accessToken) {
      fetchSubscriptionFeatures()
    }
  }, [accessToken, navigate, fetchingTime, dispatch])

  const handleCloseDialog = () => {
    setAddingEditingNewFeature({ open: false, editing: null })
  }

  const handleAddNewFeatureClick = () => {
    setAddingEditingNewFeature({ open: true, editing: null })
  }

  const handleEditFeatureClick = (feature) => () => {
    setAddingEditingNewFeature({ open: true, editing: feature })
  }

  const handleDeleteFeatureClick = (feature) => () => {
    setDeletingFeature(feature)
  }

  const handleCloseDeleteDialog = () => {
    setDeletingFeature(null)
  }

  const handleConfirmDeleteFeature = async () => {
    handleCloseDeleteDialog()

    try {
      const response = await deleteSubscriptionFeature(deletingFeature.id, accessToken)
      if (isSuccessRequest(response.status)) {
        setFetchingTime(Date.now())
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t('subscription_features_management_page.delete_feature_success'),
          })
        )
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t('subscription_features_management_page.delete_feature_failed'),
        })
      )
    }
  }

  const handleSaveFeatureSuccess = () => {
    setFetchingTime(Date.now())
  }

  const renderColumn = (name, value, record) => {
    if (name === 'actions') {
      return (
        <div className="actions-wrapper">
          <IconButton color="primary" onClick={handleEditFeatureClick(record)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" onClick={handleDeleteFeatureClick(record)}>
            <Delete />
          </IconButton>
        </div>
      )
    }
    return value
  }

  return (
    <ProtectedPage
      title={
        <>
          <div className="main-title">{t('subscription_features_management_page.title')}</div>
          <Button variant="contained" color="primary" onClick={handleAddNewFeatureClick}>
            {t('subscription_features_management_page.add_button_label')}
          </Button>
        </>
      }
      breadcrumbs={[
        { label: t('subscriptions_management_page.title'), path: '/subscriptions' },
        { label: t('subscription_features_management_page.title') },
      ]}
      className={['subscription-features-management-page', className].join(' ')}
    >
      <StyledTable
        columns={TABLE_COLUMNS}
        data={pageData?.map((record, index) => ({ ...record, index: index + 1 }))}
        renderColumn={renderColumn}
        emptyMessage={t('subscription_features_management_page.empty_features_message')}
        loading={fetchingData}
      />
      {addingEditingNewFeature.open && (
        <AddEditFeatureDialog
          open
          onClose={handleCloseDialog}
          data={addingEditingNewFeature.editing}
          onSuccess={handleSaveFeatureSuccess}
        />
      )}

      {deletingFeature && (
        <DeleteFeatureConfirmDialog onClose={handleCloseDeleteDialog} onConfirm={handleConfirmDeleteFeature} />
      )}
    </ProtectedPage>
  )
})({
  '& .MuiButton-root': {
    textTransform: 'none',
  },

  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',
  },
})
