import { Button, FormHelperText, InputLabel, MenuItem, styled as MUIStyled, TextField } from '@mui/material'
import DatePicker from 'components/DatePicker'
import { MultipleInput } from 'components/MultipleInput'
import FilePicker from 'components/FilePicker'
import ProfilePicture from 'components/ProfilePicture'
import ProtectedPage from 'components/ProtectedPage'
import StyledInput from 'components/StyledInput'
import TextareaAutosize from 'components/TextareaAutoSize'
import { CLIENT_USER_TYPES, HTTP_STATUS, MESSAGE_TYPES, SERVED_LANGUAGE_OPTIONS } from 'constants/common'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AddressInput } from 'components/AddressInput'
import { RegionsServicedInput } from 'components/RegionServicedInput'
import { HandlingTypesInput } from 'components/HandlingTypesInput'
import { CargoTypesInput } from 'components/CargoTypesInput'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getClientUserDetail, saveClientDetail } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'
import dayjs from 'dayjs'
import Loading from 'components/Loading'

let inputUpdateTimer = -1
let fetchingData = false

export const BrokerFormPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const { userId } = useParams()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const [fetchingUserData, setFetchingUserData] = useState(Boolean(userId))

  const validationSchema = Yup.object().shape({
    emails: Yup.array()
      .of(
        Yup.string()
          .email(t('form_validation.email_invalid_format'))
          .max(50, t('form_validation.max_length', { max: 50 }))
      )
      .unique(t('form_validation.dupicated_email'))
      .test({
        name: 'primary-check',
        message: t('form_validation.required_field'),
        test: function (value) {
          const { createError, path } = this
          return (value.length > 0 && value[0]) || createError({ path: `${path}.0` })
        },
      }),
    company_info: Yup.object().shape({
      contact_name: Yup.string()
        .max(50, t('form_validation.max_length', { max: 50 }))
        .required(t('form_validation.required_field')),
      phones: Yup.array()
        .of(
          Yup.string()
            .phone(t('form_validation.phone_invalid_format'))
            .max(15, t('form_validation.max_length', { max: 15 }))
        )
        .unique(t('form_validation.dupicated_phone')),
      faxes: Yup.array()
        .of(
          Yup.string()
            .phone(t('form_validation.fax_invalid_format'))
            .max(15, t('form_validation.max_length', { max: 15 }))
        )
        .unique(t('form_validation.dupicated_fax')),
      language_served: Yup.array().of(Yup.string()).min(1, t('form_validation.required_field')),
      company_name: Yup.string()
        .max(150, t('form_validation.max_length', { max: 150 }))
        .required(t('form_validation.required_field')),
      tax: Yup.string()
        .alphaNumeric(t('form_validation.tax_id_invalid'))
        .max(15, t('form_validation.max_length', { max: 15 }))
        .required(t('form_validation.required_field')),
      registration_number: Yup.string()
        .alphaNumeric(t('form_validation.registration_number_invalid'))
        .max(15, t('form_validation.max_length', { max: 15 })),
      registration_docs: Yup.array()
        .acceptFileTypes(
          ['.pdf', '.jpg', '.jpeg', '.png'],
          t('form_validation.invalid_file_types', { types: ['.pdf', '.jpg', '.jpeg', '.png'].join(', ') })
        )
        .test('check-min-1-file', t('form_validation.required_field'), function (val = []) {
          const { createError, path } = this
          const realFiles = val.filter((f) => Boolean(f.url))

          return realFiles.length > 0 || createError(path)
        }),
      started_on: Yup.string()
        .test('test-date', t('form_validation.invalid_format_date'), (value) => {
          return !value || !isNaN(Date.parse(value))
        })
        .required(t('form_validation.required_field')),
      cargo_types: Yup.array().min(1, t('form_validation.unselected_cargo_types')),
      region_serviced: Yup.array().of(Yup.object()).min(1, t('You have select at least 01 country serviced.')),
    }),
    address: Yup.object().shape({
      address: Yup.string().required(t('form_validation.required_field')),
      country: Yup.string().required(t('form_validation.required_field')),
      city: Yup.string().required(t('form_validation.required_field')),
    }),
    profile_picture: Yup.string().test(
      'check-file-type',
      t('form_validation.invalid_file_types', { types: ['.jpg', '.jpeg', '.png'].join(', ') }),
      (value) => {
        const fileExtention = value?.match(/data:(\w+)\/([\w+]+);base64,/)
        return !value || !fileExtention || ['jpg', 'jpeg', 'png'].includes(fileExtention[2]?.toLowerCase())
      }
    ),
  })

  const { handleSubmit, setFieldValue, setValues, values, errors, touched, isSubmitting } = useFormik({
    initialValues: {
      emails: [],
      company_info: {
        company_name: '',
        tax: '',
        registration_number: '',
        started_on: '',
        registration_docs: [{}],
        general_information: '',
        language_served: [],
        contact_name: '',
        phones: [],
        faxes: [],
        cargo_types: [],
        handing_types: [],
        region_serviced: [],
      },
      address: {
        address: '',
        country: '',
        state: '',
        city: '',
        postal_code: '',
      },
      profile_picture: '',
    },
    validationSchema,
    onSubmit: async ({ emails, address, company_info, profile_picture }, formActions) => {
      formActions.setSubmitting(true)

      try {
        let userData = {
          email: emails[0],
          company_info: {
            ...company_info,
            ...address,
            registration_docs: company_info.registration_docs.filter((doc) => Boolean(doc.url?.trim())),
            emails: emails.slice(1).filter((email) => Boolean(email.trim())),
            phones: company_info.phones.filter((phone) => Boolean(phone.trim())),
            faxes: company_info.faxes.filter((fax) => Boolean(fax.trim())),
          },
          profile_picture,
          role: !userId && CLIENT_USER_TYPES.BROKER,
        }

        const response = await saveClientDetail(userId, userData, accessToken)
        if (isSuccessRequest(response.status)) {
          dispatch(
            createMessage({
              type: MESSAGE_TYPES.SUCCESS,
              content: t(`broker_form_page.${!userId ? 'create' : 'update'}_user_success`),
            })
          )
          navigate('/users')
        }
      } catch (error) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.ERROR,
            content: t(`broker_form_page.${!userId ? 'create' : 'update'}_user_failed`),
          })
        )
      }
    },
  })

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        fetchingData = true
        const response = await getClientUserDetail(userId, accessToken)
        fetchingData = false

        if (isSuccessRequest(response.status)) {
          const { company_info, profile_picture, email } = response.data.data
          const { address, country, state, city, postal_code, emails, ...other } = company_info

          setValues({
            emails: [email, ...emails],
            profile_picture,
            address: { address, country, state, city, postal_code },
            company_info: other,
          })
        }
      } catch (error) {
        if (error?.response?.status === HTTP_STATUS.NOT_FOUND) {
          navigate('/404', { replace: true })
        } else {
          navigate('/500')
        }
      }
      setFetchingUserData(false)
    }
    if (accessToken && userId && !fetchingData) {
      fetchUserDetail()
    }
  }, [userId, accessToken, setValues, navigate])

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  const handleFieldChange = (name) => (event, changedValue) => {
    switch (name) {
      case 'company_info.contact_name':
      case 'company_info.general_information':
      case 'company_info.company_name':
      case 'company_info.tax':
      case 'company_info.registration_number':
        if (inputUpdateTimer > 0) {
          clearTimeout(inputUpdateTimer)
        }
        inputUpdateTimer = setTimeout(() => {
          setFieldValue(name, event.target.value)
        }, 500)
        break

      case 'address':
      case 'emails':
      case 'company_info.phones':
      case 'company_info.faxes':
      case 'company_info.started_on':
        inputUpdateTimer = setTimeout(() => {
          setFieldValue(name, changedValue)
        }, 500)
        break

      case 'company_info.registration_docs':
      case 'company_info.cargo_types':
      case 'company_info.handing_types':
      case 'company_info.region_serviced':
        setFieldValue(name, changedValue)
        break

      case 'profile_picture':
        setFieldValue(name, changedValue)
        break

      case 'company_info.language_served':
        setFieldValue(name, event.target.value)
        break

      default:
        break
    }
  }

  return (
    <ProtectedPage
      title={
        <>
          <div className="main-title">{t(`broker_form_page.${!userId ? 'add' : 'edit'}_title`)}</div>
          <Button variant="contained" color="primary" onClick={handleBackButtonClick}>
            {t('broker_form_page.back_button_label')}
          </Button>
        </>
      }
      breadcrumbs={[
        { label: t('users_management_page.title'), path: '/users?type=BROKER' },
        { label: t(`broker_form_page.${!userId ? 'add' : 'edit'}_title`) },
      ]}
      className={['broker-form-page', className].join(', ')}
    >
      {(fetchingUserData || isSubmitting) && <Loading />}
      {!fetchingUserData && (
        <form onSubmit={handleSubmit}>
          <div className="session contact-info-session">
            <div className="session-title">{t('broker_form_page.contact_info_session_title')}</div>
            <div className="session-content">
              <div className="column left-col">
                <StyledInput
                  onChange={handleFieldChange('company_info.contact_name')}
                  value={values.company_info.contact_name}
                  label={t('broker_form_page.contact_name_field_label')}
                  helperText={touched.company_info?.contact_name && errors.company_info?.contact_name}
                  error={errors.company_info?.contact_name && touched.company_info?.contact_name}
                />
                <MultipleInput
                  name="emails"
                  onChange={handleFieldChange('emails')}
                  value={values.emails}
                  label={t('broker_form_page.email_field_label')}
                  error={touched.emails && errors.emails}
                  primaryLegend={t('broker_form_page.primary_email_legend')}
                />
                <MultipleInput
                  name="company_info.phones"
                  onChange={handleFieldChange('company_info.phones')}
                  value={values.company_info.phones}
                  label={t('broker_form_page.phone_field_label')}
                  setFirstAsPrimary={false}
                  error={touched.company_info?.phones && errors.company_info?.phones}
                />
                <MultipleInput
                  name="company_info.faxes"
                  onChange={handleFieldChange('company_info.faxes')}
                  value={values.company_info.faxes}
                  label={t('broker_form_page.fax_field_label')}
                  setFirstAsPrimary={false}
                  error={touched.company_info?.faxes && errors.company_info?.faxes}
                />
                <AddressInput
                  label={t('broker_form_page.address_field_label')}
                  onChange={handleFieldChange('address')}
                  error={touched.address && errors.address}
                  value={values.address}
                />
                <div className="form-input-wrapper align-center">
                  <InputLabel htmlFor="broker-general-info">
                    {t('broker_form_page.languages_served_field_label')}
                  </InputLabel>
                  <div className="input-wrapper">
                    <TextField
                      select
                      name="company_info.language_served"
                      SelectProps={{ multiple: true }}
                      value={values.company_info.language_served}
                      onChange={handleFieldChange('company_info.language_served')}
                      error={
                        Boolean(errors.company_info?.language_served) && Boolean(touched.company_info?.language_served)
                      }
                      helperText={touched.company_info?.language_served && errors.company_info?.language_served}
                    >
                      {Object.keys(SERVED_LANGUAGE_OPTIONS).map((lang) => (
                        <MenuItem key={`lang-opt-${lang}`} value={lang}>
                          {t(`served_language.${lang}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
              <div className="column right-col">
                <div className="profile-picture-field">
                  <ProfilePicture
                    editing
                    onChange={handleFieldChange('profile_picture')}
                    src={values.profile_picture}
                  />
                  {touched.profile_picture && errors.profile_picture && (
                    <FormHelperText error>{touched.profile_picture && errors.profile_picture}</FormHelperText>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="session company-info-session">
            <div className="session-title">{t('broker_form_page.company_info_session_title')}</div>
            <div className="session-content">
              <div className="column left-col">
                <StyledInput
                  name="company_info.company_name"
                  onChange={handleFieldChange('company_info.company_name')}
                  value={values.company_info.company_name}
                  label={t('broker_form_page.company_name_field_label')}
                  helperText={touched.company_info?.company_name && errors.company_info?.company_name}
                  error={errors.company_info?.company_name && touched.company_info?.company_name}
                />
                <StyledInput
                  name="company_info.tax"
                  onChange={handleFieldChange('company_info.tax')}
                  value={values.company_info.tax}
                  label={t('broker_form_page.tax_number_field_label')}
                  helperText={touched.company_info?.tax && errors.company_info?.tax}
                  error={errors.company_info?.tax && touched.company_info?.tax}
                />
                <StyledInput
                  name="company_info.registration_number"
                  onChange={handleFieldChange('company_info.registration_number')}
                  value={values.company_info.registration_number}
                  label={t('broker_form_page.registration_number_field_label')}
                  helperText={touched.company_info?.registration_number && errors.company_info?.registration_number}
                  error={errors.company_info?.registration_number && touched.company_info?.registration_number}
                />
                <div className="form-input-wrapper align-center">
                  <InputLabel htmlFor="broker-general-info">
                    {t('broker_form_page.formatted_on_field_label')}
                  </InputLabel>
                  <div className="input-wrapper">
                    <DatePicker
                      name="company_info.started_on"
                      onChange={handleFieldChange('company_info.started_on')}
                      value={values.company_info.started_on}
                      maxDate={dayjs()}
                    />
                    {touched.company_info?.started_on && errors.company_info?.started_on && (
                      <FormHelperText error>
                        {touched.company_info.started_on && errors.company_info.started_on}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                <div className="form-input-wrapper align-center">
                  <InputLabel htmlFor="broker-general-info">
                    {t('broker_form_page.registration_docs_field_label')}
                  </InputLabel>
                  <div className="input-wrapper">
                    <FilePicker
                      name="company_info.registration_docs"
                      onChange={handleFieldChange('company_info.registration_docs')}
                      accept=".pdf,.jpg,.png,.jpeg"
                      error={touched.company_info?.registration_docs && errors.company_info?.registration_docs}
                      value={values.company_info.registration_docs}
                    />
                  </div>
                </div>
                <div className="form-input-wrapper">
                  <InputLabel htmlFor="broker-general-info">
                    {t('broker_form_page.general_information_field_label')}
                  </InputLabel>
                  <div className="input-wrapper">
                    <TextareaAutosize
                      name="company_info.general_information"
                      onChange={handleFieldChange('company_info.general_information')}
                      minRows={5}
                      value={values.company_info.general_information}
                      id="broker-general-info"
                    />
                  </div>
                </div>
                <div className="form-input-wrapper flex-column">
                  <InputLabel htmlFor="broker-general-info">{t('broker_form_page.cargo_types_field_label')}</InputLabel>
                  <div className="input-wrapper">
                    <CargoTypesInput
                      error={touched.company_info?.cargo_types && errors.company_info?.cargo_types}
                      onChange={handleFieldChange('company_info.cargo_types')}
                      value={values.company_info.cargo_types}
                    />
                  </div>
                </div>
                <div className="form-input-wrapper flex-column">
                  <InputLabel htmlFor="broker-general-info">
                    {t('broker_form_page.handling_types_field_label')}
                  </InputLabel>
                  <div className="input-wrapper">
                    <HandlingTypesInput
                      onChange={handleFieldChange('company_info.handing_types')}
                      value={values.company_info.handing_types}
                    />
                  </div>
                </div>
                <div className="form-input-wrapper flex-column">
                  <InputLabel htmlFor="broker-general-info">
                    {t('broker_form_page.regions_serviced_field_label')}
                  </InputLabel>
                  <div className="input-wrapper">
                    <RegionsServicedInput
                      onChange={handleFieldChange('company_info.region_serviced')}
                      value={values.company_info.region_serviced}
                    />
                    {touched.company_info?.region_serviced && errors.company_info?.region_serviced && (
                      <FormHelperText error>{errors.company_info?.region_serviced}</FormHelperText>
                    )}
                  </div>

                  <div className="actions-wrapper">
                    <Button variant="contained" color="primary" type="submit">
                      {t('broker_form_page.save_button_label')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',
  },
  '& .MuiButton-root': {
    textTransform: 'none',
  },

  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .form-input-wrapper': {
    display: 'flex',
    columnGap: 16,

    '& > label': {
      width: 260,
      fontSize: 18,
      fontWeight: 600,
      whiteSpace: 'break-spaces',
    },

    '& .input-wrapper': {
      flex: 1,

      '& .MuiTextField-root': {
        minWidth: 250,
      },
    },

    '&.align-center': {
      alignItems: 'center',
    },

    '&.flex-column': {
      flexDirection: 'column',
      rowGap: 12,
    },

    '& .MuiSelect-select': {
      paddingTop: 12.5,
      paddingBottom: 12.5,
    },
  },

  '& .session': {
    '& .session-title': {
      backgroundColor: 'rgba(130, 151, 224, 0.33)',
      fontWeight: 600,
      fontSize: 20,
      padding: '12px 24px',
    },

    '& .session-content': {
      display: 'flex',
      padding: '16px 32px',

      '& .column': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 24,
        flex: 1,

        '&.right-col': {
          alignItems: 'center',
        },
      },
    },

    '&.company-info-session': {
      '& .session-content': {
        maxWidth: 1200,
      },
    },
  },

  '& .actions-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,

    '& .MuiButton-root': {
      width: 200,
    },
  },
})
