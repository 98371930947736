import { Search } from '@mui/icons-material'
import { Button, FormControlLabel, Radio, RadioGroup, styled as MUIStyled, TextField } from '@mui/material'
import DatePicker from 'components/DatePicker'
import ProtectedPage from 'components/ProtectedPage'
import StyledTable from 'components/StyledTable'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { getRefundRequests } from 'services/api'
import { isSuccessRequest } from 'utils/common'
import { PaymentPageHeader } from './PageHeader'
import { RefundingRequestDialog } from './RefundingRequestDialog'

const FILTER_PERIODS = {
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_3MONTHS: 'LAST_3MONTHS',
  LAST_6MONTHS: 'LAST_6MONTHS',
  LAST_1YEAR: 'LAST_1YEAR',
}

const defaultFilters = {
  start_date: '',
  end_date: '',
  query: '',
  page: 1,
  period: FILTER_PERIODS.CURRENT_MONTH,
  queryTime: Date.now(),
}
const PAGE_SIZE = 10
const TABLE_COLUMNS = [
  {
    label: 'refund_request_page.transaction_id_column_label',
    key: 'id',
  },
  {
    label: 'refund_request_page.broker_name_column_label',
    key: 'user.company_info.company_name',
  },
  {
    label: 'refund_request_page.broker_email_column_label',
    key: 'user.email',
  },
  {
    label: 'refund_request_page.membership_column_label',
    key: 'type',
  },

  {
    label: 'refund_request_page.expired_date_column_label',
    key: 'expiresAt',
  },
  {
    label: 'refund_request_page.remaining_days_column_label',
    key: 'remain_date',
  },
  {
    label: 'refund_request_page.refund_amount_column_label',
    key: 'refund_amount',
  },
  {
    label: 'refund_request_page.action_column_label',
    key: '_action',
  },
]

const generateQueryString = ({ start_date, end_date, period, page, query }, exportCSV = false) => {
  let queries = []
  if (query) {
    queries.push(`name=${encodeURIComponent(query)}`)
  }
  if (page > 1) {
    queries.push(`page=${page}`)
  }
  let start_date_query = start_date
  if (!start_date && !end_date) {
    switch (period) {
      case FILTER_PERIODS.CURRENT_MONTH:
        start_date_query = dayjs().startOf('month').format('MM/DD/YYYY')
        break

      case FILTER_PERIODS.LAST_3MONTHS:
        start_date_query = dayjs().subtract(3, 'months').format('MM/DD/YYYY')
        break

      case FILTER_PERIODS.LAST_6MONTHS:
        start_date_query = dayjs().subtract(6, 'months').format('MM/DD/YYYY')
        break
      case FILTER_PERIODS.LAST_1YEAR:
        start_date_query = dayjs().subtract(1, 'years').format('MM/DD/YYYY')
        break

      default:
        break
    }
  }
  if (start_date_query) {
    queries.push(`start_date=${encodeURIComponent(start_date_query)}`)
  }
  if (end_date) {
    queries.push(`end_date=${encodeURIComponent(end_date)}`)
  }

  if (exportCSV) {
    queries.push('export=true')
  }

  if (queries.length > 0) {
    return `?${queries.join('&')}`
  }

  return ''
}

export const RefundRequestsPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const title = t('Refund Requests')
  const [filters, setFilters] = useState(defaultFilters)
  const [pageData, setPageData] = useState({})
  const [fetchingData, setFetchingData] = useState(false)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [refundingTransaction, setRefundingTransaction] = useState(null)

  useEffect(() => {
    const fetchTransaction = async () => {
      setPageData({})
      setFetchingData(true)
      try {
        const queryStr = generateQueryString(filters)
        const response = await getRefundRequests(queryStr, accessToken)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch {}
      setFetchingData(false)
    }

    if (accessToken) {
      fetchTransaction()
    }
  }, [accessToken, filters])

  const handleFilterChange = (filterName) => (event, value) => {
    let newValue

    switch (filterName) {
      case 'page':
        newValue = { ...filters, [filterName]: value }
        break

      case 'start_date':
      case 'end_date':
        newValue = { ...filters, [filterName]: value, page: 1 }
        break

      case 'query':
      case 'period':
        newValue = { ...filters, [filterName]: event.target.value, page: 1 }
        break

      default:
        newValue = filters
    }

    setFilters(newValue)
  }

  const handleRefundButtonClick = (transaction) => () => {
    setRefundingTransaction(transaction)
  }

  const handleRefundSuccess = () => {
    setFilters({ ...filters })
  }

  const renderColumn = (name, value, record) => {
    switch (name) {
      case 'id':
        return value.substring(value.length - 8)

      case 'type':
        return t(`subscription_plan.${value}`)

      case 'transaction_type':
        return t(`transaction_type.${value}`)

      case 'expiresAt':
        return dayjs(value).format('MM/DD/YYYY HH:mm A')

      case 'remain_date':
        return value

      case 'refund_amount':
        return `$${value?.toFixed(2) || 0}`

      case '_action':
        return (
          <Button
            variant="contained"
            onClick={handleRefundButtonClick(record)}
            disabled={record.status === 'SUCCESS' || record.status === 'REJECTED'}
          >
            {t('refund_request_page.refund_button_label')}
          </Button>
        )

      default:
        return value
    }
  }

  const handleCloseRefundingDialog = () => {
    setRefundingTransaction(null)
  }

  return (
    <ProtectedPage
      title={title}
      breadcrumbs={[{ label: t('transactions_page.title'), path: '/transactions' }, { label: title }]}
      className={['transactions-page', className].join(' ')}
    >
      <PaymentPageHeader type="REFUND_REQUESTS" />
      <div className="top-page">
        <div className="row">
          <TextField
            className="search-input"
            InputProps={{ startAdornment: <Search /> }}
            placeholder={t('transactions_page.filter_input_placeholder')}
            onChange={handleFilterChange('query')}
            value={filters.query}
          />
        </div>
        <div className="field-label filter-label">{t('transactions_page.filter_by_label')}</div>
        <div className="filters-wrapper">
          <div className="period-filters">
            <div className="field-label">{t('transactions_page.filter_time_period_label')}:</div>
            <RadioGroup onChange={handleFilterChange('period')}>
              {Object.keys(FILTER_PERIODS).map((period) => (
                <FormControlLabel
                  key={`filter-option-${period}`}
                  control={<Radio checked={filters.period === period} />}
                  label={t(`transactions_page.${period}_period_option_label`)}
                  value={period}
                />
              ))}
            </RadioGroup>
          </div>
          <div className="row apply-row">
            <div className="date-filters">
              <div className="date-field from-field">
                <div className="field-label">{t('transactions_page.filter_dates_label')}:</div>
                <DatePicker
                  inputFormat={'MM/DD/YYYY'}
                  views={['year', 'month', 'day']}
                  onChange={handleFilterChange('start_date')}
                  value={filters.start_date}
                />
              </div>
              <div className="date-field">
                <div className="field-label"> {t('transactions_page.filter_to_label')}:</div>
                <DatePicker
                  inputFormat={'MM/DD/YYYY'}
                  views={['year', 'month', 'day']}
                  onChange={handleFilterChange('end_date')}
                  value={filters.end_date}
                  minDate={filters.start_date ? dayjs(filters.start_date) : undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyledTable
        columns={TABLE_COLUMNS}
        loading={fetchingData}
        data={pageData.data?.filter((row) => Boolean(row.user)).map((row, index) => ({ ...row, index: index + 1 }))}
        pagination={{
          page: filters.page,
          count: Math.ceil((pageData.pagination?.total || 0) / PAGE_SIZE),
        }}
        onPageChange={handleFilterChange('page')}
        renderColumn={renderColumn}
        emptyMessage={t('transactions_page.empty_message')}
      />
      {refundingTransaction && (
        <RefundingRequestDialog
          open={Boolean(refundingTransaction)}
          transaction={refundingTransaction}
          onClose={handleCloseRefundingDialog}
          onSuccess={handleRefundSuccess}
        />
      )}
    </ProtectedPage>
  )
})({
  '& .MuiButton-root': {
    textTransform: 'none',
  },

  '& .top-page': {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },

    '& .filters-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      padding: 24,

      '& .period-filters': {
        display: 'flex',
        columnGap: 32,
        alignItems: 'center',

        '& .MuiFormGroup-root': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 16,
          flexDirection: 'row',
        },
      },

      '& .date-filters': {
        display: 'flex',
        columnGap: 16,

        '& .date-field': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
          flex: 1,

          '&.from-field .field-label': {
            minWidth: 75,
          },

          '& .date-picker': {
            flex: 1,

            '& .MuiFormControl-root': {
              flex: 1,
              width: '100%',
            },
          },
        },
      },

      '& input': {
        padding: '12px 14px 12px 14px',
        height: 24,
      },

      '& .MuiButton-root': {
        borderWidth: 2,
        fontWeight: 500,
      },
    },

    '& .add-wrapper': {
      display: 'flex',
      alignItems: 'flex-end',

      '& .MuiButton-root': {
        height: 56,
        fontWeight: 600,
      },
    },
  },

  '& .row': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .filter-label': {
    marginTop: 24,
  },

  '& .search-input': {
    minWidth: '50%',
  },

  '& .table-col-type, .table-col-expiresat, .table-col-remain-date, .table-col-refund-amount, .table-col--action': {
    textAlign: 'center',
  },

  '& .table-wrapper': {
    maxWidth: 'calc(100vw - 440px)',
    overflowX: 'auto',
  },
})
