import { styled as MUIStyled } from '@mui/material'
import { routesConfig } from 'constants/routesConfig'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authActions } from '../redux/slices/authSlice'
import Breadcrumbs from './Breadbrumbs'
import Header from './Header'
import SideBar from './SideBar'

let checkNewNotificationTimer = -1
const stopCheckingNewNotification = () => {
  if (checkNewNotificationTimer > 0) {
    clearInterval(checkNewNotificationTimer)
    checkNewNotificationTimer = -1
  }
}

const ProtectedPage = MUIStyled(({ children, className, title, breadcrumbs = [] }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userPermissions = user?.permissions?.map((perm) => perm.name)

  const routeMap = useMemo(() => {
    let map = {}
    routesConfig.forEach((route) => {
      map[route.path] = route
    })

    return map
  }, [])

  useEffect(() => {
    if (!user) {
      stopCheckingNewNotification()
      navigate('/login', { replace: true })
    } else {
      dispatch(authActions.checkNewNotifications())
      checkNewNotificationTimer = setInterval(() => {
        dispatch(authActions.checkNewNotifications())
      }, 10000)

      if (
        routeMap[window.location.pathname]?.permissionCheck &&
        !userPermissions.includes('ALL') &&
        !userPermissions.includes(routeMap[window.location.pathname].permission)
      ) {
        navigate('/404', { replace: true })
      }
    }

    return stopCheckingNewNotification
  }, [user, navigate, dispatch, routeMap, userPermissions])

  if (user) {
    return (
      <div className={['protected-page', className].join(' ')}>
        <Header />
        <div className="page-body">
          <SideBar />
          <div className="page-content">
            <Breadcrumbs data={breadcrumbs} />
            <div className="content-wrapper">
              <div className="page-title">{title}</div>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
})({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  background:
    'linear-gradient(0deg, rgba(33, 59, 128, 0.45) 18.23%, rgba(18, 43, 106, 0.45) 36.78%, rgba(24, 55, 115, 0.45) 57.88%, rgba(18, 28, 118, 0.45) 83.03%)',

  '& .page-body': {
    display: 'flex',
    flex: 1,
    width: '100%',

    '& .page-content': {
      flex: 1,
      padding: '12px 24px 24px 24px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 12,

      '& .page-title': {
        fontSize: 36,
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: 12,
      },

      '& .content-wrapper': {
        backgroundColor: '#fff',
        borderRadius: 8,
        flex: 1,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 24,
      },
    },
  },
})

export default ProtectedPage
