import { FormHelperText, styled as MUIStyled, TextareaAutosize as MUITextareaAutosize } from '@mui/material'
import { useEffect, useState } from 'react'

const TextareaAutosize = MUIStyled(({ className, helperText, error, onChange, value, ...others }) => {
  const [renderValue, setRenderValue] = useState(value || '')

  useEffect(() => {
    setRenderValue(value || '')
  }, [value])

  const handleInputChange = (event) => {
    setRenderValue(event.target.value)

    if (typeof onChange === 'function') {
      onChange(event)
    }
  }

  return (
    <div className={['textarea-autosize-field', error ? 'error' : '', className].join(' ')}>
      <MUITextareaAutosize {...others} onChange={handleInputChange} value={renderValue} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  )
})({
  '& .MuiFormHelperText-root': {
    margin: 0,
  },

  '& textarea': {
    padding: '12px 14px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 16,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    width: '100%',
  },

  '&.error': {
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
    '& textarea': {
      borderColor: '#d32f2f !important',
    },
  },
})

export default TextareaAutosize
