import { Notifications as NotificationsIcon } from '@mui/icons-material'
import { IconButton, styled as MUIStyled } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'

const Notifications = MUIStyled(({ className }) => {
  const { newNotifications } = useSelector((state) => state.auth, shallowEqual)
  return (
    <div className={['notifications', className].join(' ')}>
      <IconButton href="/my-notifications">
        <NotificationsIcon />
        {newNotifications > 0 && <div className="new-notifications"></div>}
      </IconButton>
    </div>
  )
})({
  '& a': {
    padding: 0,
    position: 'relative',

    '& svg': {
      fontSize: 40,
    },
  },

  '& .new-notifications': {
    width: 10,
    height: 10,
    backgroundColor: 'red',
    borderRadius: 8,
    position: 'absolute',
    top: 6,
    right: 6,
  },
})

export default Notifications
