import { REHYDRATE } from 'redux-persist'
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { authActions } from 'redux/slices/authSlice'
import { layoutActions } from 'redux/slices/layoutSlice'
import { getCountries, getLocodeCountriesList, getStaticPages } from 'services/api'
import { isSuccessRequest } from 'utils/common'

function* showMessage(action) {
  try {
    yield delay(5000)
    yield put(layoutActions.hideMessage(action.payload.id))
  } catch {}
}

function* fetchStaticPages(action) {
  try {
    const { accessToken } = yield select((state) => state.auth)
    if (accessToken) {
      const response = yield getStaticPages(accessToken)
      if (isSuccessRequest(response.status)) {
        let pagesMap = {}
        response.data.data.forEach((page) => {
          pagesMap[page.path] = page
        })
        yield put(layoutActions.loadStaticPagesSuccess(pagesMap))
      }
    }
  } catch {}
}

function* fetchCountries() {
  try {
    const { accessToken } = yield select((state) => state.auth)
    if (accessToken) {
      const response = yield call(getCountries, accessToken)
      if (isSuccessRequest(response.status)) {
        const countries = response.data.data
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          ?.map(({ region, subregion, ...other }) => ({ ...other, continent: region, region: subregion }))
        yield put(layoutActions.fetchCountriesSuccess(countries))
      }
    }
  } catch {}
}

function* fetchLocodeCountries() {
  try {
    const response = yield getLocodeCountriesList()
    if (isSuccessRequest(response?.status)) {
      yield put(
        layoutActions.fetchLocodeCountriesSuccess(
          response.data.data
            .filter(({ region, subregion }) => region && subregion)
            .map((country) => {
              let { region, subregion, ...other } = country

              return {
                ...other,
                continent: region,
                region: subregion,
                label: other.name,
                value: other.name,
              }
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        )
      )
    }
  } catch {}
}

export default function* layoutSaga() {
  yield takeEvery(layoutActions.showMessage.type, showMessage)
  yield takeEvery(REHYDRATE, fetchStaticPages)
  yield takeEvery(REHYDRATE, fetchCountries)
  yield takeEvery(REHYDRATE, fetchLocodeCountries)
  yield takeLatest(authActions.loginSuccess.type, fetchCountries)
}
