import { Button, styled as MUIStyled } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { StarIcon } from './Icons'

const RatingField = MUIStyled(({ className, value, editable, onChange, name, size }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const inputRef = useRef(null)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleSelectStar = (value) => () => {
    if (editable) {
      setCurrentValue(value + 1)
      if (typeof onChange === 'function') {
        setTimeout(() => {
          onChange(inputRef.current)
        }, 200)
      }
    }
  }

  return (
    <div className={['rating-field', className].join(' ')}>
      {!editable &&
        Array.from(Array(5)).map((val, index) => (
          <div
            className={['star-item', index >= currentValue ? 'inactive' : ''].join(' ')}
            key={`start-item-${index}`}
            onClick={handleSelectStar(index)}
          >
            <StarIcon {...size} />
          </div>
        ))}
      {editable && (
        <>
          {Array.from(Array(5)).map((val, index) => (
            <Button
              className={['star-item', index >= currentValue ? 'inactive' : ''].join(' ')}
              key={`start-item-${index}`}
              onClick={handleSelectStar(index)}
            >
              <StarIcon {...(size || { width: 24, height: 24 })} />
            </Button>
          ))}
          <input hidden value={currentValue} name={name} ref={inputRef} />
        </>
      )}
    </div>
  )
})({
  display: 'flex',
  columnGap: 10,
  alignItems: 'center',

  '& svg': {
    color: '#FFC400',
  },

  '& .star-item': {
    padding: 0,
    margin: 0,
    minWidth: 0,

    '&.inactive svg': {
      color: 'rgba(166, 166, 176, 0.6)',
    },
  },
})

export default RatingField
