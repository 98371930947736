import { Add, Delete } from '@mui/icons-material'
import { FormHelperText, IconButton, InputLabel, OutlinedInput, styled as MUIStyled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const generateDefaultValues = (values, single, enablePrimary) => {
  if (single) {
    return values?.length > 0 ? values : ['']
  }

  return values?.length > 0 ? values : enablePrimary ? ['', ''] : ['']
}

let typed = false

export const MultipleInput = MUIStyled(
  ({
    className,
    label,
    name,
    type,
    error,
    readOnly,
    maxItems,
    value,
    setFirstAsPrimary = true,
    primaryLegend,
    single = false,
    ...other
  }) => {
    const [renderValues, setRenderValues] = useState(generateDefaultValues(value, single, setFirstAsPrimary))
    const { t } = useTranslation()

    const handleAddMoreClick = () => {
      setRenderValues([...renderValues, ''])
    }

    const handleDeleteItemClick = (index) => () => {
      setRenderValues([...renderValues.slice(0, index), ...renderValues.slice(index + 1)])
    }

    useEffect(() => {
      if (!typed) {
        setRenderValues(generateDefaultValues(value, single, setFirstAsPrimary))
      }
    }, [value, setFirstAsPrimary, single])

    const handleInputChange = (index) => (event) => {
      typed = true
      const newValues = [...renderValues.slice(0, index), event.target.value, ...renderValues.slice(index + 1)]
      setRenderValues(newValues)

      if (typeof other.onChange === 'function') {
        other.onChange(null, newValues)
      }
    }

    let inputProps = {
      name,
      type,
      fullWidth: true,
      readOnly,
      ...other,
    }

    return (
      <div className={['form-input-wrapper', className].join(' ')}>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <div className="controller-wrapper">
          <div className="inputs-wrapper">
            {renderValues.map((renderValue, index) => (
              <div
                key={`email-input-${className}-${index}`}
                className={['input-wrapper', error && error[index] ? 'error' : '', readOnly ? 'read-only' : ''].join(
                  ' '
                )}
              >
                <div className="main-input">
                  <OutlinedInput
                    id={name}
                    {...inputProps}
                    value={renderValue || ''}
                    onChange={handleInputChange(index)}
                    error={error && Boolean(error[index])}
                  />
                  {setFirstAsPrimary && index === 0 && (
                    <span className="primary-legend">({primaryLegend || t('multiple_input.primary_legend')})</span>
                  )}
                  {(!setFirstAsPrimary || 0 < index) && index < renderValues.length - 1 && (
                    <IconButton color="error" onClick={handleDeleteItemClick(index)}>
                      <Delete />
                    </IconButton>
                  )}
                  {index === renderValues.length - 1 && (
                    <IconButton
                      className="add-more-button"
                      onClick={handleAddMoreClick}
                      disabled={maxItems && renderValues.length === maxItems}
                    >
                      <Add />
                    </IconButton>
                  )}
                </div>
                {error && error[index] && <FormHelperText>{error[index]}</FormHelperText>}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
)(({ theme }) => ({
  display: 'flex',
  columnGap: 16,

  '& label': {
    fontWeight: 500,
    lineHeight: '40px',
    minWidth: 100,
  },

  '& .MuiInputBase-root': {
    flex: 1,

    '&:not(.MuiInputBase-multiline)': {
      height: 40,

      '& input': {
        padding: '8px 14px',
      },
    },
  },

  '& .controller-wrapper': {
    display: 'flex',
    columnGap: 8,
    flex: 1,

    '& .inputs-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 12,
    },

    '& .add-more-button': {
      '& svg': {
        border: '2px solid rgba(0, 0, 0, 0.54)',
        borderRadius: 4,
      },
    },
  },

  '& .input-wrapper': {
    flex: 1,

    '& .main-input': {
      display: 'flex',
      columnGap: 8,
      alignItems: 'center',
    },

    '&.error': {
      '& .MuiFormHelperText-root': {
        color: '#d32f2f',
      },
    },

    '&.read-only': {
      '& .Mui-focused': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderWidth: 1,
        },
      },

      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: 1,
      },
    },
  },

  '& .primary-legend': {
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.primary.main,
  },
}))
