import { CheckCircleOutline, DoDisturb } from '@mui/icons-material'
import { Button, Dialog, IconButton, styled as MUIStyled, Tab, Tabs } from '@mui/material'
import RatingField from 'components/RatingField'
import { MESSAGE_TYPES } from 'constants/common'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getReviews, updateReview } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'
import ProtectedPage from '../../components/ProtectedPage'
import StyledTable from '../../components/StyledTable'

const ChangeReviewStatusConfirmDialog = MUIStyled(({ className, status, onConfirm, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dialog open {...other} className={['confirm-delete-plan-dialog', className].join(' ')}>
      <div className="message-wrapper">
        <div className="confirm-message">{t('reviews_management_page.change_review_status_confirm_message')}</div>
        <div className="sub-message">{t(`reviews_management_page.${status}_review_sub_message`)}</div>
      </div>
      <div className="actions-wrapper">
        <Button color="primary" variant="outlined" onClick={other.onClose}>
          {t('reviews_management_page.change_review_status_cancel_button_label')}
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {t('reviews_management_page.change_review_status_confirm_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },

  '& .message-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    alignItems: 'center',
  },

  '& .confirm-message': {
    fontSize: 24,
    fontWeight: 600,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 24,
    justifyContent: 'center',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: 100,
  },
})

const TABLE_COLUMNS = [
  { label: 'reviews_management_page.index_column_label', key: 'index' },
  { label: 'reviews_management_page.receiver_column_label', key: 'author' },
  { label: 'reviews_management_page.provider_column_label', key: 'reviewer' },
  { label: 'reviews_management_page.date_time_column_label', key: 'createdAt' },
  { label: 'reviews_management_page.ratings_column_label', key: 'score' },
  { label: 'reviews_management_page.comments_column_label', key: 'description' },
  { label: 'reviews_management_page.status_column_label', key: 'status' },
  { label: 'reviews_management_page.actions_column_label', key: 'actions' },
]
const PAGE_SIZE = 10
const REVIEW_STATUS = {
  PENDING: 'PENDING',
  PUBLISHED: 'PUBLISHED',
  DISABLED: 'DISABLED',
}
const REVIEW_TABS = {
  ALL: 'ALL',
  REPORTING: 'REPORTING',
}

const ReviewsManagementPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({ page: 1, type: REVIEW_TABS.ALL })
  const [pageData, setPageData] = useState({})
  const [fetchingData, setFetchingData] = useState(true)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [reviewChangingStatus, setReviewChangingStatus] = useState(null)

  useEffect(() => {
    const fetchReviewList = async () => {
      setFetchingData(true)
      try {
        const queryString = Object.entries(filters)
          .filter(([key, value]) => (key === 'page' && value > 1) || (key === 'type' && value !== REVIEW_TABS.ALL))
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&')
        const response = await getReviews(accessToken, queryString ? `?${queryString}` : '')
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch {
        navigate('/500')
      }
      setFetchingData(false)
    }

    if (accessToken) {
      fetchReviewList()
    }
  }, [accessToken, navigate, filters])

  const handleFilterChange = (optionName) => (_event, value) => {
    setPageData({})
    setFilters({ ...filters, [optionName]: value })
  }

  const handleToggleReviewStatus = (newStatus, review) => () => {
    setReviewChangingStatus({ newStatus, review })
  }

  const handleCloseConfirmDialog = () => {
    setReviewChangingStatus(null)
  }

  const handleUpdateReviewStatus = async () => {
    handleCloseConfirmDialog()
    const { newStatus, review } = reviewChangingStatus
    try {
      const response = await updateReview(review.id, { status: newStatus }, accessToken)
      if (isSuccessRequest(response.status)) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t(`reviews_management_page.${newStatus}_review_success`),
          })
        )
        setFilters({ ...filters, requestTime: Date.now() })
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t(`reviews_management_page.${newStatus}_review_failed`),
        })
      )
    }
  }

  const renderColumn = (name, value, record) => {
    let cellContent
    switch (name) {
      case 'author':
      case 'reviewer':
        cellContent = value?.company_info?.company_name || value?.company_info?.contact_name || 'N/A'
        break

      case 'createdAt':
        cellContent = (
          <div className="date-time-wrapper">
            <div className="created-date">{dayjs(value).format('MM-DD-YYYY')}</div>
            <div className="created-time">{dayjs(value).format('hh-mm A')}</div>
          </div>
        )
        break

      case 'score':
        cellContent = <RatingField value={value} />
        break

      case 'status':
        cellContent = t(`reviews_management_page.${value}_status_column_value`)
        break

      case 'actions':
        cellContent = (
          <div className="actions-wrapper">
            <IconButton
              color="error"
              disabled={record.status === REVIEW_STATUS.DISABLED}
              title={t('reviews_management_page.reject_disable_review_button_title')}
              onClick={handleToggleReviewStatus(REVIEW_STATUS.DISABLED, record)}
            >
              <DoDisturb />
            </IconButton>
            <IconButton
              color="success"
              disabled={record.status === REVIEW_STATUS.PUBLISHED}
              title={t('reviews_management_page.approve_publish_review_button_title')}
              onClick={handleToggleReviewStatus(REVIEW_STATUS.PUBLISHED, record)}
            >
              <CheckCircleOutline />
            </IconButton>
          </div>
        )
        break

      default:
        cellContent = value
    }

    return cellContent
  }

  return (
    <ProtectedPage
      title={t('reviews_management_page.title')}
      className={['reviews-management-page', className].join(' ')}
      breadcrumbs={[{ label: t('reviews_management_page.title') }]}
    >
      <Tabs value={filters.type} onChange={handleFilterChange('type')}>
        <Tab label={t(`reviews_management_page.ALL_tab_label`)} value={REVIEW_TABS.ALL} />
        <Tab label={t(`reviews_management_page.REPORTING_tab_label`)} value={REVIEW_TABS.REPORTING} />
      </Tabs>
      <StyledTable
        columns={TABLE_COLUMNS}
        loading={fetchingData}
        data={pageData.data?.map((row, index) => ({ ...row, index: index + 1 }))}
        pagination={{
          page: filters.page,
          count: Math.ceil((pageData.pagination?.total || 0) / PAGE_SIZE),
        }}
        onPageChange={handleFilterChange('page')}
        renderColumn={renderColumn}
        emptyMessage={t('reviews_management_page.empty_message')}
      />
      {reviewChangingStatus && (
        <ChangeReviewStatusConfirmDialog
          status={reviewChangingStatus.newStatus}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleUpdateReviewStatus}
        />
      )}
    </ProtectedPage>
  )
})({
  '& .table-col-createdat': {
    textAlign: 'center',

    '& .date-time-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  '& .table-col-actions': {
    textAlign: 'center',

    '& .actions-wrapper': {
      display: 'flex',
      columnGap: 16,
      justifyContent: 'center',

      '& svg': {
        fontSize: 32,
      },
    },
  },
})

export default ReviewsManagementPage
