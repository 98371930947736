import { styled as MUIStyled, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

const DatePicker = MUIStyled(({ className, name, error, value, onChange, inputFormat, ...other }) => {
  const handleDateChange = (date, dateString) => {
    let newDate = dateString || ''
    if (!dateString && date) {
      newDate = date.format(inputFormat || 'MM/YY')
    }
    if (typeof onChange === 'function') {
      onChange(null, newDate)
    }
  }

  return (
    <div className={['date-picker', className].join(' ')}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat={inputFormat || 'MM/YY'}
          views={['year', 'month']}
          renderInput={(props) => <TextField {...props} name={name} error={Boolean(error)} helperText={error} />}
          onChange={handleDateChange}
          value={(value && dayjs(value, inputFormat || 'MM/YY')) || value}
          {...other}
        />
      </LocalizationProvider>
    </div>
  )
})(({ theme }) => ({}))

export default DatePicker
