import HomePage from 'pages'
import PageNotFound from 'pages/404'
import ChangePasswordPage from 'pages/ChangePassword'
import ForgotPasswordPage from 'pages/ForgotPassword'
import MyNotificationsPage from 'pages/MyNotifications'
import MyProfilePage from 'pages/MyProfile'
import NotificationManagementPage from 'pages/NotificationManagement'
import NotificationDetailPage from 'pages/NotificationManagement/NotificationDetail'
import SendNotificationPage from 'pages/NotificationManagement/SendNotification'
import ResetPasswordPage from 'pages/ResetPassword'
import ReviewsManagementPage from 'pages/ReviewsManagement'
import RFQManagementPage from 'pages/RFQManagement'
import RFQDetailPage from 'pages/RFQManagement/RFQDetail'
import SignInPage from 'pages/SignIn'
import AddNewFAQPage from 'pages/StaticContents/AddNewFAQ'
import ChinaClientConfigsPage from 'pages/StaticContents/ChinaClientConfigsPage'
import EditFAQPage from 'pages/StaticContents/EditFAQ'
import EditPrivacyPolicyPage from 'pages/StaticContents/EditPrivacyPolicy'
import EditTermsAndConditionsPage from 'pages/StaticContents/EditTermsAndConditions'
import FAQsManagementPage from 'pages/StaticContents/FAQsManagement'
import StaticContentManagementPage from 'pages/StaticContents/StaticContentManagement'
import SubAdminsManagementPage from 'pages/SubAdminsManagement'
import { AdminActivityLogsPage } from 'pages/SubAdminsManagement/AdminActivityLogs'
import AdminProfilePage from 'pages/SubAdminsManagement/AdminProfile'
import AdminUserFormPage from 'pages/SubAdminsManagement/AdminUserForm'
import SubscriptionsManagementPage from 'pages/SubscriptionsManagement'
import { SubscriptionFeaturesManagementPage } from 'pages/SubscriptionsManagement/SubscriptionFeaturesManagement'
import SubscriptionPlanFormPage from 'pages/SubscriptionsManagement/SubscriptionPlanFormPage'
import { TransactionsPage } from 'pages/Transactions'
import { RefundRequestsPage } from 'pages/Transactions/RefundRequests'
import UsersManagementPage from 'pages/UsersManagement'
import { BrokerFormPage } from 'pages/UsersManagement/BrokerForm'
import { BuyerShipperFormPage } from 'pages/UsersManagement/BuyerShipperForm'
import UserDetailPage from 'pages/UsersManagement/UserDetail'

export const routesConfig = [
  {
    path: '/dashboard',
    element: <HomePage />,
    permissionCheck: true,
    permission: 'DASHBOARD_MANAGEMENT',
  },
  {
    path: '/users',
    element: <UsersManagementPage />,
    permissionCheck: true,
    permission: 'USER_MANAGEMENT',
  },
  {
    path: '/users/:userType/:userId',
    element: <UserDetailPage />,
    permissionCheck: true,
    permission: 'USER_MANAGEMENT',
  },
  {
    path: '/users/broker/new',
    element: <BrokerFormPage />,
    permissionCheck: true,
    permission: 'USER_MANAGEMENT',
  },
  {
    path: '/users/broker/:userId/edit',
    element: <BrokerFormPage />,
    permissionCheck: true,
    permission: 'USER_MANAGEMENT',
  },
  {
    path: '/users/buyer-shipper/new',
    element: <BuyerShipperFormPage />,
    permissionCheck: true,
    permission: 'USER_MANAGEMENT',
  },
  {
    path: '/users/buyer-shipper/:userId/edit',
    element: <BuyerShipperFormPage />,
    permissionCheck: true,
    permission: 'USER_MANAGEMENT',
  },
  {
    path: '/rfqs',
    element: <RFQManagementPage />,
    permissionCheck: true,
    permission: 'RFQ_MANAGEMENT',
  },
  {
    path: '/rfqs/:rfqId',
    element: <RFQDetailPage />,
    permissionCheck: true,
    permission: 'RFQ_MANAGEMENT',
  },
  {
    path: '/reviews',
    element: <ReviewsManagementPage />,
    permissionCheck: true,
    permission: 'REVIEW_MANAGEMENT',
  },
  {
    path: '/subscriptions',
    element: <SubscriptionsManagementPage />,
    permissionCheck: true,
    permission: 'SUBSCRIPTION_MANAGEMENT',
  },
  {
    path: '/subscriptions/features',
    element: <SubscriptionFeaturesManagementPage />,
    permissionCheck: true,
    permission: 'SUBSCRIPTION_MANAGEMENT',
  },
  {
    path: '/subscriptions/new',
    element: <SubscriptionPlanFormPage />,
    permissionCheck: true,
    permission: 'SUBSCRIPTION_MANAGEMENT',
  },
  {
    path: '/subscriptions/:planId/edit',
    element: <SubscriptionPlanFormPage />,
    permissionCheck: true,
    permission: 'SUBSCRIPTION_MANAGEMENT',
  },
  {
    path: '/transactions',
    element: <TransactionsPage />,
    permissionCheck: true,
    permission: 'TRANSACTION_MANAGEMENT',
  },
  {
    path: '/transactions/refunds',
    element: <RefundRequestsPage />,
    permissionCheck: true,
    permission: 'TRANSACTION_MANAGEMENT',
  },
  {
    path: '/notifications',
    element: <NotificationManagementPage />,
    permissionCheck: true,
    permission: 'NOTIFICATION_MANAGEMENT',
  },
  {
    path: '/notifications/send-new',
    element: <SendNotificationPage />,
    permissionCheck: true,
    permission: 'NOTIFICATION_MANAGEMENT',
  },
  {
    path: '/notifications/:notificationId',
    element: <NotificationDetailPage />,
    permissionCheck: true,
    permission: 'NOTIFICATION_MANAGEMENT',
  },
  {
    path: '/subadmins',
    element: <SubAdminsManagementPage />,
    permissionCheck: true,
    permission: 'SUBADMIN_MANAGEMENT',
  },
  {
    path: '/subadmins/add-new',
    element: <AdminUserFormPage />,
    permissionCheck: true,
    permission: 'SUBADMIN_MANAGEMENT',
  },
  {
    path: '/subadmins/:adminId',
    element: <AdminProfilePage />,
    permissionCheck: true,
    permission: 'SUBADMIN_MANAGEMENT',
  },
  {
    path: '/subadmins/:adminId/activity-logs',
    element: <AdminActivityLogsPage />,
    permissionCheck: true,
    permission: 'SUBADMIN_MANAGEMENT',
  },
  {
    path: '/static-contents',
    element: <StaticContentManagementPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/subadmins/:adminId/edit',
    element: <AdminUserFormPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/static-contents/privacy-policy/edit',
    element: <EditPrivacyPolicyPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/static-contents/terms-conditions/edit',
    element: <EditTermsAndConditionsPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/static-contents/faqs',
    element: <FAQsManagementPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/static-contents/faqs/add-new',
    element: <AddNewFAQPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/static-contents/faqs/:faq/edit',
    element: <EditFAQPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/static-contents/china-client-configs',
    element: <ChinaClientConfigsPage />,
    permissionCheck: true,
    permission: 'STATIC_CONTENT_MANAGEMENT',
  },
  {
    path: '/login',
    element: <SignInPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '/my-notifications',
    element: <MyNotificationsPage />,
  },
  {
    path: '/my-profile',
    element: <MyProfilePage />,
  },
  {
    path: '/change-password',
    element: <ChangePasswordPage />,
  },
  {
    path: '/404',
    element: <PageNotFound />,
  },
]
