import { Email, LocalPhone } from '@mui/icons-material'
import { styled as MUIStyled } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import ProtectedPage from '../../components/ProtectedPage'
import { getRFQ } from '../../services/api'
import { isSuccessRequest } from '../../utils/common'

const SHIPMENT_PACK_TYPES = {
  FCL: 'FCL',
  LCL: 'LCL',
  BREAK_BULK: 'BREAK_BULK',
}

const generatePortString = (portInfo) => {
  const { city_name, state_name, country_name } = portInfo
  if (state_name) {
    return [city_name, state_name, country_name].join(', ')
  }
  return [city_name, country_name].join(', ')
}

const RFQDetailPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const { rfqId } = useParams()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [pageData, setPageData] = useState(null)

  useEffect(() => {
    const fetchRFQDetail = async () => {
      try {
        const response = await getRFQ(rfqId, accessToken)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data?.data)
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          navigate('/404', { replace: true })
        } else {
          navigate('/500')
        }
      }
    }

    if (rfqId && accessToken) {
      fetchRFQDetail()
    } else {
      navigate('/404', { replace: true })
    }
  }, [rfqId, navigate, accessToken])

  const renderShipmentTypeInfo = (shipmentType) => {
    const { package_type, size, weight, volume } = shipmentType?.package_type
    let transportInfo = shipmentType?.transport_type.map((type) => t(`transport_mode.${type}`)).join(', ')
    switch (package_type) {
      case SHIPMENT_PACK_TYPES.FCL:
        return [transportInfo, t(`pack_type.${package_type}`), t(`container_size.${size}`)].join(', ')

      case SHIPMENT_PACK_TYPES.LCL:
        return [
          transportInfo,
          t(`pack_type.${package_type}`),
          `${volume} (${t(`shipment_type_lcl_product_volume_unit`)})`,
          `${weight} (${t(`shipment_type_lcl_product_gross_weight_unit`)})`,
        ].join(', ')

      default:
        return [transportInfo, t(`pack_type.${package_type}`)]
    }
  }

  const {
    broker,
    buyer,
    comments,
    description,
    estimate_ship_date,
    origin,
    destination,
    customs_clearance,
    shipment_type,
    harmonized_tax_code,
    cargo_type,
    handing_type,
    picture,
    createdAt,
  } = pageData || {}

  return (
    <ProtectedPage
      className={['rfq-detail-page', className].join(' ')}
      breadcrumbs={[
        { label: t('rfq_management_page.title'), path: '/rfqs' },
        { label: `${t('rfq_detail_page.title')} #${rfqId?.substring(0, 8).toLocaleUpperCase()}` },
      ]}
      title={
        <>
          <div className="main-title">
            {`${t('rfq_detail_page.title')} - #${rfqId?.substring(0, 8).toLocaleUpperCase()}`}
          </div>
          <div className="created-at">
            <div className="label">{t('rfq_detail_page.created_at_label')}:</div>
            <div className="value">{dayjs(createdAt).format('MMM DD, YYYY HH:mm:ss')}</div>
          </div>
        </>
      }
    >
      {pageData === null && <Loading />}
      {pageData && (
        <>
          <div className="users-info-wrapper">
            <div className="user-info">
              <div className="label">{t('rfq_management_page.buyer_shipper_detail_label')}</div>
              <div className="info-wrapper">
                <div className="username">{buyer?.company_info?.contact_name}</div>
                <div className="phone">
                  <LocalPhone />
                  <span>{buyer?.company_info?.phones?.join(', ') || 'N/A'}</span>
                </div>
                <div className="email">
                  <Email />
                  <span>{buyer?.email}</span>
                </div>
              </div>
            </div>
            <div className="user-info">
              <div className="label">{t('rfq_management_page.broker_detail_label')}</div>
              <div className="info-wrapper">
                <div className="username">{broker?.company_info?.contact_name}</div>
                <div className="phone">
                  <LocalPhone />
                  <span>{broker?.company_info?.phones?.join(', ') || 'N/A'}</span>
                </div>
                <div className="email">
                  <Email />
                  <span>{broker?.email}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="quote-detail">
            <div className="block-label">{t('rfq_management_page.quote_detail_label')}</div>
            <div className="block-content">
              <div className="general-info">
                <div className="column">
                  <div className="info-wrapper">
                    <div className="label">{t('rfq_management_page.est_ship_date_label')}</div>
                    <div className="value">{dayjs(estimate_ship_date).format('MM-DD-YYYY')}</div>
                  </div>
                  <div className="info-wrapper">
                    <div className="label">{t('rfq_management_page.shipment_type_label')}</div>
                    <div className="value">{renderShipmentTypeInfo(shipment_type)}</div>
                  </div>
                </div>
                <div className="column">
                  <div className="info-wrapper">
                    <div className="label">{t('rfq_management_page.origin_label')}</div>
                    <div className="value">{generatePortString(origin)}</div>
                  </div>
                  <div className="info-wrapper">
                    <div className="label">{t('rfq_management_page.destination_label')}</div>
                    <div className="value">{generatePortString(destination)}</div>
                  </div>
                </div>
              </div>

              <div className="product-info">
                <div className="label">{t('rfq_management_page.product_description_label')}</div>
                <div className="content">
                  <div className="info-wrapper">
                    <div className="label">{t('rfq_management_page.cargo_type_label')}</div>
                    <div className="value">{cargo_type?.map((type) => t(`cargo_type.${type}`)).join(', ')}</div>
                  </div>
                  <div className="info-wrapper">
                    <div className="label">{t('rfq_management_page.general_description_label')}</div>
                    <div
                      className="value"
                      dangerouslySetInnerHTML={{ __html: (description || '').replaceAll('\n', '<br/>') }}
                    />
                  </div>
                  <div className="info-wrapper">
                    <div className="label">{t('rfq_management_page.harmonized_tax_code_label')}</div>
                    <div className="value">{harmonized_tax_code || 'NA'}</div>
                  </div>
                  <div className="info-wrapper handling-type">
                    <div className="label">{t('rfq_management_page.handling_type_label')}</div>
                    <div className="value">
                      <span>{handing_type?.map((type) => t(`handling_type.${type}`)).join(', ')}</span>
                      {picture && (
                        <div className="product-image">
                          <img src={picture} alt="" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="general-comments">
                <div className="label">{t('rfq_management_page.general_comments_label')}</div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: (comments || '').replaceAll('\n', '<br/>') }}
                />
              </div>

              <div className="clearance-comments">
                <div className="label">
                  {t(`rfq_management_page.clearace_service_label_${customs_clearance ? 'yes' : 'no'}`)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ProtectedPage>
  )
})({
  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',
    '& .created-at': {
      display: 'flex',
      columnGap: 8,
      fontSize: 16,

      '& .value': {
        fontWeight: 400,
      },
    },
  },

  '& .users-info-wrapper': {
    display: 'flex',

    '& .user-info': {
      flex: 1,
      display: 'flex',
      columnGap: 16,

      '& .info-wrapper': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
      },

      '& .label': {
        fontWeight: 500,
        fontSize: 20,
      },

      '&  .username': {
        fontWeight: 500,
        fontSize: 20,
      },

      '& .phone, .email': {
        display: 'flex',
        alignItems: 'center',
        columnGap: 8,
      },
    },
  },

  '& .quote-detail': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,

    '& .block-label': {
      fontWeight: 500,
      fontSize: 20,
    },

    '& .block-content': {
      border: '1px solid #A0A0A0',
      borderRadius: 8,
      padding: '24px 16px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },

    '& .label': {
      fontWeight: 500,
      fontSize: 18,
    },

    '& .general-info': {
      display: 'flex',

      '& .column': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,

        '& .info-wrapper': {
          display: 'flex',
          columnGap: 16,
          alignItems: 'center',
        },
      },
    },

    '& .product-info': {
      '& .content': {
        paddingLeft: 24,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,

        '& .label': {
          fontSize: 16,
        },

        '& .info-wrapper': {
          display: 'flex',
          columnGap: 16,
          alignItems: 'center',

          '& .label': {
            width: 200,
          },

          '&.handling-type .value': {
            display: 'flex',
            columnGap: 16,
            alignItems: 'center',

            '& .product-image': {
              width: 240,
              maxHeight: 120,
              overflow: 'hidden',
              borderRadius: 4,

              '& img': {
                width: '100%',
              },
            },
          },
        },
      },
    },
  },
})

export default RFQDetailPage
