import { styled as MUIStyled } from '@mui/material'
import StyledTabs from 'components/StyledTabs'
import { useTranslation } from 'react-i18next'

export const PaymentPageHeader = MUIStyled(({ type }) => {
  const { t } = useTranslation()

  const tabs = [
    { label: t('transactions_page.transaction_tab_label'), value: 'TRANSACTIONS' },
    { label: t('transactions_page.refund_tab_label'), value: 'REFUND_REQUESTS' },
  ]

  const handlePageChange = (_, newPage) => {
    window.location.href = newPage === 'TRANSACTIONS' ? '/transactions' : '/transactions/refunds'
  }

  return <StyledTabs tabs={tabs} value={type} onChange={handlePageChange} />
})({})
