import { styled as MUIStyled } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { Logo } from '../Icons'
import AccountMenu from './AccountMenu'
import Notifications from './Notifications'

const Header = MUIStyled(({ className }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual)

  return (
    <header className={['page-header', className].join(' ')}>
      <a href="/" className="logo-wrapper">
        <Logo />
      </a>

      {user && (
        <div className="right-menu">
          <Notifications />
          <AccountMenu />
        </div>
      )}
    </header>
  )
})({
  backgroundColor: '#fff',
  width: '100%',
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  height: 62,
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',

  '& .logo-wrapper': {
    lineHeight: 1,
  },

  '& .right-menu': {
    display: 'flex',
    columnGap: 16,
    alignItems: 'center',

    '& button': {
      padding: 0,
    },
  },
})

export default Header
