import { CheckCircleOutline, DoDisturb, Password, Search, Visibility } from '@mui/icons-material'
import { Button, Dialog, IconButton, MenuItem, styled as MUIStyled, TextField } from '@mui/material'
import DatePicker from 'components/DatePicker'
import { EditIcon } from 'components/Icons'
import ProtectedPage from 'components/ProtectedPage'
import StyledInput from 'components/StyledInput'
import StyledTable from 'components/StyledTable'
import StyledTabs from 'components/StyledTabs'
import { CLIENT_USER_STATUS, CLIENT_USER_TYPES, MESSAGE_TYPES } from 'constants/common'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { extendMembership, getClientUsers, saveClientDetail, updateClientUserStatus } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'
import * as Yup from 'yup'

let extendDateTimer = -1

const ChangeAccountStatusConfirmDialog = MUIStyled(({ className, activate, onConfirm, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dialog open {...other} className={['confirm-delete-plan-dialog', className].join(' ')}>
      <div className="message-wrapper">
        <div className="confirm-message">{t('users_management_page.change_user_status_confirm_message')}</div>
        <div className="sub-message">
          {t(`users_management_page.${activate ? 'activate' : 'deactivate'}_user_sub_message`)}
        </div>
      </div>
      <div className="actions-wrapper">
        <Button color="primary" variant="outlined" onClick={other.onClose}>
          {t('users_management_page.change_user_status_cancel_button_label')}
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {t('users_management_page.change_user_status_confirm_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },

  '& .message-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    alignItems: 'center',
  },

  '& .confirm-message': {
    fontSize: 24,
    fontWeight: 600,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 24,
    justifyContent: 'center',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: 100,
  },
})

const SetUserPasswordDialog = MUIStyled(({ className, onConfirm, ...other }) => {
  const { t } = useTranslation()
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t('form_validation.length', { len: 6 }))
      .required(t('form_validation.required_field')),
    confirmPassword: Yup.string().test(
      'equalPassword',
      t('users_management_page.set_user_password_dialog_confirm_password_not_match'),
      (value, context) => {
        return value === context.parent.password
      }
    ),
  })
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (submittedValues) => {
      if (typeof other.onClose === 'function') {
        other.onClose()
      }
      if (typeof onConfirm === 'function') {
        onConfirm(submittedValues.password)
      }
    },
  })

  return (
    <Dialog open {...other} className={['confirm-delete-plan-dialog', className].join(' ')}>
      <form onSubmit={handleSubmit}>
        <h1>{t('users_management_page.set_user_password_dialog_title')}</h1>
        <StyledInput
          onChange={handleChange}
          value={values.password}
          name="password"
          label={t('users_management_page.set_user_password_dialog_new_password_field_label')}
          helperText={touched.password && errors.password}
          error={errors.password && touched.password}
        />
        <StyledInput
          onChange={handleChange}
          name="confirmPassword"
          value={values.confirmPassword}
          label={t('users_management_page.set_user_password_dialog_confirm_new_password_field_label')}
          helperText={touched.confirmPassword && errors.confirmPassword}
          error={errors.confirmPassword && touched.confirmPassword}
        />
        <div className="actions-wrapper">
          <Button color="primary" variant="outlined" onClick={other.onClose}>
            {t('users_management_page.set_user_password_dialog_cancel_button_label')}
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {t('users_management_page.set_user_password_dialog_save_button_label')}
          </Button>
        </div>
      </form>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },

  '& .message-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    alignItems: 'center',
  },

  '& .confirm-message': {
    fontSize: 24,
    fontWeight: 600,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 24,
    justifyContent: 'center',
    marginTop: 16,
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: 100,
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,

    '& h1': {
      margin: 0,
    },

    '& label': {
      minWidth: 190,
    },
  },
})

const PAGE_SIZE = 10
const TABLE_COLUMNS = [
  {
    label: 'users_management_page.index_column_label',
    key: 'index',
  },
  {
    label: 'users_management_page.company_name_column_label',
    key: 'company_info.company_name',
  },
  {
    label: 'users_management_page.email_column_label',
    key: 'email',
  },
  {
    label: 'users_management_page.contact_name_column_label',
    key: 'company_info.contact_name',
  },
  {
    label: 'users_management_page.state_column_label',
    key: 'company_info.state',
  },
  {
    label: 'users_management_page.country_column_label',
    key: 'company_info.country',
  },
  {
    label: 'users_management_page.membership_column_label',
    key: 'membership',
  },
  {
    label: 'users_management_page.expired_date_column_label',
    key: 'membership.expiresAt',
  },
  {
    label: 'users_management_page.status_column_label',
    key: 'status',
  },
  {
    label: 'users_management_page.actions_column_label',
    key: 'actions',
  },
]

const defaultFilters = {
  type: CLIENT_USER_TYPES.BROKER,
  query: '',
  page: 1,
  start_date: null,
  end_date: null,
  status: '',
}
let abortController

const generateQueryString = (filters) => {
  return Object.entries(filters)
    .filter(([key, value]) => (Boolean(value) && key !== 'page') || (key === 'page' && value > 1))
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
}

const getQueryObject = (urlSearchParams) => {
  let params = {}

  urlSearchParams?.forEach((value, key) => {
    params[key] = value
  })

  return params
}

const UsersManagementPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const [queryParams] = useSearchParams()
  const [filters, setFilters] = useState({ ...defaultFilters, ...getQueryObject(queryParams) })
  const [pageData, setPageData] = useState({})
  const [fetchingData, setFetchingData] = useState(true)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const navigate = useNavigate()
  const [changingStatusAccount, setChangingStatusAccount] = useState(null)
  const dispatch = useDispatch()
  const [changingPasswordUser, setChangingPasswordUser] = useState(null)

  useEffect(() => {
    const fetchUsers = async () => {
      setFetchingData(true)

      try {
        const queryString = generateQueryString(filters)
        abortController = new AbortController()
        const response = await getClientUsers(accessToken, `?${queryString}`, abortController.signal)
        abortController = null
        setFetchingData(false)

        if (isSuccessRequest(response?.status)) {
          setPageData(response.data)
        }
      } catch (error) {}
    }

    if (accessToken) {
      if (abortController) {
        abortController.abort()
      }

      fetchUsers()
    }
  }, [accessToken, filters, navigate])

  const handleFilterChange = (name) => (event, newValue) => {
    let newFilters = { ...filters }
    switch (name) {
      case 'query':
      case 'status':
        newFilters = { ...newFilters, [name]: event.target.value, page: 1 }
        break

      case 'start_date':
      case 'end_date':
        newFilters = { ...newFilters, [name]: newValue, page: 1 }
        break

      case 'type':
        newFilters = { ...defaultFilters, type: newValue }
        break

      default:
        newFilters = { ...newFilters, [name]: newValue }
    }
    navigate(`/users?${generateQueryString(newFilters)}`, { replace: true })
    setFilters(newFilters)
  }

  const handleClearFilters = () => {
    setFilters({ ...defaultFilters, type: filters.type })
  }

  const handleChangeAccountStatusClick = (user) => () => {
    setChangingStatusAccount({
      user,
      status: user.status === CLIENT_USER_STATUS.ACTIVE ? CLIENT_USER_STATUS.BLOCK : CLIENT_USER_STATUS.ACTIVE,
    })
  }

  const handleCloseConfirmDialog = () => {
    setChangingStatusAccount(null)
  }

  const handleConfirmChangeUserStatus = async () => {
    const isActivating = changingStatusAccount.status === CLIENT_USER_STATUS.ACTIVE
    try {
      const response = await updateClientUserStatus(
        changingStatusAccount.user.id,
        changingStatusAccount.status,
        accessToken
      )

      if (isSuccessRequest(response.status)) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t(`users_management_page.${isActivating ? 'activate' : 'deactivate'}_user_success`),
          })
        )
        setFilters({ ...filters })
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.SUCCESS,
          content: t(`users_management_page.${isActivating ? 'activate' : 'deactivate'}_user_failed`),
        })
      )
    }

    handleCloseConfirmDialog()
  }

  const handleShowSetUserPasswordDialog = (user) => () => {
    setChangingPasswordUser(user)
  }

  const handleCloseSetUserPasswordDialog = () => {
    setChangingPasswordUser(null)
  }

  const handleSubmitNewPassword = async (newPassword) => {
    const { id: userId, ...userData } = changingPasswordUser

    try {
      console.log('changing user', changingPasswordUser)
      const response = await saveClientDetail(userId, { ...userData, password: newPassword }, accessToken)
      if (isSuccessRequest(response.status)) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t('users_management_page.set_user_password_success', { username: userData.email }),
          })
        )
        return
      }
    } catch (error) {
      console.log("Set new user's password has an error. Error: ", error)
    }

    dispatch(
      createMessage({
        type: MESSAGE_TYPES.ERROR,
        content: t('users_management_page.set_user_password_failed.', { username: userData.email }),
      })
    )
  }

  const handleChangeExpirationDate = (userId) => (_ev, selectedDate) => {
    if (extendDateTimer > 0) {
      clearTimeout(extendDateTimer)
    }

    extendDateTimer = setTimeout(async () => {
      try {
        extendDateTimer = -1
        const response = await extendMembership(userId, selectedDate, accessToken)
        if (isSuccessRequest(response.status)) {
          dispatch(
            createMessage({
              type: MESSAGE_TYPES.SUCCESS,
              content: t('users_management_page.extended_membership_success'),
            })
          )
          setFilters({ ...filters })
        }
      } catch {
        dispatch(
          createMessage({ type: MESSAGE_TYPES.ERROR, content: t('users_management_page.extend_membership_failed') })
        )
      }
    }, 2000)
  }

  const renderColumn = (name, value, record) => {
    switch (name) {
      case 'status':
        if (record.membership.type && record.membership.status === 'UNSUBSCRIBE') {
          return t(`users_management_page.EXPIRED_user_status`)
        }
        return t(`users_management_page.${value}_user_status`)

      case 'membership':
        if (typeof value === 'string') {
          return t(`subscription_plan.${value}`)
        }

        return (value?.type && t(`subscription_plan.${value.type}`)) || ''

      case 'membership.expiresAt':
        if (value) {
          return (
            <DatePicker
              inputFormat={'MM/DD/YYYY'}
              views={['year', 'month', 'day']}
              onChange={handleChangeExpirationDate(record.id)}
              value={dayjs(value).format('MM/DD/YYYY')}
              mask="__/__/____"
            />
          )
        }
        return ''

      case 'actions':
        const userRole = record.roles[0]?.name?.toLowerCase()
        return (
          <div className="actions-wrapper">
            <IconButton
              color="primary"
              href={`/users/${userRole}/${record.id}`}
              title={t('users_management_page.view_user_button_title')}
            >
              <Visibility />
            </IconButton>
            {record.status === CLIENT_USER_STATUS.PENDING && (
              <Button
                variant="contained"
                color="primary"
                href={`/users/${userRole}/${record.id}`}
                title={t('users_management_page.approve_user_button_title')}
              >
                {t('users_management_page.approve_button_label')}
              </Button>
            )}
            {record.status !== CLIENT_USER_STATUS.VERIFIED &&
              record.status !== CLIENT_USER_STATUS.PENDING &&
              record.status !== CLIENT_USER_STATUS.VERIFYING && (
                <IconButton
                  color={record.status === CLIENT_USER_STATUS.ACTIVE ? 'error' : 'success'}
                  onClick={handleChangeAccountStatusClick(record)}
                  title={t(
                    `users_management_page.${CLIENT_USER_STATUS.ACTIVE ? 'disable' : 'active'}_user_button_title`
                  )}
                >
                  {record.status === CLIENT_USER_STATUS.ACTIVE ? <DoDisturb /> : <CheckCircleOutline />}
                </IconButton>
              )}
            {record.status !== CLIENT_USER_STATUS.PENDING &&
              record.status !== CLIENT_USER_STATUS.VERIFYING &&
              record.status !== CLIENT_USER_STATUS.VERIFIED && (
                <>
                  <IconButton
                    color="warning"
                    onClick={handleShowSetUserPasswordDialog(record)}
                    title={t('users_management_page.set_password_user_button_title')}
                  >
                    <Password />
                  </IconButton>
                  <IconButton
                    color="info"
                    href={`/users/${filters.type.toLowerCase().replace(/_/g, '-')}/${record.id}/edit`}
                    title={t('users_management_page.edit_user_button_title')}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}
          </div>
        )
      default:
        return value
    }
  }

  const disabledClearFilters =
    Object.entries(filters).filter(
      ([key, value]) => (Boolean(value) && key !== 'page' && key !== 'type') || (key === 'page' && value > 1)
    ).length === 0
  const tabs = Object.keys(CLIENT_USER_TYPES).map((type) => ({
    label: t(`users_management_page.${type}_tab_label`),
    value: type,
  }))

  let tableColumns = TABLE_COLUMNS
  if (filters.type !== 'BROKER') {
    tableColumns = [...TABLE_COLUMNS.slice(0, 6), TABLE_COLUMNS.at(-1)]
  }

  return (
    <ProtectedPage
      title={t('users_management_page.title')}
      breadcrumbs={[{ label: t('users_management_page.title') }]}
      className={['users-management-page', className].join(' ')}
    >
      <StyledTabs tabs={tabs} value={filters.type} onChange={handleFilterChange('type')} />

      <div className="top-page">
        <div className="filters-wrapper">
          <TextField
            className="search-input"
            InputProps={{ startAdornment: <Search /> }}
            placeholder={t('users_management_page.filter_input_placeholder')}
            onChange={handleFilterChange('query')}
            value={filters.query}
          />
          <div className="date-filters">
            <div className="date-field from-field">
              <div className="field-label">{t('users_management_page.filter_by_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('start_date')}
                value={filters.start_date}
                mask="__/__/____"
              />
            </div>
            <div className="date-field">
              <div className="field-label"> {t('users_management_page.filter_to_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('end_date')}
                value={filters.end_date}
                mask="__/__/____"
              />
            </div>
            <TextField
              select
              label={t('users_management_page.filter_status_placeholder')}
              value={filters.status}
              name="status"
              onChange={handleFilterChange('status')}
            >
              {Object.keys(CLIENT_USER_STATUS).map((status) => (
                <MenuItem key={`user-status-filter-${status}`} value={status}>
                  {t(`users_management_page.${status}_user_status`)}
                </MenuItem>
              ))}
            </TextField>
            <Button variant="contained" color="primary" onClick={handleClearFilters} disabled={disabledClearFilters}>
              {t('users_management_page.clear_filters_button_label')}
            </Button>
          </div>
        </div>
        <div className="add-wrapper">
          <Button
            variant="contained"
            color="primary"
            href={`/users/${filters.type.replace(/_/g, '-').toLowerCase()}/new`}
          >
            {t('users_management_page.add_new_button_label')}
          </Button>
        </div>
      </div>

      <StyledTable
        columns={tableColumns}
        loading={fetchingData}
        data={pageData.data?.map((row, index) => ({ ...row, index: index + 1 }))}
        pagination={{
          page: filters.page,
          count: Math.ceil((pageData.pagination?.total || 0) / PAGE_SIZE),
        }}
        onPageChange={handleFilterChange('page')}
        renderColumn={renderColumn}
        emptyMessage={t(`users_management_page.${filters.type}_empty_message`)}
      />

      {changingStatusAccount && (
        <ChangeAccountStatusConfirmDialog
          activate={changingStatusAccount.status === CLIENT_USER_STATUS.ACTIVE}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleConfirmChangeUserStatus}
        />
      )}
      {changingPasswordUser !== null && (
        <SetUserPasswordDialog onConfirm={handleSubmitNewPassword} onClose={handleCloseSetUserPasswordDialog} />
      )}
    </ProtectedPage>
  )
})({
  '& .top-page': {
    display: 'flex',
    columnGap: 60,
    justifyContent: 'space-between',
    position: 'relative',

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },

    '& .filters-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1200,
      rowGap: 16,

      '& .date-filters': {
        display: 'flex',
        columnGap: 16,

        '& .date-field': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
          flex: 1,

          '&.from-field .field-label': {
            minWidth: 75,
          },

          '& .date-picker': {
            flex: 1,

            '& .MuiFormControl-root': {
              flex: 1,
              width: '100%',
            },
          },
        },

        '& .MuiTextField-root': {
          minWidth: 150,

          '& .MuiSelect-select': {
            paddingTop: 12.5,
            paddingBottom: 12.5,
          },

          '& label.MuiInputLabel-shrink, legend': {
            display: 'none',
          },
        },
      },

      '& input': {
        padding: '12px 14px 12px 14px',
        height: 24,
      },

      '& .MuiButton-root': {
        borderWidth: 2,
        fontWeight: 500,
      },
    },

    '& .add-wrapper': {
      display: 'flex',
      alignItems: 'flex-end',

      '& .MuiButton-root': {
        height: 56,
        fontWeight: 600,
      },
    },
  },

  '& .table-col-status, .table-col-actions': {
    textAlign: 'center',
  },
})

export default UsersManagementPage
