import { Email, Key } from '@mui/icons-material'
import { Alert, Button, Link, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import AuthPage from '../components/AuthPage'
import Loading from '../components/Loading'
import StyledInput from '../components/StyledInput'
import { MESSAGE_TYPES } from '../constants/common'
import { authActions } from '../redux/slices/authSlice'
import { getUserProfile, login } from '../services/api'
import { createMessage, isSuccessRequest } from '../utils/common'

let checkingToken = false

const SignInPage = MUIStyled(({ className }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [errorMsg, setErrorMsg] = useState(location.state?.error || '')
  const [queryParams] = useSearchParams()
  const quickAccessToken = queryParams.get('accessToken')
  const [fetchingUserData, setFetchingData] = useState(quickAccessToken)
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('form_validation.email_invalid_format')).required(t('form_validation.required_field')),
    password: Yup.string().required(t('form_validation.required_field')),
  })
  const { handleSubmit, handleChange, errors, touched, isSubmitting } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (submitValues, actions) => {
      actions.setSubmitting(true)

      try {
        const response = await login(submitValues)
        const { admin: user, tokens } = response.data?.data
        if (isSuccessRequest(response.status)) {
          dispatch(authActions.loginSuccess({ user, tokens }))
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: t('signin_page.login_success_message') }))
          return
        }
      } catch (error) {
        setErrorMsg(t(`signin_page.login_fail_${error?.response?.data?.code || 'default'}_message`))
      }
    },
  })

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile(queryParams.get('accessToken'))
        if (isSuccessRequest(response.status)) {
          dispatch(
            authActions.loginSuccess({
              user: response.data.data,
              tokens: { accessToken: { token: queryParams.get('accessToken') } },
            })
          )
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: t('signin_page.login_success_message') }))
        }
      } catch (error) {
        setErrorMsg(t('signin_page.login_fail_token_invalid'))
      }
      setFetchingData(false)
      checkingToken = false
    }

    if (queryParams.get('accessToken') && !checkingToken) {
      checkingToken = true
      fetchUserProfile()
    }
  }, [queryParams, dispatch, t])

  const handleFieldChange = (event) => {
    if (errorMsg) {
      setErrorMsg('')
    }

    handleChange(event)
  }

  return (
    <AuthPage className={['signin-page', className].join(' ')}>
      {(isSubmitting || fetchingUserData) && <Loading />}
      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <div className="form-title">{t('signin_page.title')}</div>
      <form onSubmit={handleSubmit}>
        <StyledInput
          fullWidth
          startAdornment={<Email />}
          placeholder={t('signin_page.email_field_placeholder')}
          name="email"
          onChange={handleFieldChange}
          error={errors.email && touched.email}
          helperText={touched.email && errors.email}
        />
        <StyledInput
          fullWidth
          startAdornment={<Key />}
          placeholder={t('signin_page.password_field_placeholder')}
          type="password"
          name="password"
          onChange={handleFieldChange}
          error={errors.password && touched.password}
          helperText={touched.password && errors.password}
        />
        <div className="forgot-password-link">
          <Link href="/forgot-password">{t('signin_page.forget_password_link_label')}</Link>
        </div>
        <div className="action-wrapper">
          <Button variant="contained" type="submit" color="primary">
            {t('signin_page.login_button_label')}
          </Button>
        </div>
      </form>
    </AuthPage>
  )
})({
  '& .form-title': {
    fontSize: 20,
    fontWeight: 600,
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
  },

  '& .MuiInputBase-root': {
    height: 'auto !important',

    '& svg': {
      color: '#A0A0A0',
    },

    '& input': {
      padding: '16.5px 14px',
    },
  },

  '& .forgot-password-link': {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  '& .action-wrapper': {
    display: 'flex',
    justifyContent: 'center',

    '& button': {
      textTransform: 'none',
      width: '40%',
      height: 44,
    },
  },
})

export default SignInPage
