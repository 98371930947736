import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormHelperText, IconButton, InputLabel, OutlinedInput, styled as MUIStyled } from '@mui/material'
import { useEffect, useState } from 'react'

let renderingValue = ''

const StyledInput = MUIStyled(
  ({ className, label, name, type, error, helperText, readOnly, value, onChange, ...other }) => {
    const [showingPassword, setShowingPassword] = useState(false)
    const [renderValue, setRenderValue] = useState(value || '')

    useEffect(() => {
      if (value !== renderingValue) {
        setRenderValue(value)
      }
    }, [value])

    const togglePasswordVisibility = () => {
      setShowingPassword(!showingPassword)
    }

    const handleInputChange = (event) => {
      setRenderValue(event.target.value)
      renderingValue = event.target.value

      if (typeof onChange === 'function') {
        onChange(event)
      }
    }

    let inputProps = {
      name,
      type,
      error,
      fullWidth: true,
      readOnly,
      value: renderValue || '',
      onChange: handleInputChange,
      ...other,
    }
    if (type === 'password') {
      inputProps = {
        ...inputProps,
        type: showingPassword ? 'text' : 'password',
        endAdornment: (
          <IconButton onClick={togglePasswordVisibility}>
            {showingPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
      }
    }

    return (
      <div className={['form-input-wrapper', className].join(' ')}>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <div className={['input-wrapper', error ? 'error' : '', readOnly ? 'read-only' : ''].join(' ')}>
          <OutlinedInput id={name} {...inputProps} />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </div>
      </div>
    )
  }
)({
  display: 'flex',
  columnGap: 16,

  '& label': {
    fontWeight: 500,
    lineHeight: '40px',
    minWidth: 100,
  },

  '& .MuiInputBase-root': {
    flex: 1,

    '&:not(.MuiInputBase-multiline)': {
      height: 40,

      '& input': {
        padding: '8px 14px',
      },
    },
  },

  '& .input-wrapper': {
    flex: 1,

    '&.error': {
      '& .MuiFormHelperText-root': {
        color: '#d32f2f',
      },
    },

    '&.read-only': {
      '& .Mui-focused': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderWidth: 1,
        },
      },

      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: 1,
      },
    },
  },
})

export default StyledInput
