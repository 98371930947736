import { Button, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Loading from '../components/Loading'
import ProfilePicture from '../components/ProfilePicture'
import ProtectedPage from '../components/ProtectedPage'
import StyledInput from '../components/StyledInput'
import { MESSAGE_TYPES } from '../constants/common'
import { authActions } from '../redux/slices/authSlice'
import { updateProfile } from '../services/api'
import { createMessage, isSuccessRequest } from '../utils/common'

const MyProfilePage = MUIStyled(({ className }) => {
  const [editing, setEditing] = useState(false)
  const { user, accessToken } = useSelector((state) => state.auth, shallowEqual)
  const { full_name, email, profile_picture, phone, permissions } = user || {}
  const dispatch = useDispatch()

  const { handleChange, handleSubmit, setFieldValue, values, resetForm, isSubmitting, setValues } = useFormik({
    initialValues: {
      full_name,
      email,
      phone: phone || '',
      profile_picture,
      permissions: permissions?.map((perm) => perm.name),
    },
    onSubmit: async (formValues, actions) => {
      actions.setSubmitting(true)
      try {
        const response = await updateProfile(user.id, formValues, accessToken)
        if (isSuccessRequest(response.status)) {
          dispatch(authActions.updateUserInfo(response.data?.data))
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Profile updated success.' }))
          setEditing(false)
        }
      } catch {
        dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Profile update failed.' }))
      }
      actions.setSubmitting(false)
    },
  })

  useEffect(() => {
    setValues({
      full_name,
      email,
      phone: phone || '',
      profile_picture,
    })
  }, [full_name, email, phone, profile_picture, setValues])

  const handleEditButtonClick = () => {
    setEditing(true)
  }

  const handleCancelEdit = () => {
    setEditing(false)
    resetForm()
  }

  const handleProfileImageChange = (base64File) => {
    setFieldValue('profile_picture', base64File)
  }

  return (
    <ProtectedPage
      className={['my-profile-page', className].join(' ')}
      title="My Profile"
      breadcrumbs={[{ label: 'My Profile' }]}
    >
      {!editing && (
        <Button variant="contained" className="edit-button" onClick={handleEditButtonClick}>
          Edit
        </Button>
      )}
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <ProfilePicture editing={editing} src={values.profile_picture} onChange={handleProfileImageChange} />
          <StyledInput
            label="Full Name:"
            name="full_name"
            onChange={handleChange}
            value={values.full_name}
            readOnly={!editing}
            fullWidth
          />
          <StyledInput label="Email Address:" readOnly name="email" value={values.email} />
          <StyledInput
            label="Phone Number:"
            name="phone"
            onChange={handleChange}
            value={values.phone}
            readOnly={!editing}
            fullWidth
          />
          {editing && (
            <div className="actions-wrapper">
              <Button variant="outlined" onClick={handleCancelEdit}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </div>
          )}
        </form>
      </div>
      {isSubmitting && <Loading />}
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  '& .edit-button': {
    position: 'absolute',
    top: 20,
    right: 20,
    textTransform: 'none',
  },

  '& .form-wrapper': {
    display: 'flex',
    justifyContent: 'center',
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 24,
    maxWidth: 900,
    flex: 1,

    '& .profile-picture': {
      marginBottom: 24,
    },

    '& label': {
      minWidth: 150,
    },

    '& .form-input-wrapper': {
      width: '100%',
    },

    '& .actions-wrapper': {
      display: 'flex',
      columnGap: 40,
      marginTop: 24,

      '& button': {
        textTransform: 'none',
        width: 150,
      },
    },
  },
})

export default MyProfilePage
