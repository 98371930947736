import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTrans from './en.json'

const languageResources = {
  en: {
    translation: enTrans,
  },
}

export const initTranslations = () => {
  i18next.use(initReactI18next).init({
    resources: languageResources,
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
  })
}
