import { Button, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import FilePicker from '../../components/FilePicker'
import ProtectedPage from '../../components/ProtectedPage'
import { saveStaticPage } from 'services/api'
import { MESSAGE_TYPES } from 'constants/common'
import { createMessage, isSuccessRequest } from 'utils/common'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const PAGE_PATH = '/china-client-configs'

const ChinaClientConfigsPage = MUIStyled(({ className }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const { staticPages } = useSelector((state) => state.layout, shallowEqual)
  const savedData = staticPages[PAGE_PATH]

  const { handleSubmit, values, setFieldValue, setValues } = useFormik({
    initialValues: {
      topSearchImage: {},
      topLocationsImage: {},
      addRouteImage: {},
    },
    onSubmit: async (formValue, actions) => {
      actions.setSubmitting(true)
      try {
        const response = await saveStaticPage(
          savedData?.id,
          {
            path: PAGE_PATH,
            title: 'China Client Configs',
            content: JSON.stringify(formValue),
          },
          accessToken
        )

        if (isSuccessRequest(response.status)) {
          navigate('/static-contents', { replace: true })
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'The configs updated success.' }))
        }
      } catch {
        dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'The configs update failed.' }))
      }
      actions.setSubmitting(false)
    },
  })

  useEffect(() => {
    if (savedData?.id) {
      try {
        const parsedContent = JSON.parse(savedData.content)
        setValues({ ...parsedContent })
      } catch {}
    }
  }, [savedData, setValues])

  const handleFileChange = (fieldName) => (_event, selectedFiles) => {
    setFieldValue(fieldName, (selectedFiles?.length > 0 && selectedFiles[0]) || {})
  }

  return (
    <ProtectedPage
      title="China Client Configs"
      className={['china-client-configs-page', className].join(' ')}
      breadcrumbs={[{ label: 'Static Contents', path: '/static-contents' }, { label: 'China Client Configs' }]}
    >
      <form onSubmit={handleSubmit}>
        <FilePicker
          label="Top Search Locations Image (Broker Home Page)"
          single
          value={[values.topSearchImage]}
          accept=".jpg,.png,.jpeg"
          onChange={handleFileChange('topSearchImage')}
          autoUpload
          uploadAPIType="image"
          accessToken={accessToken}
        />
        <FilePicker
          label="Brokers most common Locations/Ports Image (Buyers/Shipper Home Page)"
          single
          value={[values.topLocationsImage]}
          accept=".jpg,.png,.jpeg"
          autoUpload
          uploadAPIType="image"
          accessToken={accessToken}
          onChange={handleFileChange('topLocationsImage')}
        />

        <FilePicker
          label="Add Route Form Image"
          single
          value={[values.addRouteImage]}
          accept=".jpg,.png,.jpeg"
          autoUpload
          uploadAPIType="image"
          accessToken={accessToken}
          onChange={handleFileChange('addRouteImage')}
        />
        <div className="actions-wrapper">
          <Button variant="outlined" href="/static-contents">
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </div>
      </form>
    </ProtectedPage>
  )
})({
  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 32,

    '& > div > label': {
      minWidth: 150,
    },

    '& .file-picker': {
      flexDirection: 'column',
      rowGap: 12,

      '& > label': {
        fontWeight: 600,
      },

      '& .file-preview': {
        '& img': { width: 200, border: '1px solid #666', borderRadius: 8 },

        '& .filename': {
          display: 'none',
        },
      },
    },

    '& .actions-wrapper': {
      display: 'flex',
      columnGap: 24,
      justifyContent: 'center',

      '& .MuiButton-root': {
        textTransform: 'none',
        minWidth: 120,
      },
    },
  },
})

export default ChinaClientConfigsPage
