import { Alert, CssBaseline, Snackbar, ThemeProvider } from '@mui/material'
import { store, persistor } from './redux/store'
import { Provider, shallowEqual, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { CARGOKWIK_THEME } from './constants/colors'
import { initTranslations } from './languages'
import { routesConfig } from 'constants/routesConfig'
import 'utils/yup-validations'
import { useMemo } from 'react'

initTranslations()

const AppRoutes = () => {
  const { messages } = useSelector((state) => state.layout, shallowEqual)
  const { user } = useSelector((state) => state.auth, shallowEqual)
  const userPermissions = useMemo(() => {
    return user?.permissions?.map((perm) => perm.name) || []
  }, [user])
  const routes = [...routesConfig]
  routes.push({
    path: '/',
    element: (
      <Navigate
        to={
          userPermissions?.includes('ALL') || userPermissions?.includes('DASHBOARD_MANAGEMENT')
            ? '/dashboard'
            : '/my-profile'
        }
        replace
      />
    ),
  })

  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map(({ element, path }, index) => (
            <Route path={path} element={element} key={`route-${index}`} />
          ))}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </BrowserRouter>
      <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <div sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          {Object.values(messages)
            .sort((a, b) => a.createdAt > b.createdAt)
            .map((msg, index) => (
              <Alert severity={msg.type} key={`notification-message-${index}`} sx={{ width: '100%' }}>
                {msg.content}
              </Alert>
            ))}
        </div>
      </Snackbar>
    </>
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={CARGOKWIK_THEME}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
