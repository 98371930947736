import axios from 'axios'
import { URLs } from '../constants/common'

export const getCountries = (accessToken) =>
  axios({
    method: 'GET',
    url: URLs.ADDRESS_API,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getStatesByCountry = (countryId, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.ADDRESS_API}/${countryId}/states`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const login = (data) => {
  return axios({
    method: 'POST',
    url: URLs.LOGIN,
    data,
  })
}

export const getUserProfile = (accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.SUBADMINS_API}/current`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getUserNotifications = (accessToken, queryString) =>
  axios({
    method: 'GET',
    url: `${URLs.NOTIFICATION_API}${queryString || ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const forgetPassword = (data) => {
  return axios({
    method: 'POST',
    url: URLs.FORGET_PASSWORD,
    data,
  })
}

export const verifyForgotPasswordLink = (data) => {
  return axios({
    method: 'POST',
    url: URLs.VERIFY_RESET_PASSWORD_LINK,
    data,
  })
}

export const resetPassword = (data) => {
  return axios({
    method: 'POST',
    url: URLs.RESET_PASSWORD,
    data,
  })
}

export const changePassword = (data, accessToken) => {
  return axios({
    method: 'POST',
    url: URLs.CHANGE_PASSWORD,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const updateProfile = (userId, data, accessToken) => {
  return axios({
    method: 'PUT',
    url: URLs.PROFILE_API.replace('{userId}', userId),
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const getStaticPages = (accessToken) =>
  axios({
    method: 'GET',
    url: URLs.STATIC_PAGES,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const createStaticPage = (data, accessToken) =>
  axios({
    method: 'POST',
    url: URLs.STATIC_PAGES,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const saveStaticPage = (pageId, data, accessToken) => {
  if (!pageId) {
    return createStaticPage(data, accessToken)
  }

  return axios({
    method: 'PUT',
    url: `${URLs.STATIC_PAGES}/${pageId}`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const getFAQs = (queryString, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.FAQ_API}${queryString}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const createFAQ = (data, accessToken) =>
  axios({
    method: 'POST',
    url: URLs.FAQ_API,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getFAQDetail = (faqId, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.FAQ_API}/${faqId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const updateFAQ = (faqId, data, accessToken) =>
  axios({
    method: 'PUT',
    url: `${URLs.FAQ_API}/${faqId}`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const deleteFAQ = (faqId, accessToken) =>
  axios({
    method: 'DELETE',
    url: `${URLs.FAQ_API}/${faqId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const uploadFile = (type, fileData, accessToken) => {
  const data = { [type !== 'file' ? `base64_${type.toLowerCase()}` : 'file']: fileData }
  return axios({
    method: 'POST',
    url: `${URLs.UPLOAD_API}/${type.toLowerCase()}`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const getNotificationList = (accessToken, queryString) =>
  axios({
    method: 'GET',
    url: `${URLs.NOTIFICATION_API}/all${queryString || ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getNotificationDetail = (notificationId, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.NOTIFICATION_API}/${notificationId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const sendNotification = (data, accessToken) =>
  axios({
    method: 'POST',
    url: URLs.NOTIFICATION_API,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getSiteStatistic = (accessToken) =>
  axios({
    method: 'GET',
    url: URLs.STATISTIC_API,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getRFQList = (accessToken, queryString) =>
  axios({
    method: 'GET',
    url: `${URLs.RFQ_API}${queryString}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getRFQ = (rfqId, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.RFQ_API}/${rfqId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getSubscriptionPlans = (accessToken) =>
  axios({
    method: 'GET',
    url: URLs.SUBSCRIPTION_API,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getSubcriptionPlanDetail = (planId, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.SUBSCRIPTION_API}/${planId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const saveSubscriptionPlan = (planId, data, accessToken) =>
  axios({
    method: planId ? 'PUT' : 'POST',
    url: planId ? `${URLs.SUBSCRIPTION_API}/${planId}` : URLs.SUBSCRIPTION_API,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const deleteSubscriptionPlan = (planId, accessToken) =>
  axios({
    method: 'DELETE',
    url: `${URLs.SUBSCRIPTION_API}/${planId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getSubscriptionFeatures = (accessToken) =>
  axios({
    method: 'GET',
    url: URLs.SUBSCRIPTION_FEATURE_API,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const saveSubscriptionFeature = (featureId, data, accessToken) =>
  axios({
    method: featureId ? 'PUT' : 'POST',
    url: featureId ? `${URLs.SUBSCRIPTION_FEATURE_API}/${featureId}` : URLs.SUBSCRIPTION_FEATURE_API,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const deleteSubscriptionFeature = (featureId, accessToken) =>
  axios({
    method: 'DELETE',
    url: `${URLs.SUBSCRIPTION_FEATURE_API}/${featureId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getAdminPermissions = (accessToken) =>
  axios({
    method: 'GET',
    url: URLs.PERMISSIONS_API,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getSubadmins = (accessToken, queryString) =>
  axios({
    method: 'GET',
    url: `${URLs.SUBADMINS_API}${queryString}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getSubadminProfile = (adminId, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.SUBADMINS_API}/${adminId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getSubadminActivityLogs = (queryStr, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.SUBADMINS_API}/action-logs/${queryStr}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const saveSubadminUser = (adminId, data, accessToken) =>
  axios({
    method: adminId ? 'PUT' : 'POST',
    url: adminId ? `${URLs.SUBADMINS_API}/${adminId}` : URLs.SUBADMINS_API,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const updateSubadminAccountStatus = (adminId, active, accessToken) =>
  axios({
    method: 'PUT',
    url: `${URLs.SUBADMINS_API}/${adminId}/${active ? 'activate' : 'deactivate'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const sendLoginLinkToAdminUser = (email, accessToken) =>
  axios({
    method: 'POST',
    url: `${URLs.SUBADMINS_API}/send-login-link`,
    data: { email },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getReviews = (accessToken, queryString = '') =>
  axios({
    method: 'GET',
    url: `${URLs.RATING_API}${queryString || ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const updateReview = (reviewId, data, accessToken) =>
  axios({
    method: 'PUT',
    url: `${URLs.RATING_API}/${reviewId}`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getClientUsers = (accessToken, queryString, signal) =>
  axios({
    method: 'GET',
    url: `${URLs.USERS_API}${queryString || ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    signal,
  })

export const getClientUserDetail = (userId, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.USERS_API}/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const saveClientDetail = (userId, data, accessToken) =>
  axios({
    method: userId ? 'PUT' : 'POST',
    url: userId ? `${URLs.USERS_API}/${userId}` : URLs.USERS_API,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const updateClientUserStatus = (userId, status, accessToken) =>
  axios({
    method: 'PUT',
    url: `${URLs.USERS_API}/${userId}/change-status `,
    data: {
      status,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getLocodeCountriesList = () => {
  return axios({
    method: 'GET',
    url: URLs.LOCODE_COUNTRIES_LIST,
  })
}

export const getLocodeStatesByCountry = (countryId) => {
  return axios({
    method: 'GET',
    url: URLs.LOCODE_STATES_LIST_BY_COUNTRY.replace('{countryId}', countryId),
  })
}

export const getLocodeCities = (countryId, stateId, queryStr) => {
  let url = URLs.LOCODE_CITIES_LIST_BY_COUNTRY.replace('{countryId}', countryId)
  if (stateId) {
    url = URLs.LOCODE_CITIES_LIST_BY_STATE.replace('{countryId}', countryId).replace('{stateId}', stateId)
  }
  if (queryStr) {
    url = `${url}?${queryStr}`
  }
  return axios({
    method: 'GET',
    url,
  })
}

export const getLocodeCitiesByCountry = (countryId, queryStr) => {
  let url = URLs.LOCODE_CITIES_LIST_BY_COUNTRY.replace('{countryId}', countryId)
  if (queryStr) {
    url = `${url}?${queryStr}`
  }
  return axios({
    method: 'GET',
    url,
  })
}

export const getLocodeCitiesByState = (countryId, stateId) => {
  return axios({
    method: 'GET',
    url: URLs.LOCODE_CITIES_LIST_BY_STATE.replace('{countryId}', countryId).replace('{stateId}', stateId),
  })
}

export const getLocodeByName = (queryStr, accessToken) => {
  return axios({
    method: 'GET',
    url: `${URLs.SEARCH_LOCODE}?locode=${queryStr}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const getTransactions = (queryStr, accessToken, downloadFile = false) => {
  return axios({
    method: 'GET',
    url: `${URLs.TRANSACTION_API}${queryStr || ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: downloadFile ? 'blob' : undefined,
  })
}

export const exportDashboardData = (type, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.STATISTIC_API}/${type}/export`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'blob',
  })

export const extendMembership = (userId, newDate, accessToken) =>
  axios({
    method: 'POST',
    url: `${URLs.MEMBERSHIP_API}/users/${userId}/extend`,
    data: { type: 'DATE', value: newDate },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const getRefundRequests = (queryString, accessToken) =>
  axios({
    method: 'GET',
    url: `${URLs.MEMBERSHIP_API}/refund${queryString}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const submitRefundRequest = (refundId, data, accessToken) =>
  axios({
    method: 'PUT',
    url: `${URLs.MEMBERSHIP_API}/refund/${refundId}`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
