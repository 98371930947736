import { Checkbox, FormControlLabel, FormHelperText, styled as MUIStyled } from '@mui/material'
import { CARGO_TYPES } from 'constants/common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const generateDefaultValues = (value) => {
  let defaultMap = {}
  value?.forEach((item) => {
    defaultMap[item] = true
  })

  return defaultMap
}

export const CargoTypesInput = MUIStyled(({ className, name, onChange, value, error }) => {
  const { t } = useTranslation()
  const [selectedBoxes, setSelectedBoxes] = useState(generateDefaultValues(value))

  useEffect(() => {
    setSelectedBoxes(generateDefaultValues(value))
  }, [value])

  const handleBoxChange = (type) => (event) => {
    let newData = { ...selectedBoxes }
    newData[type] = event.target.checked
    setSelectedBoxes(newData)

    if (typeof onChange === 'function') {
      const enabledTypes = []
      Object.keys(newData).forEach((type) => {
        if (newData[type]) {
          enabledTypes.push(type)
        }
      })
      onChange(null, enabledTypes)
    }
  }

  return (
    <div className={['cargo-types-input', error ? 'has-error' : '', className].join(' ')}>
      {Object.keys(CARGO_TYPES).map((type) => (
        <FormControlLabel
          control={
            <Checkbox
              key={`cargo-type-${type}`}
              name={name}
              onChange={handleBoxChange(type)}
              checked={selectedBoxes[type] === true}
            />
          }
          label={t(`cargo_type.${type}`)}
          key={`cargo-type-${type}`}
        />
      ))}
      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  )
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: 24,
  padding: '12px 18px',
  justifyContent: 'space-between',

  '& .MuiFormControlLabel-root': {
    width: '30%',
    minWidth: '30%',
    maxWidth: '30%',
    margin: 0,

    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '18px',
    },

    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: 18,

      '& svg': {
        fontSize: 24,
      },
    },
  },

  '& .MuiFormHelperText-root': {
    width: '100%',
  },

  '&.has-error .MuiFormHelperText-root': {
    color: '#d32f2f',
    fontSize: 14,
  },
}))
