import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'pathname', function (errorMessage) {
  return this.test('testPathname', errorMessage, function (value) {
    const { path, createError } = this
    const regEpx = /^(\/{0,1}(?!\/))[A-Za-z0-9/\-_]+(\.([a-zA-Z]+))?$/

    return !value || regEpx.test(value) || createError({ path, message: errorMessage })
  })
})

Yup.addMethod(Yup.string, 'phone', function (errMessage) {
  return this.test('testPhoneNumber', errMessage, function (value) {
    const { path, createError } = this
    const regEpx = /^(\+)?\d+$/

    return !value || regEpx.test(value) || createError({ path, message: errMessage })
  })
})

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    const set = [...new Set(list.map(mapper))]
    const isUnique = list.length === set.length
    const idx = list.findIndex((l, i) => {
      return mapper(l) !== set[i]
    })

    if (isUnique || idx < 0) {
      return true
    }

    const { path, createError } = this
    return createError({
      path: `${path}.${idx}`,
      message,
    })
  })
})

Yup.addMethod(Yup.string, 'alphaNumeric', function (errMessage, includeMinus = true) {
  return this.test('testAlphaNumeric', errMessage, function (value) {
    const { path, createError } = this
    let regEpx = /^[A-Za-z0-9]+$/
    if (includeMinus) {
      regEpx = /^[A-Za-z0-9-]+$/
    }

    return !value || regEpx.test(value) || createError({ path, message: errMessage })
  })
})

Yup.addMethod(Yup.array, 'acceptFileTypes', function (acceptTypes, errMessage) {
  const cloneAcceptTypes = acceptTypes

  return this.test('testFileTypes', errMessage, function (values) {
    const { path, createError } = this

    let errors = {}
    values.forEach(({ url, filename }, index) => {
      const fileExtension = (filename || '').split('.').slice(-1)[0]
      if (
        url &&
        url.indexOf(';base64') > -1 &&
        (!filename || !cloneAcceptTypes?.map((ext) => ext.toUpperCase()).includes(`.${fileExtension?.toUpperCase()}`))
      ) {
        errors[index] = errMessage
      }
    })

    return !Object.keys(errors).length || createError({ path, message: errors })
  })
})

Yup.addMethod(Yup.string, 'numberic', function (errMessage) {
  return this.test('testNumberic', errMessage, function (value) {
    const { path, createError } = this
    const regEpx = /^\d+$/

    return !value || regEpx.test(value) || createError({ path, message: errMessage })
  })
})
