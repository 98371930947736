import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: {},
  staticPages: {},
  highestPlanOrder: 0,
  highestSubscriptionFeatureOrder: 0,
  countries: [],
  locodeCountries: [],
}

const layoutSlice = createSlice({
  initialState,
  name: 'layout',
  reducers: {
    showMessage: (state, action) => {
      const { id, ...other } = action.payload
      return { ...state, messages: { ...state.messages, [id]: other } }
    },
    hideMessage: (state, action) => {
      const newMessages = { ...state.messages }
      if (newMessages[action.payload]) {
        delete newMessages[action.payload]
      }
      return { ...state, messages: newMessages }
    },
    loadStaticPagesSuccess: (state, action) => {
      return { ...state, staticPages: { ...action.payload } }
    },
    updateHighestPlanOrder: (state, action) => {
      return { ...state, highestPlanOrder: action.payload }
    },
    fetchCountriesSuccess: (state, action) => {
      return { ...state, countries: action.payload }
    },
    fetchLocodeCountriesSuccess: (state, action) => {
      return { ...state, locodeCountries: action.payload }
    },
    updateHighestSubscriptionFeatureOrder: (state, action) => {
      return { ...state, highestSubscriptionFeatureOrder: action.payload }
    },
  },
})
export const { actions: layoutActions, reducer: layoutReducer } = layoutSlice
