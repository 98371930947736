import { createTheme } from '@mui/material'

export const CARGOKWIK_THEME = createTheme({
  palette: {
    primary: {
      main: '#292E6F',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
})
