import { Lock, LockOpen, Search, Send, Visibility } from '@mui/icons-material'
import { Button, Dialog, IconButton, Link, styled as MUIStyled, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DatePicker from '../../components/DatePicker'
import { EditIcon } from '../../components/Icons'
import ProtectedPage from '../../components/ProtectedPage'
import StyledTable from '../../components/StyledTable'
import { MESSAGE_TYPES } from '../../constants/common'
import { getSubadmins, sendLoginLinkToAdminUser, updateSubadminAccountStatus } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

const ChangeAccountStatusConfirmDialog = MUIStyled(({ className, activate, onConfirm, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dialog open {...other} className={['confirm-delete-plan-dialog', className].join(' ')}>
      <div className="message-wrapper">
        <div className="confirm-message">{t('subadmins_management_page.change_user_status_confirm_message')}</div>
        <div className="sub-message">
          {t(`subadmins_management_page.${activate ? 'activate' : 'deactivate'}_user_sub_message`)}
        </div>
      </div>
      <div className="actions-wrapper">
        <Button color="primary" variant="outlined" onClick={other.onClose}>
          {t('subadmins_management_page.change_user_status_cancel_button_label')}
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {t('subadmins_management_page.change_user_status_confirm_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },

  '& .message-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    alignItems: 'center',
  },

  '& .confirm-message': {
    fontSize: 24,
    fontWeight: 600,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 24,
    justifyContent: 'center',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: 100,
  },
})

const TABLE_COLUMNS = [
  { label: 'subadmins_management_page.index_column_label', key: 'index' },
  { label: 'subadmins_management_page.full_name_column_label', key: 'full_name' },
  { label: 'subadmins_management_page.email_column_label', key: 'email' },
  { label: 'subadmins_management_page.phone_column_label', key: 'phone' },
  { label: 'subadmins_management_page.activity_log_column_label', key: 'activity' },
  { label: 'subadmins_management_page.status_column_label', key: 'active' },
  { label: 'subadmins_management_page.created_on_column_label', key: 'createdAt' },
  { label: 'subadmins_management_page.actions_column_label', key: 'actions' },
]
const PAGE_SIZE = 10
const defaultFilters = {
  page: 1,
  start_date: null,
  end_date: null,
  query: '',
}

const SubAdminsManagementPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [pageData, setPageData] = useState({})
  const [filters, setFilters] = useState(defaultFilters)
  const [fetchingData, setFetchingData] = useState(false)
  const [changingAccountStatus, setChangingAccountStatus] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchAdminUserList = async () => {
      setFetchingData(true)
      try {
        const queryString = Object.entries(filters)
          .filter(([key, value]) => Boolean(value) && ((key === 'page' && value > 1) || key !== 'page'))
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&')
        const response = await getSubadmins(accessToken, `?${queryString}`)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch {
        setPageData({})
      }
      setFetchingData(false)
    }
    if (accessToken) {
      fetchAdminUserList()
    }
  }, [accessToken, filters])

  const handleFilterChange = (filterName) => (event, value) => {
    let newValue

    switch (filterName) {
      case 'page':
        newValue = { ...filters, [filterName]: value }
        break

      case 'start_date':
      case 'end_date':
        newValue = { ...filters, [filterName]: value, page: 1 }
        break

      case 'query':
        newValue = { ...filters, [filterName]: event.target.value, page: 1 }
        break

      default:
        newValue = filters
    }

    setFilters(newValue)
  }

  const handleClearFilters = () => {
    setFilters(defaultFilters)
  }

  const toggleAccountStatus = (userId) => () => {
    setChangingAccountStatus(userId)
  }

  const handleCloseChangeAccountStatusConfirmDialog = () => {
    setChangingAccountStatus(null)
  }

  const handleConfirmChangeAccountStatus = async () => {
    const { id, active } = changingAccountStatus
    try {
      const response = await updateSubadminAccountStatus(id, !active, accessToken)
      if (isSuccessRequest(response.status)) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t(`subadmins_management_page.${active ? 'deactivate' : 'activate'}_user_success`),
          })
        )
        setFilters({ ...filters })
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t(`subadmins_management_page.${active ? 'deactivate' : 'activate'}_user_failed`),
        })
      )
    }

    handleCloseChangeAccountStatusConfirmDialog()
  }

  const handleSendLoginLinkClick = (email) => async () => {
    try {
      const response = await sendLoginLinkToAdminUser(email, accessToken)
      if (isSuccessRequest(response.status)) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t('subadmins_management_page.sent_login_link_success'),
          })
        )
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t('subadmins_management_page.sent_login_link_failed'),
        })
      )
    }
  }

  const renderColumn = (name, value, record) => {
    let cellContent

    switch (name) {
      case 'activity':
        cellContent = (
          <Link href={`/subadmins/${record.id}/activity-logs`}>
            {t(`subadmins_management_page.user_activity_link_label`, { username: record.email.split('@')[0] })}
          </Link>
        )
        break

      case 'createdAt':
        cellContent = dayjs(value).format('MM-DD-YYYY')
        break

      case 'active':
        cellContent = t(`subadmins_management_page.${value ? 'active' : 'deactivated'}_status_user`)
        break

      case 'actions':
        cellContent = (
          <div className="actions-wrapper">
            <IconButton
              color="primary"
              title={t('subadmins_management_page.send_login_link_button_title')}
              onClick={handleSendLoginLinkClick(record.email)}
              disabled={!record.active}
            >
              <Send />
            </IconButton>
            <IconButton
              color="primary"
              title={t('subadmins_management_page.view_user_button_title')}
              href={`/subadmins/${record.id}`}
            >
              <Visibility />
            </IconButton>
            <IconButton
              color={record.active ? 'error' : 'success'}
              title={t(`subadmins_management_page.${record.active ? 'deactivate' : 'activate'}_user_button_title`)}
              onClick={toggleAccountStatus(record)}
            >
              {record.active ? <Lock /> : <LockOpen />}
            </IconButton>
            <IconButton
              color="primary"
              title={t('subadmins_management_page.edit_user_button_title')}
              href={`/subadmins/${record.id}/edit`}
            >
              <EditIcon />
            </IconButton>
          </div>
        )
        break

      default:
        cellContent = value
    }

    return cellContent
  }

  const disabledClearFilters =
    Object.entries(filters).filter(([key, value]) => key !== 'page' && Boolean(value)).length === 0

  return (
    <ProtectedPage
      breadcrumbs={[{ label: t('subadmins_management_page.title') }]}
      title={t('subadmins_management_page.title')}
      className={['subadmin-management-page', className].join(' ')}
    >
      <div className="top-page">
        <div className="filters-wrapper">
          <TextField
            className="search-input"
            InputProps={{ startAdornment: <Search /> }}
            placeholder={t('subadmins_management_page.filter_input_placeholder')}
            onChange={handleFilterChange('query')}
            value={filters.query}
          />
          <div className="date-filters">
            <div className="date-field from-field">
              <div className="field-label">{t('subadmins_management_page.filter_by_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('start_date')}
                value={filters.start_date}
              />
            </div>
            <div className="date-field">
              <div className="field-label"> {t('subadmins_management_page.filter_to_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('end_date')}
                value={filters.end_date}
                minDate={filters.start_date ? dayjs(filters.start_date) : undefined}
              />
            </div>
            <Button variant="contained" color="primary" onClick={handleClearFilters} disabled={disabledClearFilters}>
              {t('subadmins_management_page.clear_filters_button_label')}
            </Button>
          </div>
        </div>
        <div className="add-wrapper">
          <Button variant="contained" color="primary" href="/subadmins/add-new">
            {t('subadmins_management_page.add_button_label')}
          </Button>
        </div>
      </div>
      <StyledTable
        columns={TABLE_COLUMNS}
        loading={fetchingData}
        data={pageData.data?.map((row, index) => ({ ...row, index: index + 1 }))}
        pagination={{
          page: filters.page,
          count: Math.ceil((pageData.pagination?.total || 0) / PAGE_SIZE),
        }}
        onPageChange={handleFilterChange('page')}
        renderColumn={renderColumn}
        emptyMessage={t('subadmins_management_page.empty_message')}
      />
      {changingAccountStatus && (
        <ChangeAccountStatusConfirmDialog
          onClose={handleCloseChangeAccountStatusConfirmDialog}
          onConfirm={handleConfirmChangeAccountStatus}
          activate={!changingAccountStatus.active}
        />
      )}
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    rowGap: '40px !important',
  },

  '& .top-page': {
    display: 'flex',
    columnGap: 60,
    justifyContent: 'space-between',
    position: 'relative',

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },

    '& .filters-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1200,
      rowGap: 16,

      '& .date-filters': {
        display: 'flex',
        columnGap: 16,

        '& .date-field': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
          flex: 1,

          '&.from-field .field-label': {
            minWidth: 75,
          },

          '& .date-picker': {
            flex: 1,

            '& .MuiFormControl-root': {
              flex: 1,
              width: '100%',
            },
          },
        },
      },

      '& input': {
        padding: '12px 14px 12px 14px',
        height: 24,
      },

      '& .MuiButton-root': {
        borderWidth: 2,
        fontWeight: 500,
      },
    },

    '& .add-wrapper': {
      display: 'flex',
      alignItems: 'flex-end',

      '& .MuiButton-root': {
        height: 56,
        fontWeight: 600,
      },
    },
  },

  '& .table-col-actions': {
    textAlign: 'center',
    maxWidth: 240,
    width: 240,

    '& .actions-wrapper': {
      display: 'flex',
      columnGap: 20,
      justifyContent: 'center',
    },
  },
})

export default SubAdminsManagementPage
