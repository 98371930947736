import { Autocomplete, styled as MUIStyled, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

const ListItem = MUIStyled('li')({
  padding: 8,
})

const StyledOptionItem = MUIStyled(({ className, data, option, selectedItem }) => {
  return (
    <ListItem
      {...data}
      key={`${option.id}_${Math.random()}`}
      className={['city-option', className, selectedItem ? 'selecting' : ''].join(' ')}
    >
      {option.name}
    </ListItem>
  )
})({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '&.selecting': {
    backgroundColor: 'rgba(41, 46, 111, 0.08)',
  },
})

const CitySelect = MUIStyled(({ classsName, onSearchChange, label, searchingValue, error, value, ...other }) => {
  const [renderValue, setRenderValue] = useState(value)
  const [searchingCity, setSearchingCity] = useState(false)

  useEffect(() => {
    if (!searchingCity) {
      setRenderValue(value)
    }
  }, [value, searchingCity])

  const handleInputChange = async (value) => {
    if (typeof onSearchChange === 'function') {
      setRenderValue(null)
      setSearchingCity(true)
      await onSearchChange(value)
    }
  }

  const handleItemSelect = (event, selectedValue, reason) => {
    setSearchingCity(false)
    if (typeof other.onChange === 'function') {
      other.onChange(event, selectedValue)
    }
  }

  return (
    <Autocomplete
      disablePortal
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          handleInputChange(value)
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, placeholder: label, value: searchingValue || params.inputProps.value }}
            error={Boolean(error)}
            helperText={error}
          />
        )
      }}
      isOptionEqualToValue={(opt) => opt.id === value?.id}
      renderOption={(props, option) => (
        <StyledOptionItem
          data={props}
          key={`${option.id}_${Math.random()}`}
          option={option}
          selectedItem={value?.id === option.id}
        />
      )}
      {...other}
      value={renderValue}
      className={['city-select-field', classsName].join(' ')}
      onChange={handleItemSelect}
    />
  )
})(({ theme }) => ({
  '& .city-option': {
    backgroundColor: 'red',
  },
}))

export default CitySelect
