import { Search } from '@mui/icons-material'
import { Button, FormControlLabel, Radio, RadioGroup, styled as MUIStyled, TextField } from '@mui/material'
import DatePicker from 'components/DatePicker'
import Loading from 'components/Loading'
import ProtectedPage from 'components/ProtectedPage'
import StyledTable from 'components/StyledTable'
import { MESSAGE_TYPES } from 'constants/common'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getTransactions } from 'services/api'
import { createMessage, downloadFile, isSuccessRequest } from 'utils/common'
import { PaymentPageHeader } from './PageHeader'

const FILTER_PERIODS = {
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_3MONTHS: 'LAST_3MONTHS',
  LAST_6MONTHS: 'LAST_6MONTHS',
  LAST_1YEAR: 'LAST_1YEAR',
}

const defaultFilters = {
  start_date: '',
  end_date: '',
  query: '',
  page: 1,
  period: FILTER_PERIODS.CURRENT_MONTH,
  queryTime: Date.now(),
}
const PAGE_SIZE = 10
const TABLE_COLUMNS = [
  {
    label: 'transactions_page.transaction_id_column_label',
    key: '_id',
  },
  {
    label: 'transactions_page.name_column_label',
    key: 'user.company_info.company_name',
  },
  {
    label: 'transactions_page.email_column_label',
    key: 'user.email',
  },
  {
    label: 'transactions_page.membership_column_label',
    key: 'membership',
  },
  {
    label: 'transactions_page.transaction_type_column_label',
    key: 'transaction_type',
  },
  {
    label: 'transactions_page.payment_method_column_label',
    key: 'payment_method',
  },
  {
    label: 'transactions_page.date_time_column_label',
    key: 'updatedAt',
  },
  {
    label: 'transactions_page.amount_column_label',
    key: 'amount',
  },
]

const generateQueryString = ({ start_date, end_date, period, page, query }, exportCSV = false) => {
  let queries = []
  if (query) {
    queries.push(`name=${encodeURIComponent(query)}`)
  }
  if (page > 1) {
    queries.push(`page=${page}`)
  }
  let start_date_query = start_date
  if (!start_date && !end_date) {
    switch (period) {
      case FILTER_PERIODS.CURRENT_MONTH:
        start_date_query = dayjs().startOf('month').format('MM/DD/YYYY')
        break

      case FILTER_PERIODS.LAST_3MONTHS:
        start_date_query = dayjs().subtract(3, 'months').format('MM/DD/YYYY')
        break

      case FILTER_PERIODS.LAST_6MONTHS:
        start_date_query = dayjs().subtract(6, 'months').format('MM/DD/YYYY')
        break
      case FILTER_PERIODS.LAST_1YEAR:
        start_date_query = dayjs().subtract(1, 'years').format('MM/DD/YYYY')
        break

      default:
        break
    }
  }
  if (start_date_query) {
    queries.push(`start_date=${encodeURIComponent(start_date_query)}`)
  }
  if (end_date) {
    queries.push(`end_date=${encodeURIComponent(end_date)}`)
  }

  if (exportCSV) {
    queries.push('export=true')
  }

  if (queries.length > 0) {
    return `?${queries.join('&')}`
  }

  return ''
}

export const TransactionsPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const title = t('transactions_page.title')
  const [filters, setFilters] = useState(defaultFilters)
  const [pageData, setPageData] = useState({})
  const [fetchingData, setFetchingData] = useState(false)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const [exportingCSV, setExportingCSV] = useState(false)

  useEffect(() => {
    const fetchTransaction = async () => {
      setPageData({})
      setFetchingData(true)
      try {
        const queryStr = generateQueryString(filters)
        const response = await getTransactions(queryStr, accessToken)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch {}
      setFetchingData(false)
    }

    if (accessToken) {
      fetchTransaction()
    }
  }, [accessToken, filters])

  const handleFilterChange = (filterName) => (event, value) => {
    let newValue

    switch (filterName) {
      case 'page':
        newValue = { ...filters, [filterName]: value }
        break

      case 'start_date':
      case 'end_date':
        newValue = { ...filters, [filterName]: value, page: 1 }
        break

      case 'query':
      case 'period':
        newValue = { ...filters, [filterName]: event.target.value, page: 1 }
        break

      default:
        newValue = filters
    }

    setFilters(newValue)
  }

  const renderColumn = (name, value, record) => {
    switch (name) {
      case '_id':
        return value.substring(value.length - 8)

      case 'membership':
        return t(`subscription_plan.${value}`)

      case 'transaction_type':
        return t(`transaction_type.${value}`)

      case 'payment_method':
        return t(`payment_method.${value}`)

      case 'updatedAt':
        return dayjs(value).format('MM/DD/YYYY HH:mm A')

      case 'amount':
        return `$${value || 0}`

      default:
        return value
    }
  }

  const handleExportClick = async () => {
    try {
      setExportingCSV(true)
      const queryStr = generateQueryString(filters, true)
      const response = await getTransactions(queryStr, accessToken, true)
      if (isSuccessRequest(response.status)) {
        downloadFile(response.data, 'payment_transactions.csv')
      }
      setExportingCSV(false)
    } catch {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: t('transactions_page.export_csv_error_message') }))
    }
  }

  return (
    <ProtectedPage
      title={title}
      breadcrumbs={[{ label: title }]}
      className={['transactions-page', className].join(' ')}
    >
      <PaymentPageHeader type="TRANSACTIONS" />
      <div className="top-page">
        <div className="row">
          <TextField
            className="search-input"
            InputProps={{ startAdornment: <Search /> }}
            placeholder={t('transactions_page.filter_input_placeholder')}
            onChange={handleFilterChange('query')}
            value={filters.query}
          />
          <Button variant="contained" onClick={handleExportClick} disabled={exportingCSV} className="export-csv-button">
            <span>{t('transactions_page.export_csv_button_label')}</span>
            {exportingCSV && <Loading />}
          </Button>
        </div>
        <div className="field-label filter-label">{t('transactions_page.filter_by_label')}</div>
        <div className="filters-wrapper">
          <div className="period-filters">
            <div className="field-label">{t('transactions_page.filter_time_period_label')}:</div>
            <RadioGroup onChange={handleFilterChange('period')}>
              {Object.keys(FILTER_PERIODS).map((period) => (
                <FormControlLabel
                  key={`filter-option-${period}`}
                  control={<Radio checked={filters.period === period} />}
                  label={t(`transactions_page.${period}_period_option_label`)}
                  value={period}
                />
              ))}
            </RadioGroup>
          </div>
          <div className="row apply-row">
            <div className="date-filters">
              <div className="date-field from-field">
                <div className="field-label">{t('transactions_page.filter_dates_label')}:</div>
                <DatePicker
                  inputFormat={'MM/DD/YYYY'}
                  views={['year', 'month', 'day']}
                  onChange={handleFilterChange('start_date')}
                  value={filters.start_date}
                />
              </div>
              <div className="date-field">
                <div className="field-label"> {t('transactions_page.filter_to_label')}:</div>
                <DatePicker
                  inputFormat={'MM/DD/YYYY'}
                  views={['year', 'month', 'day']}
                  onChange={handleFilterChange('end_date')}
                  value={filters.end_date}
                  minDate={filters.start_date ? dayjs(filters.start_date) : undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyledTable
        columns={TABLE_COLUMNS}
        loading={fetchingData}
        data={pageData.data?.filter((row) => Boolean(row.user)).map((row, index) => ({ ...row, index: index + 1 }))}
        pagination={{
          page: filters.page,
          count: Math.ceil((pageData.pagination?.total || 0) / PAGE_SIZE),
        }}
        onPageChange={handleFilterChange('page')}
        renderColumn={renderColumn}
        emptyMessage={t('transactions_page.empty_message')}
      />
    </ProtectedPage>
  )
})({
  '& .MuiButton-root': {
    textTransform: 'none',
  },

  '& .top-page': {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },

    '& .filters-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      padding: 24,

      '& .period-filters': {
        display: 'flex',
        columnGap: 32,
        alignItems: 'center',

        '& .MuiFormGroup-root': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 16,
          flexDirection: 'row',
        },
      },

      '& .date-filters': {
        display: 'flex',
        columnGap: 16,

        '& .date-field': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
          flex: 1,

          '&.from-field .field-label': {
            minWidth: 75,
          },

          '& .date-picker': {
            flex: 1,

            '& .MuiFormControl-root': {
              flex: 1,
              width: '100%',
            },
          },
        },
      },

      '& input': {
        padding: '12px 14px 12px 14px',
        height: 24,
      },

      '& .MuiButton-root': {
        borderWidth: 2,
        fontWeight: 500,
      },
    },

    '& .add-wrapper': {
      display: 'flex',
      alignItems: 'flex-end',

      '& .MuiButton-root': {
        height: 56,
        fontWeight: 600,
      },
    },

    '& .export-csv-button': {
      display: 'flex',
      position: 'relative',
      columnGap: 12,

      '& .loading': {
        position: 'relative',
        backgroundColor: 'transparent',
        width: 'auto',

        '& .MuiCircularProgress-root': {
          width: '20px !important',
          height: '20px !important',
        },

        '& svg': {
          color: '#fff',
        },
      },
    },
  },

  '& .row': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .filter-label': {
    marginTop: 24,
  },

  '& .search-input': {
    minWidth: '50%',
  },

  '& .table-col-membership, .table-col-transaction-type, .table-col-payment-method, .table-col-updatedat, .table-col-amount':
    {
      textAlign: 'center',
    },

  '& .table-wrapper': {
    maxWidth: 'calc(100vw - 440px)',
    overflowX: 'auto',
  },
})
