import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  accessToken: '',
  refreshToken: '',
  newNotifications: 0,
  adminPermissionList: [],
}

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    loginSuccess: (state, action) => {
      const { user, tokens } = action.payload
      return {
        ...state,
        accessToken: tokens.accessToken.token,
        refreshToken: tokens.refreshToken?.token || '',
        user,
      }
    },

    updateUserInfo: (state, action) => {
      return { ...state, user: action.payload }
    },

    logout: () => {
      return initialState
    },

    checkNewNotifications: () => {},
    updateNewNotifications: (state, action) => {
      return { ...state, newNotifications: action.payload }
    },

    fetchAdminPermissionListSuccess: (state, action) => {
      return { ...state, adminPermissionList: action.payload }
    },
  },
})

export const { actions: authActions, reducer: authReducer } = authSlice
