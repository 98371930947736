import axios from 'axios'
import { MESSAGE_TYPES } from '../constants/common'
import { authActions } from '../redux/slices/authSlice'
import { store } from '../redux/store'
import { createMessage } from '../utils/common'

let refreshingToken = false

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    const {
      response: { status },
    } = error

    if (status === 401) {
      if (!refreshingToken) {
        refreshingToken = true
        store.dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Your login session is expired.' }))
        store.dispatch(authActions.logout())

        setTimeout(() => {
          refreshingToken = false
        }, 1000)
      }
    }

    throw error
  }
)
