import { Delete } from '@mui/icons-material'
import { Button, Dialog, IconButton, styled as MUIStyled, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { EditIcon } from 'components/Icons'
import Loading from 'components/Loading'
import ProtectedPage from 'components/ProtectedPage'
import StyledTable from 'components/StyledTable'
import { MESSAGE_TYPES } from 'constants/common'
import { deleteSubscriptionPlan, getSubscriptionPlans, saveSubscriptionPlan } from 'services/api'
import { createMessage, isSuccessRequest, isTrialPlan } from 'utils/common'

const DeletePlanConfirmDialog = MUIStyled(({ className, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dialog open {...other} className={['confirm-delete-plan-dialog', className].join(' ')}>
      <div className="message-wrapper">
        <div className="confirm-message">{t('subscriptions_management_page.delete_plan_confirm_message')}</div>
        <div className="sub-message">{t('subscriptions_management_page.delete_plan_sub_message')}</div>
      </div>
      <div className="actions-wrapper">
        <Button color="primary" variant="contained" onClick={other.onClose}>
          {t('subscriptions_management_page.delete_plan_dialog_cancel_button_label')}
        </Button>
        <Button color="error" variant="contained" onClick={other.onConfirm}>
          {t('subscriptions_management_page.delete_plan_dialog_delete_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },

  '& .message-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    alignItems: 'center',
  },

  '& .confirm-message': {
    fontSize: 24,
    fontWeight: 600,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 24,
    justifyContent: 'center',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: 100,
  },
})

const TABLE_COLUMNS = [
  {
    label: 'subscriptions_management_page.index_column_label',
    key: 'index',
  },
  {
    label: 'subscriptions_management_page.name_column_label',
    key: 'name',
  },
  {
    label: 'subscriptions_management_page.types_column_label',
    key: 'types',
  },
  {
    label: 'subscriptions_management_page.amount_column_label',
    key: 'amount',
  },
  {
    label: 'subscriptions_management_page.description_column_label',
    key: 'description',
  },
  {
    label: 'subscriptions_management_page.status_column_label',
    key: 'active',
  },
  {
    label: 'subscriptions_management_page.actions_column_label',
    key: 'actions',
  },
]

const parseSubscriptionRenderableData = (plan, t) => {
  let amount = []
  let types = []
  const { name, description, active, id, trial_time, monthly, yearly, order } = plan

  if (order >= 0) {
    types = [t('subscription_plan.MONTH'), t('subscription_plan.ANNUAL')]
    amount = [`$${monthly}`, `$${yearly}`]
  } else {
    types.push(t('subscription_plan.TRIAL'))
    amount.push(`${trial_time} ${t('subscription_plan_form_page.trial_time_field_unit_label')}`)
  }

  return { name, description, active, amount, types, id }
}

const SubscriptionsManagementPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const [subscriptionPlans, setSubscriptionPlans] = useState({})
  const [fetchingData, setFetchingData] = useState(true)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const [deletingPlan, setDeletingPlan] = useState(null)

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      setFetchingData(true)
      try {
        const response = await getSubscriptionPlans(accessToken)
        if (isSuccessRequest(response.status)) {
          let data = {}
          response.data?.data
            ?.sort((a, b) => a.order - b.order)
            .forEach((plan, index) => {
              data[plan.id] = { ...parseSubscriptionRenderableData(plan, t), index: index + 1 }
            })
          setSubscriptionPlans(data)
        }
      } catch {
        setSubscriptionPlans({})
      }
      setFetchingData(false)
    }

    if (accessToken) {
      fetchSubscriptionPlans()
    }
  }, [accessToken, t])

  const toggleSubscriptionPlanStatus =
    ({ id, ...other }) =>
    async () => {
      try {
        const response = await saveSubscriptionPlan(id, { ...other, active: !other.active }, accessToken)
        if (isSuccessRequest(response.status)) {
          setSubscriptionPlans({ ...subscriptionPlans, [id]: { ...subscriptionPlans[id], active: !other.active } })
          dispatch(
            createMessage({
              type: MESSAGE_TYPES.SUCCESS,
              content: t(`subscriptions_management_page.${!other.active ? 'activate' : 'deactivate'}_plan_success`),
            })
          )
        }
      } catch {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.ERROR,
            content: t(`subscriptions_management_page.${!other.active ? 'activate' : 'deactivate'}_plan_failed`),
          })
        )
      }
    }

  const handleClickDeletePlan = (planId) => () => {
    setDeletingPlan(planId)
  }

  const handleConfirmedDetele = async () => {
    try {
      const response = await deleteSubscriptionPlan(deletingPlan, accessToken)
      if (isSuccessRequest(response.status)) {
        const newPlans = { ...subscriptionPlans }
        delete newPlans[deletingPlan]
        setSubscriptionPlans(newPlans)

        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t('subscriptions_management_page.delete_plan_success'),
          })
        )
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t('subscriptions_management_page.delete_plan_failed'),
        })
      )
    }

    handleCloseDeleteDialog()
  }

  const renderColumn = (columnName, value, plan) => {
    switch (columnName) {
      case 'types':
        return (
          <div className="types-wrapper">
            {value.map((item, index) => (
              <span key={`${columnName}-${index}`}>{item}</span>
            ))}
          </div>
        )

      case 'amount':
        return (
          <div className="amount-wrapper">
            {value.map((item, index) => (
              <span key={`${columnName}-${index}`}>{item}</span>
            ))}
          </div>
        )

      case 'active':
        return <Switch checked={value} onChange={toggleSubscriptionPlanStatus(plan)} disabled={isTrialPlan(plan)} />

      case 'actions':
        return (
          <div className="actions-wrapper">
            <IconButton href={`/subscriptions/${plan.id}/edit`} color="primary">
              <EditIcon />
            </IconButton>
            {!isTrialPlan(plan) && (
              <IconButton color="error" onClick={handleClickDeletePlan(plan.id)}>
                <Delete />
              </IconButton>
            )}
          </div>
        )
      default:
        return value
    }
  }

  const handleCloseDeleteDialog = () => {
    setDeletingPlan(null)
  }

  return (
    <ProtectedPage
      title={
        <>
          <div className="main-title">{t('subscriptions_management_page.title')}</div>
          <div className="buttons-wrapper">
            <Button color="primary" variant="contained" href="/subscriptions/features">
              {t('subscriptions_management_page.manage_features_button_label')}
            </Button>
            <Button color="primary" variant="contained" href="/subscriptions/new">
              {t('subscriptions_management_page.add_new_plan_button_label')}
            </Button>
          </div>
        </>
      }
      className={['subsciptions-management-page', className].join(' ')}
      breadcrumbs={[{ label: t('subscriptions_management_page.title') }]}
    >
      {fetchingData && <Loading />}
      <StyledTable
        columns={TABLE_COLUMNS}
        data={Object.values(subscriptionPlans)}
        renderColumn={renderColumn}
      ></StyledTable>
      {deletingPlan && (
        <DeletePlanConfirmDialog
          open={Boolean(deletingPlan)}
          onConfirm={handleConfirmedDetele}
          onClose={handleCloseDeleteDialog}
        />
      )}
    </ProtectedPage>
  )
})({
  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiButton-root': {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: 16,
    },

    '& .buttons-wrapper': {
      display: 'flex',
      columnGap: 16,
    },
  },

  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',

    '& .loading': {
      backgroundColor: 'transparent',
    },
  },

  '& table': {
    '& .table-col-index, .table-col-name, .table-col-types, .table-col-actions, .table-col-amount': {
      textAlign: 'center',
    },

    '& .types-wrapper, .amount-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      alignItems: 'center',

      '& > span': {
        minWidth: 50,
        display: 'block',
        textAlign: 'left',
      },
    },
  },
})

export default SubscriptionsManagementPage
