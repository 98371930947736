import { Button, Dialog, styled as MUIStyled } from '@mui/material'
import Loading from 'components/Loading'
import StyledInput from 'components/StyledInput'
import { MESSAGE_TYPES } from 'constants/common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { submitRefundRequest } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'

export const RefundingRequestDialog = MUIStyled(({ className, onClose, transaction, onSuccess, ...other }) => {
  console.log('dasdasd', transaction.refund_amount)
  const { t } = useTranslation()
  const [refundAmount, setRefundAmount] = useState(transaction?.refund_amount?.toFixed(2) || 0)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const handleChangeRefundAmount = (event) => {
    setRefundAmount(event.target.value)
  }

  const handleSubmitRefundRequest = async () => {
    setSubmitting(true)
    try {
      const response = await submitRefundRequest(
        transaction.id,
        { amount: refundAmount, reason: 'Admin Approved' },
        accessToken
      )
      if (isSuccessRequest(response.status)) {
        if (typeof onClose === 'function') {
          onClose()
        }

        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t('refund_request_page.submit_refund_request_success'),
          })
        )
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t('refund_request_page.submit_refund_request_failed'),
        })
      )
    }
    setSubmitting(false)
  }

  return (
    <Dialog {...other} onClose={onClose} className={['refund-dialog', className].join(' ')}>
      {submitting && <Loading />}
      <div className="dialog-title">{t('refund_request_page.refund_dialog_title')}</div>
      <div className="content-wrapper">
        <div className="field-wrapper">
          <div className="field-label">{t('refund_request_page.refund_dialog_transaction_id_field_label')}:</div>
          <div className="field-value">{transaction.transaction.id}</div>
        </div>
        <div className="field-wrapper">
          <div className="field-label">{t('refund_request_page.refund_dialog_broker_name_field_label')}:</div>
          <div className="field-value">{transaction.user?.company_info?.company_name}</div>
        </div>
        <div className="field-wrapper">
          <div className="field-label">{t('refund_request_page.refund_dialog_calculated_amount_field_label')}:</div>
          <div className="field-value">${transaction?.refund_amount?.toFixed(2)}</div>
        </div>
        <StyledInput
          type="number"
          inputProps={{ min: 0, max: transaction?.transaction?.amount }}
          value={refundAmount}
          label={`${t('refund_request_page.refund_dialog_amount_input_label')}:`}
          onChange={handleChangeRefundAmount}
        />
      </div>
      <div className="actions-wrapper">
        <Button variant="outlined" onClick={onClose}>
          {t('refund_request_page.refund_dialog_cancel_button_label')}
        </Button>
        <Button variant="contained" onClick={handleSubmitRefundRequest}>
          {t('refund_request_page.refund_dialog_submit_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 500,
    padding: 32,
  },

  '& .dialog-title': {
    fontSize: 28,
    fontWeight: 600,
    marginBottom: 32,
    textAlign: 'center',
  },

  '& .content-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },

  '& .field-wrapper': {
    display: 'flex',
    columnGap: 16,

    '& .field-label': {
      width: 165,
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },

  '& .actions-wrapper': {
    marginTop: 32,
    display: 'flex',
    columnGap: 32,
    justifyContent: 'center',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    fontWeight: 600,
    width: 120,
  },
}))
