import { Alert, Button, Dialog, styled as MUIStyled, Switch } from '@mui/material'
import { useFormik } from 'formik'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Loading from 'components/Loading'
import ProtectedPage from 'components/ProtectedPage'
import StyledInput from 'components/StyledInput'
import TextareaAutosize from 'components/TextareaAutoSize'
import { HTTP_STATUS, MESSAGE_TYPES } from 'constants/common'
import { getSubcriptionPlanDetail, saveSubscriptionPlan } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'
import { SubscriptionFeaturesInput } from 'components/SubscriptionFeaturesInput'

const ConfirmDiscardChangeDialog = MUIStyled(({ className, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dialog open {...other} className={['confirm-discard-change-dialog', className].join(' ')}>
      <div className="confirm-message">{t('subscription_plan_form_page.discard_change_plan_message')}</div>
      <div className="actions-wrapper">
        <Button color="primary" variant="contained" onClick={other.onClose}>
          {t('subscription_plan_form_page.discard_change_dialog_cancel_button_label')}
        </Button>
        <Button color="error" variant="contained" href="/subscriptions">
          {t('subscription_plan_form_page.discard_change_dialog_discard_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
  },

  '& .confirm-message': {
    fontSize: 20,
    fontWeight: 500,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 24,
    justifyContent: 'center',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    width: 100,
  },
})

export const PLAN_TYPES = {
  MONTH: 'MONTH',
  ANNUAL: 'ANNUAL',
  TRIAL: 'TRIAL',
}

const SubscriptionPlanFormPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const [showingDiscardChangeDialog, setShowingDiscardChangeDialog] = useState(false)
  const { planId } = useParams()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const { highestPlanOrder } = useSelector((state) => state.layout, shallowEqual)
  const [changedFields, setChangedFields] = useState([])
  const dispatch = useDispatch()
  const [submitError, setSubmitError] = useState('')
  const [fetchingData, setFetchingData] = useState(Boolean(planId))

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(50, t('form_validation.max_length', { max: 50 }))
      .required(t('form_validation.required_field')),
    description: Yup.string()
      .max(500, t('form_validation.max_length', { max: 500 }))
      .required(t('form_validation.required_field')),
    order: Yup.number(),
    trial_time: Yup.number().when('order', {
      is: Yup.number().moreThan(0),
      then: Yup.number()
        .test('check_trial_time', t('subscription_plan_form_page.trial_time_invalid'), (value) => {
          return value !== 0
        })
        .required(t('form_validation.required_field')),
    }),
  })
  const { errors, handleChange, handleSubmit, setValues, setFieldValue, values, touched, initialValues, isSubmitting } =
    useFormik({
      initialValues: {
        name: '',
        description: '',
        yearly: 0,
        monthly: 0,
        active: true,
        trial_time: 0,
        order: highestPlanOrder,
        features: [],
      },
      validationSchema,
      onSubmit: async (submittedValues, actions) => {
        actions.setSubmitting(true)
        try {
          setSubmitError('')
          const response = await saveSubscriptionPlan(planId, submittedValues, accessToken)
          if (isSuccessRequest(response.status)) {
            navigate('/subscriptions')
            dispatch(
              createMessage({
                type: MESSAGE_TYPES.SUCCESS,
                content: t(`subscription_plan_form_page.${planId ? 'update' : 'create'}_subscription_plan_success`),
              })
            )
          }
        } catch {
          setSubmitError(t(`subscription_plan_form_page.${planId ? 'update' : 'create'}_subscription_plan_success`))
        }
        actions.setSubmitting(false)
      },
    })

  useEffect(() => {
    const fetchSubscriptionPlanDetail = async () => {
      try {
        const response = await getSubcriptionPlanDetail(planId, accessToken)
        if (isSuccessRequest(response.status)) {
          const { name, description, yearly, monthly, active, trial_time, order, features } = response.data?.data
          setValues({ name, description, active, trial_time, yearly, monthly, order, features })
        }
      } catch (error) {
        if (error?.response?.status === HTTP_STATUS.NOT_FOUND) {
          navigate('/404')
        } else {
          navigate('/500')
        }
      }
      setFetchingData(false)
    }

    if (planId) {
      fetchSubscriptionPlanDetail()
    }
  }, [planId, accessToken, setValues, navigate])

  const handleDiscardChangeClick = () => {
    if (changedFields.length > 0) {
      setShowingDiscardChangeDialog(true)
    } else {
      navigate('/subscriptions')
    }
  }

  const handleCloseDiscardChangeDialog = () => {
    setShowingDiscardChangeDialog(false)
  }

  const handleFieldChange = (fieldName) => (event) => {
    setSubmitError('')

    let fieldChanged = false
    const fieldIndex = changedFields.indexOf(fieldName)

    if (fieldName === 'features') {
      setFieldValue(fieldName, event)
      fieldChanged = true
    } else {
      handleChange(event)
      fieldChanged = get(initialValues, fieldName) !== event.target.value && fieldIndex < 0
    }

    if (fieldChanged) {
      setChangedFields([...changedFields, fieldName])
    } else {
      setChangedFields([...changedFields.slice(0, fieldIndex), ...changedFields.slice(fieldIndex + 1)])
    }
  }

  const title = t(`subscription_plan_form_page.${planId ? 'edit' : 'add'}_subscription_plan_title`)
  const breadcrumbs = [
    { label: t('subscriptions_management_page.title'), path: '/subscriptions' },
    {
      label: title,
    },
  ]

  return (
    <ProtectedPage
      className={['subscription-plan-form', className].join(' ')}
      breadcrumbs={breadcrumbs}
      title={
        <>
          <div className="main-title">{title}</div>
          <Button variant="contained" color="primary" onClick={handleDiscardChangeClick}>
            {t('subscription_plan_form_page.back_button_label')}
          </Button>
        </>
      }
    >
      <div className="form-wrapper">
        {submitError && <Alert severity="error">{submitError}</Alert>}
        {(isSubmitting || fetchingData) && <Loading />}
        <form onSubmit={handleSubmit}>
          <StyledInput
            label={t('subscription_plan_form_page.plan_name_field_label')}
            name="name"
            onChange={handleFieldChange('name')}
            value={values.name}
            helperText={touched.name && errors.name}
            error={errors.name && touched.name}
          />
          <div className="form-input-wrapper">
            <div className="field-label">{t('subscription_plan_form_page.description_field_label')}</div>
            <TextareaAutosize
              name="description"
              minRows={5}
              onChange={handleFieldChange('description')}
              value={values.description}
              helperText={touched.name && errors.name}
              error={errors.name && touched.name}
            />
          </div>
          {(!planId || values.trial_time === 0) && (
            <div className="form-input-wrapper">
              <div className="field-label">{t('subscription_plan_form_page.price_field_label')}</div>
              <div className="prices-input-wrapper">
                <div className="input-wrapper">
                  <StyledInput
                    name="monthly"
                    onChange={handleFieldChange('monthly')}
                    value={values.monthly}
                    type="number"
                    inputProps={{ min: 0 }}
                    disabled={values.order < 0}
                  />
                  <div className="price-unit">{t('subscription_plan_form_page.price_month_unit_label')}</div>
                </div>
                <div className="input-wrapper">
                  <StyledInput
                    name="yearly"
                    onChange={handleFieldChange('yearly')}
                    value={values.yearly}
                    type="number"
                    inputProps={{ min: 0 }}
                    disabled={values.order < 0}
                  />
                  <div className="field-unit">{t('subscription_plan_form_page.price_year_unit_label')}</div>
                </div>
              </div>
            </div>
          )}

          {planId && values.order < 0 && (
            <div className={['form-input-wrapper', 'trial-time-field'].join(' ')}>
              <div className="field-label">{t('subscription_plan_form_page.trial_time_field_label')}</div>
              <div className="input-wrapper">
                <StyledInput
                  name="trial_time"
                  onChange={handleFieldChange('trial_time')}
                  value={values.trial_time}
                  type="number"
                  inputProps={{ min: 0 }}
                  error={errors.trial_time && touched.trial_time}
                  helperText={touched.trial_time && errors.trial_time}
                />
                <div className="field-unit">{t('subscription_plan_form_page.trial_time_field_unit_label')}</div>
              </div>
            </div>
          )}
          <SubscriptionFeaturesInput
            label={t('subscription_plan_form_page.features_field_label')}
            value={values.features}
            onChange={handleFieldChange('features')}
          />

          {(!planId || values.order >= 0) && (
            <>
              <div className="form-input-wrapper align-items-center">
                <div className="field-label">{t('subscription_plan_form_page.publish_status_field_label')}</div>
                <Switch checked={values.active} onChange={handleFieldChange('active')} name="active" />
              </div>
              <div className="form-input-wrapper align-items-center">
                <div className="field-label">{t('subscription_plan_form_page.order_field_label')}</div>
                <StyledInput
                  name="order"
                  onChange={handleFieldChange('order')}
                  value={values.order}
                  type="number"
                  inputProps={{ min: 0 }}
                  className="order-input"
                />
              </div>
            </>
          )}
          <div className="actions-wrapper">
            <Button variant="contained" color="primary" type="submit">
              {t('subscription_plan_form_page.save_button_label')}
            </Button>
          </div>
        </form>
      </div>

      {showingDiscardChangeDialog && <ConfirmDiscardChangeDialog onClose={handleCloseDiscardChangeDialog} />}
    </ProtectedPage>
  )
})({
  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 100,
    },
  },

  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',
  },

  '& .form-wrapper': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },

  '& .MuiAlert-root': {
    marginBottom: 24,
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
    width: '100%',

    '& .form-input-wrapper': {
      display: 'flex',
      columnGap: 24,

      '& label, .field-label': {
        fontWeight: 500,
        width: 120,
        color: 'rgba(0, 0, 0, 0.6)',
      },

      '& .textarea-autosize-field': {
        flex: 1,
      },

      '& .prices-input-wrapper': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 16,
        '& .input-wrapper': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
          width: 100,
        },
      },

      '& .trial-plan': {
        width: 'auto',
      },

      '&.trial-time-field': {
        width: 'auto !important',

        '& .field-label': {
          minWidth: 100,
          marginTop: 8,
        },

        '& > .input-wrapper': {
          display: 'flex',
          columnGap: 16,
        },

        '& .field-unit': {
          marginTop: 8,
        },
      },

      '& .controller-wrapper': {
        display: 'flex',
        alignItems: 'center',
        columnGap: 12,
      },

      '& .order-input': {
        width: 100,
      },

      '&.align-items-center': {
        alignItems: 'center',
      },
    },

    '& .actions-wrapper': {
      paddingLeft: 144,

      '& .MuiButton-root': {
        textTransform: 'none',
        width: 150,
      },
    },
  },
})

export default SubscriptionPlanFormPage
