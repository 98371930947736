import { FileDownload } from '@mui/icons-material'
import { Button, IconButton, styled as MUIStyled } from '@mui/material'
import { FileIcon, ImageFileIcon, PDFFileIcon } from 'components/Icons'
import ProfilePicture from 'components/ProfilePicture'
import { CLIENT_USER_STATUS, MESSAGE_TYPES } from 'constants/common'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateClientUserStatus } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'

const CompanyRegistrationDoc = MUIStyled(({ className, document }) => {
  const { filename, url } = document
  const fileExtention = filename.split('.').at(-1)
  let fileIcon = <FileIcon />
  if (fileExtention?.toLowerCase() === 'pdf') {
    fileIcon = <PDFFileIcon />
  } else if (['png', 'jpg', 'jpeg'].includes(fileExtention?.toLowerCase())) {
    fileIcon = <ImageFileIcon />
  }
  return (
    <div className={['registraion-doc-item', className].join(' ')}>
      <div className="file-info-wrapper">
        {fileIcon}
        <div className="file-name">{filename}</div>
      </div>
      <div className="download-button-wrapper">
        <IconButton href={url} download target="_blank">
          <FileDownload />
        </IconButton>
      </div>
    </div>
  )
})({
  display: 'flex',
  columnGap: 12,

  '& .file-name': {
    fontSize: 12,
  },

  '& .MuiIconButton-root svg': {
    fontSize: 32,
  },
})

const renderRegionsServiced = (regionsServices) => {
  const map = {}

  regionsServices.forEach(({ continent, region, country }) => {
    if (!map[continent]) {
      map[continent] = {}
    }

    if (!map[continent][region]) {
      map[continent][region] = []
    }

    map[continent][region].push(country)
  })

  return Object.entries(map).map(([continent, regions]) => (
    <div className="continent-wrapper">
      <div className="continent" key={`continent-${continent}`}>
        {`${continent} - `}
      </div>
      <div className="regions-wrapper">
        {Object.entries(regions).map(([region, countries]) => (
          <div className="region-item" key={`region-${region}`}>
            {`${region}: ${countries.join(', ')}`}
          </div>
        ))}
      </div>
    </div>
  ))
}

const CompanyDetails = MUIStyled(({ className, data, profilePicture }) => {
  const { t } = useTranslation()

  return (
    <div className={['session', 'company-details-session', className].join(' ')}>
      <div className="session-title">{t('user_detail_page.company_info_session_title')}</div>
      <div className="session-content">
        <div className="row">
          <div className="column left-col">
            <div className="field-item">
              <div className="field-label">{t('user_detail_page.company_name_field_label')}:</div>
              <div className="field-value">{data?.company_name}</div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('user_detail_page.company_tax_id_field_label')}:</div>
              <div className="field-value">{data?.tax}</div>
            </div>
            {data?.registration_number && (
              <div className="field-item">
                <div className="field-label">{t('user_detail_page.company_registration_id_field_label')}:</div>
                <div className="field-value">{data.registration_number}</div>
              </div>
            )}
            <div className="field-item">
              <div className="field-label">{t('user_detail_page.company_formatted_on_label_field')}:</div>
              <div className="field-value">{dayjs(data?.started_on).format('MM/DD/YYYY')}</div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('user_detail_page.company_registration_docs_label_field')}:</div>
              <div className="field-value">
                {data?.registration_docs
                  ?.filter((doc) => doc.filename && doc.url)
                  .map((doc) => (
                    <CompanyRegistrationDoc document={doc} key={`doc-${doc._id}`} />
                  ))}
              </div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('user_detail_page.company_general_information_field_label')}:</div>
              <div
                className="field-value"
                dangerouslySetInnerHTML={{
                  __html: data?.general_information?.replaceAll('\n', '<br/>'),
                }}
              />
            </div>
            <div className="field-item">
              <div className="field-label">{t('user_detail_page.cargo_types_field_label')}:</div>
              <div className="field-value">{data?.cargo_types?.map((type) => t(`cargo_type.${type}`)).join(', ')}</div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('user_detail_page.handling_types_field_label')}:</div>
              <div className="field-value">
                {data?.handing_types?.map((type) => t(`handling_type.${type}`)).join(', ')}
              </div>
            </div>
          </div>
          <div className="column right-col">
            <ProfilePicture src={profilePicture} />
          </div>
        </div>
        <div className="row">
          <div className="field-item regions-serviced-field">
            <div className="field-label">{t('user_detail_page.regions_serviced_field_label')}:</div>
            <div className="field-value">{renderRegionsServiced(data?.region_serviced)}</div>
          </div>
        </div>
      </div>
    </div>
  )
})({
  '& .row': {
    display: 'flex',

    '& .column': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,

      '&.right-col': {
        alignItems: 'center',

        '& .MuiAvatar-root': {
          width: 150,
          height: 150,
        },
      },
    },
  },
})

const ContactInfoDetails = MUIStyled(({ className, userData }) => {
  const { t } = useTranslation()

  return (
    <div className={['session', 'contact-info-session', className].join(' ')}>
      <div className="session-title">{t('user_detail_page.contact_info_session_title')}</div>
      <div className="session-content">
        <div className="field-item">
          <div className="field-label">{t('user_detail_page.contact_name_field_label')}:</div>
          <div className="field-value">{userData.company_info?.contact_name}</div>
        </div>
        <div className="field-item">
          <div className="field-label">{t('user_detail_page.address_field_label')}:</div>
          <div className="field-value">
            {[
              userData.company_info?.address,
              userData.company_info?.city,
              userData.company_info?.state,
              userData.company_info?.country,
            ]
              .filter((item) => Boolean(item))
              .join(', ')}
          </div>
        </div>
        <div className="field-item">
          <div className="field-label">{t('user_detail_page.email_field_label')}:</div>
          <div className="field-value">{userData.email}</div>
        </div>
        <div className="field-item">
          <div className="field-label">{t('user_detail_page.phone_field_label')}:</div>
          <div className="field-value">{userData.company_info?.phones?.join(', ')}</div>
        </div>
        <div className="field-item">
          <div className="field-label">{t('user_detail_page.fax_field_label')}:</div>
          <div className="field-value">{userData.company_info?.faxes?.join(', ')}</div>
        </div>

        <div className="field-item">
          <div className="field-label">{t('user_detail_page.languages_served_field_label')}:</div>
          <div className="field-value">
            {userData.company_info?.language_served?.map((lang) => t(`served_language.${lang}`)).join(', ')}
          </div>
        </div>
      </div>
    </div>
  )
})({})

const MembershipTransactionLog = MUIStyled(({ className, data }) => {
  const { t } = useTranslation()

  return (
    <div className={['session', 'membership-transaction-log-session', className].join(' ')}>
      <div className="session-title">{t('user_detail_page.membership_transaction_log_session_title')}</div>
      <div className="session-content"></div>
    </div>
  )
})({})

const MemberApproving = MUIStyled(({ className, userData }) => {
  const { t } = useTranslation()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChangeUserStatus = (newStatus) => async () => {
    try {
      const response = await updateClientUserStatus(userData.id, newStatus, accessToken)
      if (isSuccessRequest(response.status)) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t(
              `user_detail_page.${newStatus === CLIENT_USER_STATUS.ACTIVE ? 'approve' : 'decline'}_user_success`
            ),
          })
        )
        navigate('/users')
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t(`user_detail_page.${newStatus === CLIENT_USER_STATUS.ACTIVE ? 'approve' : 'decline'}_user_failed`),
        })
      )
    }
  }

  return (
    <div className={['session', 'member-approving-session', className].join(' ')}>
      <div className="session-content">
        <div className="field-item">
          <div className="field-label">{t('user_detail_page.membership_field_label')}:</div>
          <div className="field-value">{t('user_detail_page.free_membership_field_value')}</div>
        </div>
        <div className="actions-wrapper">
          <Button variant="contained" color="error" onClick={handleChangeUserStatus(CLIENT_USER_STATUS.REJECTED)}>
            {t('user_detail_page.broker_decline_button_label')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleChangeUserStatus(CLIENT_USER_STATUS.ACTIVE)}>
            {t('user_detail_page.broker_approve_button_label')}
          </Button>
        </div>
      </div>
    </div>
  )
})({
  '& .field-label': {
    width: '150px !important',
  },

  '& .actions-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 24,
    marginTop: 60,

    '& .MuiButton-root': {
      textTransform: 'none',
    },
  },
})

export const BrokerDetail = MUIStyled(({ className, userData }) => {
  if (!userData) {
    return null
  }

  return (
    <div className={['broker-detail', className].join(' ')}>
      <CompanyDetails data={userData?.company_info} />
      <ContactInfoDetails userData={userData} />
      {userData.status !== CLIENT_USER_STATUS.PENDING &&
        userData.status !== CLIENT_USER_STATUS.VERIFYING &&
        userData.status !== CLIENT_USER_STATUS.VERIFIED && <MembershipTransactionLog />}
      {userData.status === CLIENT_USER_STATUS.PENDING && <MemberApproving userData={userData} />}
    </div>
  )
})({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 40,

  '& .regions-serviced-field': {
    flexDirection: 'column',
    rowGap: 8,

    '& .field-value': {
      padding: '0 24px',
      fontWeight: 500,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,

      '& .continent-wrapper': {
        display: 'flex',
      },

      '& .continent': {
        minWidth: 200,
      },

      '& .regions-wrapper': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
      },

      '& .region-item': {
        display: 'flex',

        '& .region-name': {
          display: 'inline-block',
          marginRight: 8,
        },
      },
    },
  },
})
