import { Checkbox, FormControlLabel, InputLabel, styled as MUIStyled } from '@mui/material'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getSubscriptionFeatures } from 'services/api'
import { isSuccessRequest } from 'utils/common'

export const SubscriptionFeaturesInput = MUIStyled(({ className, label, value, onChange }) => {
  const [features, setFeatures] = useState([])
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [renderValues, setRenderValues] = useState(value || [])

  useEffect(() => {
    setRenderValues(value || [])
  }, [value])

  useEffect(() => {
    const fetchSubscriptionFeatures = async () => {
      try {
        const response = await getSubscriptionFeatures(accessToken)
        if (isSuccessRequest(response.status)) {
          const features = response.data.data?.sort((a, b) => a.order - b.order)
          setFeatures(features)
        }
      } catch {}
    }

    if (accessToken) {
      fetchSubscriptionFeatures()
    }
  }, [accessToken])

  const handleFeatureCheck = (featureId) => (event) => {
    let newValues = [...renderValues]
    if (event.target.checked) {
      newValues.push(featureId)
    } else {
      const featureIndex = newValues.indexOf(featureId)
      newValues = [...newValues.slice(0, featureIndex), ...newValues.slice(featureIndex + 1)]
    }
    setRenderValues(newValues)
    if (typeof onChange === 'function') {
      onChange(newValues)
    }
  }

  return (
    <div className={['form-input-wrapper', 'subscription-features-input', className].join(' ')}>
      <InputLabel>{label}</InputLabel>
      <div className="inputs-wrapper">
        {features.map((feature) => (
          <FormControlLabel
            label={feature.name}
            key={`subscription-feature-${feature.id}`}
            control={<Checkbox checked={renderValues.includes(feature.id)} onChange={handleFeatureCheck(feature.id)} />}
          />
        ))}
      </div>
    </div>
  )
})({
  '& .inputs-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    '& label': {
      width: 'auto !important',
    },
  },
})
