export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED_SUCCESS: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  OTHER: -1,
}

export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const CARGO_TYPES = {
  TEXTILES: 'TEXTILES',
  AUTOMOTIVE: 'AUTOMOTIVE',
  ELECTRONICS: 'ELECTRONICS',
  FURNITURE: 'FURNITURE',
  GENERAL_CONSUMER_GOODS: 'GENERAL_CONSUMER_GOODS',
  MATERIALS: 'MATERIALS',
  PERISHABLES: 'PERISHABLES',
}

export const HANDLING_TYPES = {
  OVERWEIGHT: 'OVERWEIGHT',
  OVERSIZED: 'OVERSIZED',
  REFRIGERATED: 'REFRIGERATED',
  HAZARDOUS_GOODS: 'HAZARDOUS_GOODS',
}

export const URLs = {
  ADDRESS_API: '/address/countries',
  LOGIN: '/admin/signin',
  FORGET_PASSWORD: '/admin/forgot-password',
  RESET_PASSWORD: '/admin/reset-password',
  VERIFY_RESET_PASSWORD_LINK: '/admin/verify-reset-password',
  CHANGE_PASSWORD: '/admin/change-password',
  PROFILE_API: '/admin/{userId}',
  UPLOAD_API: '/upload',
  STATIC_PAGES: '/admin/pages',
  FAQ_API: '/faq',
  NOTIFICATION_API: '/admin/notification',
  STATISTIC_API: '/admin/statistic',
  RFQ_API: '/rfq',
  SUBSCRIPTION_API: '/admin/subscriptions',
  SUBSCRIPTION_FEATURE_API: '/admin/features',
  SUBADMINS_API: '/admin',
  PERMISSIONS_API: '/admin/permissions',
  RATING_API: '/rating',
  USERS_API: '/admin/users',
  LOCODE_COUNTRIES_LIST: '/locodes/countries',
  LOCODE_CITIES_LIST_BY_COUNTRY: '/locodes/countries/{countryId}/cities',
  LOCODE_CITIES_LIST_BY_STATE: '/locodes/countries/{countryId}/states/{stateId}/cities',
  LOCODE_STATES_LIST_BY_COUNTRY: '/locodes/countries/{countryId}/states',
  TRANSACTION_API: '/admin/transactions',
  MEMBERSHIP_API: '/admin/memberships',
}

export const FAQ_TYPES = {
  GENERAL: 'General',
  BROKER: 'Broker',
  BUYER_SHIPPER: 'Buyer/Shipper',
}

export const CLIENT_USER_TYPES = {
  BROKER: 'BROKER',
  BUYER_SHIPPER: 'BUYER',
}
export const CLIENT_USER_STATUS = {
  VERIFYING: 'VERIFYING',
  VERIFIED: 'VERIFIED',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  BLOCK: 'BLOCK',
  REJECTED: 'REJECTED',
}

export const SERVED_LANGUAGE_OPTIONS = {
  ENGLISH: 'ENGLISH',
  CHINESE: 'CHINESE',
  SPANISH: 'SPANISH',
}

export const TRANSPORT_MODES = {
  RAIL: 'RAIL',
  AIR: 'AIR',
  TRUCK: 'TRUCK',
  SEA: 'SEA',
}

export const PAYMENT_METHODS = {
  CARD: 'CARD',
}
