import { styled as MUIStyled } from '@mui/material'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import ProtectedPage from '../../components/ProtectedPage'
import { getNotificationDetail } from '../../services/api'
import { isSuccessRequest } from '../../utils/common'
import { generateNotificationReceiversInfo } from './SendNotification'

const NotificationDetailPage = MUIStyled(({ className }) => {
  const { notificationId } = useParams()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [data, setData] = useState(null)
  const [fetchingData, setFetchingData] = useState(true)

  useEffect(() => {
    const fetchNotificationDetail = async () => {
      setFetchingData(true)
      try {
        const response = await getNotificationDetail(notificationId, accessToken)
        if (isSuccessRequest(response.status)) {
          setData(response.data?.data)
        }
      } catch {
        navigate('/404')
      }
      setFetchingData(false)
    }

    if (notificationId && accessToken) {
      fetchNotificationDetail()
    } else {
      navigate('/404', { replace: true })
    }
  }, [notificationId, accessToken, navigate])

  const notification = data || {}

  return (
    <ProtectedPage
      title="Notification View"
      breadcrumbs={[{ label: 'Notification Management', path: '/notifications' }, { label: 'View' }]}
      className={['notification-detail-page', className].join(' ')}
    >
      {fetchingData && <Loading />}
      {notification && !fetchingData && (
        <>
          <div className="field-info">
            <div className="field-label">Title: </div>
            <div className="field-content">{notification.title || ''}</div>
          </div>
          <div className="field-info">
            <div className="field-label">Description: </div>
            <div
              className="field-content"
              dangerouslySetInnerHTML={{ __html: (notification.description || '').replaceAll('\n', '<br/>') }}
            />
          </div>
          <div className="field-info">
            <div className="field-label">Sent To: </div>
            <div className="field-content">{generateNotificationReceiversInfo(notification.send_to)}</div>
          </div>
        </>
      )}
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    position: 'relative',
    '& .field-info': {
      display: 'flex',
      columnGap: 12,
      fontWeight: 500,
      fontSize: 16,

      '& .field-label': {
        fontWeight: 600,
        minWidth: 120,
      },
    },
  },
})

export default NotificationDetailPage
