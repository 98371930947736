import { Email } from '@mui/icons-material'
import { Alert, Button, styled as MUIStyled, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import AuthPage from '../components/AuthPage'
import { SentMailIcon } from '../components/Icons'
import Loading from '../components/Loading'
import { forgetPassword } from '../services/api'
import { isSuccessRequest } from '../utils/common'

const FORGET_RESULTS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
}

const ForgotPasswordPage = MUIStyled(({ className }) => {
  const [submitResult, setSubmitResult] = useState('')

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address.').required('This is required field.'),
  })
  const { handleSubmit, handleChange, errors, touched, isSubmitting, values } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (submitValues, actions) => {
      setSubmitResult('')
      actions.setSubmitting(true)

      try {
        const response = await forgetPassword(submitValues)
        if (isSuccessRequest(response.status)) {
          setSubmitResult(FORGET_RESULTS.SUCCESS)
        }
      } catch {
        setSubmitResult(FORGET_RESULTS.FAILED)
      }
    },
  })

  return (
    <AuthPage className={['forgot-password-page', className].join(' ')}>
      {isSubmitting && <Loading />}
      {submitResult === FORGET_RESULTS.FAILED && (
        <Alert severity="error">
          Forget your account's password unsuccess. Please check the entered email and try again.
        </Alert>
      )}
      {submitResult !== FORGET_RESULTS.SUCCESS && (
        <>
          <div className="form-title">Forgot your account password?</div>
          <div className="sub-message">
            Please enter your registered email address, we will help you to retrieve your password.
          </div>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              InputProps={{ startAdornment: <Email /> }}
              placeholder="Email"
              name="email"
              onChange={handleChange}
              error={errors.email && touched.email}
              helperText={touched.email && errors.email}
            />

            <div className="action-wrapper">
              <Button variant="contained" type="submit" color="primary">
                Submit
              </Button>
            </div>
          </form>
        </>
      )}
      {submitResult === FORGET_RESULTS.SUCCESS && (
        <div className="forget-password-success">
          <SentMailIcon />
          <div className="success-message">
            {`We have sent a reset password link to your email address - ${values.email}`}
          </div>
        </div>
      )}
    </AuthPage>
  )
})({
  '& .form-title': {
    fontSize: 20,
    fontWeight: 600,
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
  },

  '& .MuiTextField-root': {
    '& svg': {
      color: '#A0A0A0',
    },

    '& input': {
      padding: '16.5px 14px',
    },
  },

  '& .forgot-password-link': {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  '& .action-wrapper': {
    display: 'flex',
    justifyContent: 'center',

    '& button': {
      textTransform: 'none',
      width: '40%',
      height: 44,
    },
  },

  '& .forget-password-success': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 24,

    '& svg': {
      width: 120,
      height: 120,
    },
  },
})

export default ForgotPasswordPage
