import { Button, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import FAQField from '../../components/FAQField'
import Loading from '../../components/Loading'
import ProtectedPage from '../../components/ProtectedPage'
import { MESSAGE_TYPES } from '../../constants/common'
import { createFAQ } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

const defaultQuestionValue = { question: '', answer: '', type: [] }

const AddNewFAQPage = MUIStyled(({ className }) => {
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required('This is required field.'),
        answer: Yup.string().required('This is required field.'),
        type: Yup.array().of(Yup.string()).min(1, 'You have select 1 type for this question.'),
      })
    ),
  })
  const { handleSubmit, values, errors, setFieldValue, isSubmitting, touched } = useFormik({
    initialValues: {
      questions: [defaultQuestionValue],
    },
    validationSchema,
    onSubmit: async (formValue, actions) => {
      actions.setSubmitting(true)
      try {
        const response = await createFAQ(formValue.questions, accessToken)
        if (isSuccessRequest(response.status)) {
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'The questions added success.' }))
          actions.resetForm()
        }
      } catch {
        dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'The questions add failed.' }))
      }
      actions.setSubmitting(false)
    },
  })

  const handleFieldChange = (fieldname, value) => {
    setFieldValue(fieldname, value)
  }

  const handleAddMoreQuestion = (event) => {
    event.preventDefault()
    if (values.questions.length < 5) {
      setFieldValue('questions', [...values.questions, defaultQuestionValue])
    }
  }

  return (
    <ProtectedPage
      title="Add New FAQ"
      className={['add-new-faq-page', className].join(' ')}
      breadcrumbs={[
        { label: 'Static Contents', path: '/static-contents' },
        { label: 'FAQs', path: '/static-contents/faqs' },
        { label: 'Add New FAQ', path: '/static-contents/faqs/add' },
      ]}
    >
      {isSubmitting && <Loading />}

      <form onSubmit={handleSubmit}>
        <div className="actions-wrapper">
          <Button variant="outlined" href="/static-contents/faqs">
            Back
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </div>
        {values.questions.map((_, index) => (
          <FAQField
            name={`questions[${index}]`}
            key={`questions[${index}]`}
            value={values.questions[index]}
            onChange={handleFieldChange}
            error={errors.questions && errors.questions[index]}
            touched={touched.questions && touched.questions[index]}
          />
        ))}
        <div className="add-more-wrapper">
          <a href="#/" onClick={handleAddMoreQuestion}>
            Add more
          </a>
          <div className="description">(in one go you can add upto 5.)</div>
        </div>
      </form>
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 32,
  },

  '& .actions-wrapper': {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 24,

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },
  },

  '& .add-more-wrapper': {
    '& a': {
      textDecoration: 'none',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.25,
      color: '#0A349E',
    },
    '& .description': {
      fontSize: 12,
      fontStyle: 'italic',
    },
  },
})

export default AddNewFAQPage
