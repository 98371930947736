import { styled as MUIStyled } from '@mui/material'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Header from './Header'

const PublicPage = MUIStyled(({ children, className }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true })
    }
  }, [user, navigate])

  if (!user) {
    return (
      <div className={['public-page', className].join(' ')}>
        <Header />
        {children}
      </div>
    )
  }

  return null
})({
  background:
    'linear-gradient(0deg, rgba(33, 59, 128, 0.45) 18.23%, rgba(18, 43, 106, 0.45) 36.78%, rgba(24, 55, 115, 0.45) 57.88%, rgba(18, 28, 118, 0.45) 83.03%)',
  minHeight: '100vh',

  '& .page-header': {
    position: 'fixed',
    top: 0,
  },
})

export default PublicPage
