import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, styled as MUIStyled, TextField } from '@mui/material'
import { useState } from 'react'

const PasswordInput = MUIStyled((props) => {
  const [showingPassword, setShowingPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowingPassword(!showingPassword)
  }

  return (
    <TextField
      {...props}
      type={showingPassword ? 'text' : 'password'}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <IconButton onClick={togglePasswordVisibility}>
            {showingPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
      }}
    />
  )
})({})

export default PasswordInput
