import { CameraAlt } from '@mui/icons-material'
import { Avatar, styled as MUIStyled } from '@mui/material'
import defaultAvatar from '../assets/images/default_avatar.svg'
import { toBase64 } from '../utils/common'

const ProfilePicture = MUIStyled(({ className, src, editing, onChange }) => {
  let base64File
  const handleImageChange = async (event) => {
    try {
      base64File = await toBase64(event.target.files[0])
    } catch {
      base64File = ''
    }
    if (typeof onChange === 'function') {
      onChange(null, base64File)
    }
  }
  return (
    <div className={['profile-picture', className].join(' ')}>
      <Avatar src={src || defaultAvatar} size="lagre"></Avatar>
      {editing && (
        <label className="file-select">
          <CameraAlt />
          <input type="file" hidden accept="image/png,image/jpg,image/jpeg" onChange={handleImageChange} />
        </label>
      )}
    </div>
  )
})({
  position: 'relative',
  width: 190,
  height: 190,
  borderRadius: '50%',

  '& label.file-select': {
    bottom: 0,
    right: 0,
    position: 'absolute',
    padding: 0,
    minWidth: 0,
    height: 32,

    '& svg': {
      fontSize: 32,
      color: '#A0A0A0',
    },
  },

  '& .MuiAvatar-root': {
    width: 190,
    height: 190,
    borderRadius: '50%',
    overflow: 'hidden',
  },
})

export default ProfilePicture
