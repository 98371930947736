import { Alert, Button, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import AuthPage from '../components/AuthPage'
import Loading from '../components/Loading'
import PasswordInput from '../components/PasswordInput'
import { MESSAGE_TYPES } from '../constants/common'
import { resetPassword, verifyForgotPasswordLink } from '../services/api'
import { createMessage, isSuccessRequest, parseURLQuery } from '../utils/common'

const RESET_RESULTS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
}

let verifyingToken = false

const ResetPasswordPage = MUIStyled(({ className }) => {
  const [submitResult, setSubmitResult] = useState('')
  const [resetToken, setResetToken] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, 'The password must have minimum 6 characters.').required('This is required field.'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('This is required field.'),
  })
  const { handleSubmit, handleChange, errors, touched, isSubmitting } = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: async (submitValues, actions) => {
      setSubmitResult('')
      actions.setSubmitting(true)

      try {
        const response = await resetPassword({ password: submitValues.password, token: resetToken })
        if (isSuccessRequest(response.status)) {
          dispatch(
            createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Your password has been changed successful.' })
          )
          navigate('/login', { replace: true })
        }
      } catch {
        setSubmitResult(RESET_RESULTS.FAILED)
      }
    },
  })

  const location = useLocation()
  useEffect(() => {
    const { token } = parseURLQuery(location.search)

    const verifyToken = async () => {
      try {
        const response = await verifyForgotPasswordLink({ token })
        if (isSuccessRequest(response.status)) {
          setResetToken(response.data.token)
        }
      } catch {
        navigate('/login', {
          state: { error: 'The reset password link is incorrect or expired. Please try to forget password again.' },
          replace: true,
        })
      }
    }

    if (!token) {
      navigate('/404', { replace: true })
    } else if (!verifyingToken) {
      verifyingToken = true
      verifyToken()
    }
  }, [location.search, navigate])

  return (
    <AuthPage className={['forgot-password-page', className].join(' ')}>
      {isSubmitting && <Loading />}
      {submitResult === RESET_RESULTS.FAILED && (
        <Alert severity="error">Reset your account's password unsuccess.</Alert>
      )}
      {submitResult !== RESET_RESULTS.SUCCESS && (
        <>
          <div>
            <div className="form-title">Set new password</div>
            <div className="sub-message">Enter your new password for your account.</div>
          </div>
          <form onSubmit={handleSubmit}>
            <PasswordInput
              fullWidth
              placeholder="New Password"
              name="password"
              onChange={handleChange}
              error={errors.password && touched.password}
              helperText={touched.password && errors.password}
            />

            <PasswordInput
              fullWidth
              placeholder="Confirm Password"
              name="confirm_password"
              onChange={handleChange}
              error={errors.confirm_password && touched.confirm_password}
              helperText={touched.confirm_password && errors.confirm_password}
            />

            <div className="action-wrapper">
              <Button variant="contained" type="submit" color="primary">
                Submit
              </Button>
            </div>
          </form>
        </>
      )}
    </AuthPage>
  )
})({
  '& .form-title': {
    fontSize: 20,
    fontWeight: 600,
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
  },

  '& .MuiTextField-root': {
    '& svg': {
      color: '#A0A0A0',
    },

    '& input': {
      padding: '16.5px 14px',
    },
  },

  '& .forgot-password-link': {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  '& .action-wrapper': {
    display: 'flex',
    justifyContent: 'center',

    '& button': {
      textTransform: 'none',
      width: '40%',
      height: 44,
    },
  },

  '& .forget-password-success': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 24,

    '& svg': {
      width: 120,
      height: 120,
    },
  },
})

export default ResetPasswordPage
