import { ArrowDropDownCircle } from '@mui/icons-material'
import { Avatar, Button, IconButton, Menu, MenuItem, styled as MUIStyled } from '@mui/material'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../redux/slices/authSlice'

const StyledMenuItem = MUIStyled(({ className, item }) => {
  const dispatch = useDispatch()

  return (
    <MenuItem className={className} onClick={typeof item.onClick === 'function' ? item.onClick(dispatch) : undefined}>
      <Button href={item.url}>{item.label}</Button>
    </MenuItem>
  )
})({
  '& .MuiButton-root': {
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const AccountMenuItems = [
  {
    label: 'My Profile',
    url: '/my-profile',
  },
  {
    label: 'Change Password',
    url: '/change-password',
  },
  {
    label: 'Logout',
    onClick: (dispatch) => (event) => {
      event.preventDefault()
      dispatch(authActions.logout())
    },
  },
]

const AccountMenu = MUIStyled(({ className }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { user } = useSelector((state) => state.auth, shallowEqual)

  const handleShowMenu = (event) => {
    setAnchorEl(event.target)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={['account-menu', className].join(' ')}>
      <Avatar src={user?.profile_picture} alt="" size="small" />
      <div className="welcome-user">{`Welcome ${user?.full_name}!`}</div>
      <IconButton onClick={handleShowMenu}>
        <ArrowDropDownCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: 2,
          },
        }}
      >
        {AccountMenuItems.map((item, index) => (
          <StyledMenuItem item={item} key={`account-menu-item-${index}`} />
        ))}
      </Menu>
    </div>
  )
})({
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  fontFamily: 'Montserrat',

  '& .welcome-user': {
    fontWeight: 500,
  },
})

export default AccountMenu
