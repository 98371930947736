import { Button, InputLabel, styled as MUIStyled } from '@mui/material'
import { QuillEditor } from 'components/QuillEditor'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FilePicker from '../../components/FilePicker'
import Loading from '../../components/Loading'
import ProtectedPage from '../../components/ProtectedPage'
import { MESSAGE_TYPES } from '../../constants/common'
import { saveStaticPage, uploadFile } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

const PAGE_PATH = '/privacy-policy'

const EditPrivacyPolicyPage = MUIStyled(({ className }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const { staticPages } = useSelector((state) => state.layout, shallowEqual)
  const pageData = staticPages[PAGE_PATH]
  console.log('dasdasd', pageData)

  const { handleSubmit, setFieldValue, setValues, isSubmitting, values } = useFormik({
    initialValues: {
      description: '',
      file: {},
      title: 'Privacy Policy',
      path: PAGE_PATH,
    },
    onSubmit: async (formValue, actions) => {
      actions.setSubmitting(true)
      try {
        const { description, file, title, path } = formValue
        let fileContent = { ...file }
        if (fileContent?.url?.indexOf(';base64') > -1) {
          const uploadFileResponse = await uploadFile('pdf', fileContent.url, accessToken)
          if (isSuccessRequest(uploadFileResponse.status)) {
            fileContent.url = uploadFileResponse.data.pdf_url
          }
        }

        const response = await saveStaticPage(
          pageData?.id,
          {
            path,
            title,
            content: JSON.stringify({ description, file: fileContent }),
          },
          accessToken
        )

        if (isSuccessRequest(response.status)) {
          navigate('/static-contents', { replace: true })
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'The page content updated success.' }))
        }
      } catch {
        dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'The page content update failed.' }))
      }
      actions.setSubmitting(false)
    },
  })

  useEffect(() => {
    let savedData = pageData || {}
    if (savedData.id) {
      const { content, ...other } = savedData

      try {
        const parsedContent = JSON.parse(content)
        savedData = { ...other, ...parsedContent }
      } catch {
        savedData = { ...other }
      }
      setValues({ ...savedData })
    }
  }, [pageData, setValues])

  const handleFileChange = (selectedFiles) => {
    setFieldValue('file', (selectedFiles?.length > 0 && selectedFiles[0]) || {})
  }

  const handleDescriptionChange = (newContent) => {
    setFieldValue('description', newContent)
  }

  return (
    <ProtectedPage
      title="Edit Privacy Policy"
      className={['edit-privacy-policy-page', className].join(' ')}
      breadcrumbs={[
        { label: 'Static Contents', path: '/static-contents' },
        { label: 'Edit Privacy Policy', path: '/static-contents/privacy-policy/edit' },
      ]}
    >
      <form onSubmit={handleSubmit}>
        {isSubmitting && <Loading />}

        <div className="form-title">
          <div className="page-title-field">
            <div className="field-label">Title - </div>
            <div className="label-value">Privacy Policy</div>
          </div>
          <div className="actions-wrapper">
            <Button href="/static-contents" variant="outlined">
              Back
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </div>
        </div>

        <div className="form-input-wrapper">
          <InputLabel>Description -</InputLabel>
          <QuillEditor value={values.description} onChange={handleDescriptionChange} />
        </div>
        <div className="file-picker-wrapper">
          <FilePicker
            accept="application/pdf"
            description="(you can upload in pdf format.)"
            uploadButtonLabel="Upload file"
            onChange={handleFileChange}
            value={[values.file]}
          />
        </div>
      </form>
    </ProtectedPage>
  )
})({
  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 16,
  },

  '& .form-title': {
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiButton-root': {
      textTransform: 'none',
      minWidth: 100,
    },
  },

  '& .page-title-field': {
    display: 'flex',
    columnGap: 4,
    fontSize: 20,
    lineHeight: 1.25,
    fontWeight: 500,
  },

  '& .form-input-wrapper': {
    flex: 1,
    display: 'flex',

    '& label': {
      fontSize: 20,
      lineHeight: 1.25,
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
      minWidth: 150,
    },

    '& .MuiOutlinedInput-root': {
      height: '100%',
      alignItems: 'flex-start',

      '& textarea': {
        height: '100% !important',
      },
    },

    '& .quill-editor': {
      flex: 1,

      '& .quill': {
        height: 'calc(100% - 45px)',
      },
    },
  },

  '& .file-picker-wrapper': {
    paddingLeft: 148,

    '& .file-preview': {
      '& img': {
        width: 60,
      },
    },

    '& .desc': {
      fontSize: 12,
      fontStyle: 'italic',
    },
  },
})

export default EditPrivacyPolicyPage
