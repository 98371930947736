import { Button, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import RoutePoint from './RoutePoint'

const AddRouteFormMemo = ({
  className,
  onSubmit,
  onCancel,
  onAddressChange,
  data,
  onRoutePage = false,
  enabledCancelButton = false,
  title,
  saveButtonLabel,
  cancelButtonLabel,
  enabledReturnHomeButton = false,
}) => {
  const { t } = useTranslation()

  let validationShape = {
    origin: Yup.object().shape({
      country: Yup.string().required(t('form_validation.required_field')),
      city: Yup.string().required(t('form_validation.required_field')),
      locode: Yup.string()
        .alphaNumeric(t('form_validation.lo_code_invalid'))
        .max(5, t('form_validation.max_length', { len: 5 })),
    }),
    destination: Yup.object().shape({
      country: Yup.string().required(t('form_validation.required_field')),
      city: Yup.string().required(t('form_validation.required_field')),
      locode: Yup.string()
        .alphaNumeric(t('form_validation.lo_code_invalid'))
        .max(5, t('form_validation.max_length', { len: 5 })),
    }),
  }

  if (onRoutePage) {
    validationShape.transport_modes = Yup.array()
      .of(
        Yup.object().shape({
          enabled: Yup.bool(),
          periods: Yup.array().when(['enabled'], (enabled, schema) => {
            return enabled ? schema.min(1, t('form_validation.min_transport_period_selected', { min: 1 })) : schema
          }),
        })
      )
      .min(1, t('form_validation.min_select_transport_mode', { min: 1 }))
  }
  const validationSchema = Yup.object().shape(validationShape)
  const { setValues, setFieldValue, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      origin: {
        country: '',
        country_name: '',
        city: '',
        city_name: '',
        state: '',
        state_name: '',
        locode: '',
        latlng: {
          lat: '',
          lng: '',
        },
      },
      destination: {
        country: '',
        country_name: '',
        city: '',
        city_name: '',
        state: '',
        state_name: '',
        locode: '',
        latlng: {
          lat: '',
          lng: '',
        },
      },
      transport_modes: [],
    },
    validationSchema,
    onSubmit: async (submittedValues, { resetForm }) => {
      const submitSuccess = await onSubmit(submittedValues)
      if (submitSuccess) {
        resetForm()
      }
    },
  })

  useEffect(() => {
    if (data) {
      setValues({ ...data })
    }
  }, [data, setValues])

  const handlePointChange = (pointName) => (_event, newValue) => {
    setFieldValue(pointName, newValue)
    if (typeof onAddressChange === 'function') {
      onAddressChange({ ...values, [pointName]: newValue })
    }
  }

  let actionsWrapperClasses = ['actions-wrapper']
  if (enabledCancelButton) {
    actionsWrapperClasses.push('align-left')
  } else if (enabledReturnHomeButton) {
    actionsWrapperClasses.push('space-between')
  }

  return (
    <form onSubmit={handleSubmit} className={['add-route-form', className].join(' ')}>
      <div className="form-title">{title || t('add_route_form.title')}</div>
      <div className="form-content">
        <div className="route-points-wrapper">
          <div className="points-wrapper">
            <div className="field-wrapper">
              <div className="field-label">{t('add_route_form.orgin_field_label')}</div>
              <RoutePoint
                touched={touched.origin}
                errors={errors.origin}
                value={{ ...values.origin, city_id: values.origin.city }}
                onChange={handlePointChange('origin')}
                useLocationId={onRoutePage}
                updateLocodeByCitySelect
              />
            </div>
            <div className="field-wrapper">
              <div className="field-label">{t('add_route_form.destination_field_label')}</div>
              <RoutePoint
                touched={touched.destination}
                errors={errors.destination}
                value={{ ...values.destination, city_id: values.destination.city }}
                onChange={handlePointChange('destination')}
                useLocationId={onRoutePage}
                updateLocodeByCitySelect
              />
            </div>
          </div>
        </div>

        <div className={actionsWrapperClasses.join(' ')}>
          {enabledCancelButton && (
            <Button variant="outlined" onClick={onCancel}>
              {cancelButtonLabel || t('add_route_form.cancel_button_label')}
            </Button>
          )}
          <Button variant="contained" type="submit">
            {saveButtonLabel || t('add_route_form.save_button_label')}
          </Button>
        </div>
      </div>
    </form>
  )
}

AddRouteFormMemo.displayName = 'AddRouteForm'

const AddRouteForm = MUIStyled(React.memo(AddRouteFormMemo))({
  '& .form-title': {
    fontSize: 44,
    lineHeight: '54px',
    fontWeight: 600,
  },

  '& .form-content': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
    marginTop: 24,
  },

  '& .field-wrapper': {
    display: 'flex',
    flex: 1,
  },
  '& .field-label': {
    width: 130,
    minWidth: 130,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.4,
  },

  '& .route-points-wrapper': {
    display: 'flex',
    columnGap: 40,
  },

  '& .form-description': {
    marginBottom: 16,
  },

  '& .points-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
    flex: 1,
  },

  '& .actions-wrapper': {
    display: 'flex',
    columnGap: 40,
    justifyContent: 'center',

    '& button': {
      width: 220,

      '&.return-home-btn': {
        display: 'flex',
        alignItems: 'center',
        columnGap: 8,
      },
    },

    '&.align-left': {
      justifyContent: 'flex-start',
    },
    '&.space-between': {
      justifyContent: 'space-between',
    },
  },

  '& .MuiButton-root': {
    textTransform: 'none',
  },
})

export default AddRouteForm
