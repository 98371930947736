import { styled as MUIStyled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Loading from './Loading'
import StyledPagination from './StyledPagination'

const StyledTable = MUIStyled(
  ({ className, columns, renderColumn, data = [], loading = false, pagination, onPageChange, emptyMessage }) => {
    const { t } = useTranslation()
    return (
      <div
        className={[
          'table-wrapper',
          !loading && data?.length === 0 && emptyMessage ? 'empty-table' : '',
          loading ? 'loading' : '',
          className,
        ].join(' ')}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(({ label, key }, index) => (
                <TableCell
                  key={`table-head-col-${className}-${key}-${index}`}
                  className={`table-col-${key.toLowerCase().replaceAll('.', '-').replaceAll('_', '-')}`}
                >
                  {t(label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data?.length > 0 && (
            <TableBody>
              {data?.map((row, rIndex) => (
                <TableRow key={`table-col-${className}-${rIndex}`}>
                  {columns.map((col, cIndex) => (
                    <TableCell
                      className={`table-col-${col.key.toLowerCase().replaceAll('.', '-').replaceAll('_', '-')}`}
                      key={`table-col-${className}-${rIndex}-${cIndex}`}
                    >
                      {renderColumn(col.key, get(row, col.key), row, rIndex)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {!loading && data?.length === 0 && emptyMessage && <div className="empty-message">{emptyMessage}</div>}
        {pagination?.count > 0 && <StyledPagination {...pagination} onChange={onPageChange} />}
        {loading && (
          <div className="loading-wrapper">
            <Loading />
          </div>
        )}
      </div>
    )
  }
)({
  '&.table-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
    flex: 1,
    justifyContent: 'space-between',
    position: 'relative',

    '& th': {
      '&.column-send_to, &.column-updatedat, &.column-actions': {
        textAlign: 'center',
      },
    },

    '&.empty-table': {
      justifyContent: 'flex-start',
    },
  },

  '& .empty-message': {
    padding: 24,
    textAlign: 'center',
  },
  '& .loading-wrapper': {
    height: 200,
    position: 'absolute',
    top: 0,
    left: '0',
    width: '100%',

    '& .loading': {
      backgroundColor: 'transparent',
    },
  },
})

StyledTable.propTypes = {
  renderColumn: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    count: PropTypes.number,
  }),
  onPageChange: PropTypes.func,
  emptyMessage: PropTypes.string,
}

export default StyledTable
