import { styled as MUIStyled } from '@mui/material'
import ProfilePicture from 'components/ProfilePicture'
import StyledTable from 'components/StyledTable'
import { useTranslation } from 'react-i18next'

const ContactInfoDetails = MUIStyled(({ className, userData }) => {
  const { t } = useTranslation()

  return (
    <div className={['session', 'contact-info-session', className].join(' ')}>
      <div className="session-title">{t('user_detail_page.contact_info_session_title')}</div>
      <div className="session-content">
        <div className="column left-col">
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.contact_name_field_label')}:</div>
            <div className="field-value">{userData.company_info?.contact_name}</div>
          </div>
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.email_field_label')}:</div>
            <div className="field-value">{userData.email}</div>
          </div>
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.phone_field_label')}:</div>
            <div className="field-value">{userData.company_info?.phones?.join(', ')}</div>
          </div>
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.address_field_label')}:</div>
            <div className="field-value">
              {[
                userData.company_info?.address,
                userData.company_info?.city,
                userData.company_info?.state,
                userData.company_info?.country,
              ]
                .filter((item) => Boolean(item))
                .join(', ')}
            </div>
          </div>
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.languages_served_field_label')}:</div>
            <div className="field-value">
              {userData.company_info?.language_served?.map((lang) => t(`served_language.${lang}`)).join(', ')}
            </div>
          </div>
        </div>
        <div className="column right-col">
          <ProfilePicture src={userData.profile_picture} />
        </div>
      </div>
    </div>
  )
})({
  '& .session-content': {
    display: 'flex',
    flexDirection: 'row !important',

    '& .column': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,

      '& .field-label': {
        width: '200px !important',
      },

      '&.right-col': {
        alignItems: 'center',

        '& .profile-picture .MuiAvatar-root': {
          width: 150,
          height: 150,
        },
      },
    },
  },
})

const ROUTES_TABLE_COLUMNS = [
  {
    label: 'user_detail_page.route_origin_column_label',
    key: 'origin',
  },
  {
    label: 'user_detail_page.route_destination_column_label',
    key: 'destination',
  },
]

const InterestedArea = MUIStyled(({ className, userData }) => {
  const { t } = useTranslation()

  const renderRoutesTableColumn = (_name, { city_name, state_name, country_name, locode }) => {
    return (
      <div className="point-wrapper">
        <div className="address">
          {[city_name, state_name, country_name].filter((item) => Boolean(item)).join(', ')}
        </div>
        <div className="locode">{locode}</div>
      </div>
    )
  }

  return (
    <div className={['session', 'interested-area-session', className].join(' ')}>
      <div className="session-title">{t('user_detail_page.buyer_shipper_interested_session_title')}</div>
      <div className="session-content">
        <div className="column left-col">
          <div className="field-item transport-modes">
            <div className="field-label">{t('user_detail_page.transport_modes_field_label')}:</div>
            <div className="field-value">
              {userData.company_info?.transport_modes?.map((mode) => (
                <div>{t(`transport_mode.${mode}`)}</div>
              ))}
            </div>
          </div>
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.cargo_types_field_label')}:</div>
            <div className="field-value">
              {userData.company_info?.cargo_types.map((type) => t(`cargo_type.${type}`)).join(', ')}
            </div>
          </div>
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.handling_types_field_label')}:</div>
            <div className="field-value">
              {userData.company_info?.handing_types.map((type) => t(`handling_type.${type}`)).join(', ')}
            </div>
          </div>

          <div className="field-item">
            <div className="field-label">{t('user_detail_page.harmonized_tax_code_field_label')}:</div>
            <div className="field-value">
              {userData.company_info?.language_served?.map((lang) => t(`served_language.${lang}`)).join(', ')}
            </div>
          </div>
        </div>
        <div className="column right-col">
          <div className="field-item">
            <div className="field-label">{t('user_detail_page.routes_field_label')}</div>
            <div className="field-value">
              <StyledTable
                columns={ROUTES_TABLE_COLUMNS}
                data={userData.routes || []}
                renderColumn={renderRoutesTableColumn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})({
  '&.interested-area-session': {
    '& .session-content': {
      display: 'flex',
      flexDirection: 'row !important',

      '& .column': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,

        '& .field-label': {
          width: '200px !important',
        },

        '&.right-col': {
          '& .field-item': {
            flexDirection: 'column',
          },

          '& .point-wrapper': {
            '& .address': {
              fontWeight: 500,
            },
            '& .locode': {
              fontSize: 12,
            },
          },
        },
      },
    },

    '& .transport-modes': {
      '& .field-value': {
        display: 'flex',
        columnGap: 16,
      },
    },
  },
})

export const BuyerShipperDetail = MUIStyled(({ className, userData }) => {
  return (
    <div className={['buyer-shipper-detail', className].join(' ')}>
      <ContactInfoDetails userData={userData} />
      <InterestedArea userData={userData} />
    </div>
  )
})({})
