import { Box, styled as MUIStyled } from '@mui/material'
import { Logo } from '../components/Icons'
import PublicPage from '../components/PublicPage'

const AuthPage = MUIStyled(({ className, children }) => {
  return (
    <PublicPage className={['auth-page', className].join(' ')}>
      <Box>
        <div className="logo-wrapper">
          <Logo />
        </div>
        {children}
      </Box>
    </PublicPage>
  )
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: 32,

  '& .MuiBox-root': {
    width: 582,
    backgroundColor: '#fff',
    borderRadius: 50,
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
    position: 'relative',
    overflow: 'hidden',
  },

  '& .logo-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default AuthPage
