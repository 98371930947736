import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled as MUIStyled } from '@mui/material'
import Loading from 'components/Loading'
import { useFormik } from 'formik'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import ProtectedPage from '../../components/ProtectedPage'
import StyledInput from '../../components/StyledInput'
import TextareaAutosize from '../../components/TextareaAutoSize'
import { MESSAGE_TYPES } from '../../constants/common'
import { sendNotification } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

export const RECEIVER_TYPES = {
  ALL_USERS: 'ALL_USERS',
  ALL_BROKERS: 'ALL_BROKERS',
  ALL_BUYERS_SHIPPERS: 'ALL_BUYERS_SHIPPERS',
  SPECIFIC_USERS: 'SPECIFIC_USERS',
}

export const RECEIVER_TYPES_LABEL = {
  ALL_USERS: 'All registered users',
  ALL_BROKERS: 'All brokers',
  ALL_BUYERS_SHIPPERS: 'All Buyers/Shippers',
  SPECIFIC_USERS: 'Specific individuals',
}

export const generateNotificationReceiversInfo = (receiversData) => {
  const { all_users, all_brokers, all_buyers, specific } = receiversData || {}
  let receivers = (specific || []).join(', ')
  if (all_users) {
    receivers = RECEIVER_TYPES_LABEL.ALL_USERS
  } else if (all_brokers) {
    receivers = RECEIVER_TYPES_LABEL.ALL_BROKERS
  } else if (all_buyers) {
    receivers = RECEIVER_TYPES_LABEL.ALL_BUYERS_SHIPPERS
  }

  return receivers
}

const SendNotificationPage = MUIStyled(({ className }) => {
  const [specificUsers, setSpecificUsers] = useState('')
  const dispatch = useDispatch()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is required field.'),
    description: Yup.string().required('This is required field.'),
    specific: Yup.array().when('sent_to', {
      is: RECEIVER_TYPES.SPECIFIC_USERS,
      then: Yup.array().of(Yup.string().email('Has email invalid format.')).min(1, 'You have fill the receiver email'),
    }),
  })

  const { handleChange, handleSubmit, setFieldValue, errors, touched, values, isSubmitting } = useFormik({
    initialValues: {
      title: '',
      description: '',
      sent_to: RECEIVER_TYPES.ALL_USERS,
      specific: [],
    },
    validationSchema,
    onSubmit: async ({ title, description, sent_to, specific }, actions) => {
      let receiverData = {
        all_users: sent_to === RECEIVER_TYPES.ALL_USERS,
        all_brokers: sent_to === RECEIVER_TYPES.ALL_BROKERS,
        all_buyers: sent_to === RECEIVER_TYPES.ALL_BUYERS_SHIPPERS,
        specific: sent_to === RECEIVER_TYPES.SPECIFIC_USERS ? specific : [],
      }

      try {
        const response = await sendNotification({ title, description, send_to: receiverData }, accessToken)
        if (isSuccessRequest(response.status)) {
          actions.resetForm()
          setSpecificUsers('')
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'The notification sent success.' }))
        }
      } catch {
        dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'The notification send failed.' }))
      }
    },
  })

  const handleChangeSpecificUsers = (event) => {
    setFieldValue(
      'specific',
      event.target.value
        .split(',')
        .map((user) => user.trim())
        .filter((user) => user !== '')
    )
    setSpecificUsers(event.target.value)
  }

  return (
    <ProtectedPage
      title="Send Notification"
      className={['send-notification-page', className].join(' ')}
      breadcrumbs={[{ label: 'Notification Management', path: '/notifications' }, { label: 'Send Notification' }]}
    >
      <form onSubmit={handleSubmit}>
        {isSubmitting && <Loading />}
        <Button variant="contained" color="primary" href="/notifications" className="back-button">
          Back
        </Button>
        <StyledInput
          label="Title - "
          name="title"
          onChange={handleChange}
          value={values.title}
          error={errors.title && touched.title}
          helperText={touched.title && errors.title}
        />
        <FormControl className="field-wrapper">
          <FormLabel htmlFor="description-field">{'Description - '}</FormLabel>
          <TextareaAutosize
            minRows={5}
            id="description-field"
            name="description"
            onChange={handleChange}
            error={errors.description && touched.description}
            helperText={touched.description && errors.description}
            value={values.description}
          />
        </FormControl>
        <FormControl className="field-wrapper receiver-field">
          <FormLabel id="sent-to">{'Sent to - '}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={values.sent_to}
            name="sent_to"
            onChange={handleChange}
          >
            {Object.keys(RECEIVER_TYPES).map((type) => (
              <FormControlLabel
                value={type}
                control={<Radio />}
                label={RECEIVER_TYPES_LABEL[type]}
                key={`receive-type-${type}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <StyledInput
          name="specify"
          value={specificUsers}
          onChange={handleChangeSpecificUsers}
          disabled={values.sent_to !== RECEIVER_TYPES.SPECIFIC_USERS}
          className="specific-users-field"
          helperText={(touched.specific && errors.specific) || 'Enter the email of receivers, separates by comma.'}
          error={errors.specific && touched.specific}
        />
        <div className="actions-wrapper">
          <Button variant="contained" color="primary" type="submit">
            Send
          </Button>
        </div>
      </form>
    </ProtectedPage>
  )
})({
  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    position: 'relative',

    '& .loading': {
      backgroundColor: 'transparent',
    },

    '& .MuiButton-root': {
      textTransform: 'none',
      fontWeight: 500,
      minWidth: 200,
      height: 48,
      fontSize: 16,

      '&.back-button': {
        position: 'absolute',
        top: -80,
        right: 0,
        minWidth: 120,
        fontSize: 14,
      },
    },

    '& label': {
      minWidth: 120,
      color: 'rgba(0, 0, 0, 0.6) !important',
      fontWeight: 500,
    },

    '& .field-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      columnGap: 16,

      '& .textarea-autosize-field': {
        flex: 1,
      },

      '&.receiver-field > label': {
        paddingTop: 8,
      },
    },

    '& .specific-users-field': {
      marginLeft: 140,
    },

    '& .actions-wrapper': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
})

export default SendNotificationPage
