import { Close } from '@mui/icons-material'
import { Button, IconButton, MenuItem, styled as MUIStyled, TextField } from '@mui/material'
import StyledInput from 'components/StyledInput'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { getLocodeByName, getLocodeCities, getLocodeStatesByCountry } from 'services/api'
import { isSuccessRequest } from 'utils/common'
import CitySelect from './CitySelect'

let fetchingCityTimer = -1
let searchingCityString = ''

const RoutePoint = MUIStyled(
  ({
    className,
    value = {},
    onChange,
    errors,
    touched,
    resetButtonLabel,
    useLocationId = false,
    enabledResetButton = false,
    useNativeSelect = false,
    required = {},
    updateLocodeByCitySelect = false,
  }) => {
    const { t } = useTranslation()
    const [renderValue, setRenderValue] = useState({ ...value, city: value.city_id })
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const { locodeCountries: countries } = useSelector((state) => state.layout, shallowEqual)
    const { accessToken } = useSelector((state) => state.auth, shallowEqual)
    const [citySearchingValue, setCitySearchingValue] = useState(value.city_name || '')

    useEffect(() => {
      setRenderValue({ ...value, city: value.city_id || value.city })

      if (value?.city_name === '') {
        setCitySearchingValue('')
      }
    }, [value])

    const countryMap = useMemo(() => {
      let map = {}
      countries.forEach((country) => {
        const mapKey = useLocationId ? country.id : country.name
        map[mapKey] = country
      })

      return map
    }, [countries, useLocationId])

    const stateMap = useMemo(() => {
      let map = {}
      states.forEach((state) => {
        const mapKey = useLocationId ? state.id : state.name
        map[mapKey] = { ...state, label: state.name, value: mapKey }
      })

      return map
    }, [states, useLocationId])

    const cityMap = useMemo(() => {
      let map = {}
      cities.forEach((city) => {
        const mapKey = city.id
        map[mapKey] = city
      })

      return map
    }, [cities])

    useEffect(() => {
      const fetchStatesByCountry = async (selectedCountryId) => {
        try {
          const response = await getLocodeStatesByCountry(selectedCountryId)
          if (isSuccessRequest(response.status)) {
            setStates(response.data.data)
          }
        } catch {
          setStates([])
        }
      }
      const selectedCountryId = useLocationId ? renderValue.country : countryMap[renderValue.country]?.id

      if (selectedCountryId) {
        fetchStatesByCountry(selectedCountryId)
      }
    }, [renderValue.country, countryMap, useLocationId])

    useEffect(() => {
      const fetchCities = async (countryId) => {
        try {
          const queryString =
            citySearchingValue?.trim() || '' ? `name=${encodeURIComponent(citySearchingValue?.trim() || '')}` : ''
          const selectedStateId = useLocationId ? renderValue.state : stateMap[renderValue.state]?.id
          const response = await getLocodeCities(countryId, selectedStateId, queryString)
          if (isSuccessRequest(response?.status)) {
            const cityList = [...response.data.data]
              .map((city) => ({
                ...city,
                label: !selectedStateId ? `${city.name} (${city.state_name})` : city.name,
                value: useLocationId ? city.id : city.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
            setCities(cityList)
            setCities(cityList)
          }
        } catch (error) {
          setCities([])
        }
      }

      const selectedCountryId = useLocationId ? renderValue.country : countryMap[renderValue.country]?.id
      if (renderValue.country && selectedCountryId) {
        if (fetchingCityTimer > 0 && citySearchingValue !== searchingCityString) {
          clearTimeout(fetchingCityTimer)
        }

        fetchingCityTimer = setTimeout(() => {
          searchingCityString = citySearchingValue
          fetchCities(selectedCountryId)
          fetchingCityTimer = -1
        }, 500)
      }
    }, [renderValue.country, countryMap, renderValue.state, stateMap, citySearchingValue, useLocationId])

    const fetchLocodeInfo = async (searchLocode) => {
      try {
        const response = await getLocodeByName(searchLocode.toUpperCase(), accessToken)
        if (isSuccessRequest(response?.status) && response?.data?.data?.length) {
          const locodeData = response?.data?.data[0]
          const newValue = {
            country: useLocationId ? locodeData.country_id : locodeData.country_name,
            country_name: locodeData.country_name,
            state: useLocationId ? locodeData.state_id : locodeData.state_name,
            state_name: locodeData.state_name,
            city: useLocationId ? locodeData.id : locodeData.name,
            city_name: locodeData.name,
            latlng: { lat: locodeData.latitude, lng: locodeData.longitude },
            locode: locodeData.locode,
          }

          setCities([{ ...locodeData, label: locodeData.name, value: useLocationId ? locodeData.id : locodeData.name }])
          setRenderValue({ ...renderValue, ...newValue })

          if (typeof onChange === 'function') {
            onChange({}, { ...renderValue, ...newValue })
          }
        }
      } catch (error) {}
    }

    const handleCitySearchChange = (value) => {
      setCitySearchingValue(value)
    }

    const handleInputChange = (fieldName) => (event, changedValue) => {
      let newValue
      const fieldValue = event.target.value

      switch (fieldName) {
        case 'country':
          newValue = {
            country: fieldValue,
            country_name: countryMap[fieldValue].name,
            city: '',
            city_name: '',
            state: '',
            state_name: '',
            latlng: { lat: '', lng: '' },
          }
          setCitySearchingValue('')
          break

        case 'state':
          newValue = {
            city: '',
            city_name: '',
            state: fieldValue,
            state_name: stateMap[fieldValue].name,
            latlng: { lat: '', lng: '' },
          }
          setCitySearchingValue('')
          break

        case 'city':
          newValue = {
            city: changedValue?.id,
            city_name: changedValue?.name,
            latlng: { lat: changedValue?.latitude, lng: changedValue?.longitude },
          }

          if (!renderValue.state) {
            newValue = {
              ...newValue,
              state: (useLocationId ? changedValue?.state_id : changedValue?.state_name) || '',
              state_name: changedValue?.state_name || '',
            }
          }

          if (changedValue?.name !== undefined) {
            setCitySearchingValue(changedValue?.name)
          } else {
            setCitySearchingValue('')
          }

          if (updateLocodeByCitySelect) {
            newValue.locode = changedValue ? `${changedValue?.country_code}${changedValue?.code}` : ''
          }
          break

        case 'locode':
          setCitySearchingValue('')
          newValue = { locode: fieldValue.trim().toUpperCase() }
          if (fieldValue.length === 5) {
            fetchLocodeInfo(fieldValue.trim().toUpperCase())
          }
          break

        default:
          newValue = { [fieldName]: fieldValue }
      }
      setRenderValue({ ...renderValue, ...newValue })

      if (typeof onChange === 'function') {
        onChange(event, { ...renderValue, ...newValue })
      }
    }

    const handleResetFieldsClick = (event) => {
      const newValue = {
        country: '',
        country_name: '',
        city: '',
        city_name: '',
        state: '',
        state_name: '',
        locode: '',
        latlng: { lat: '', lng: '' },
      }
      setRenderValue(newValue)

      if (typeof onChange === 'function') {
        onChange(event, { ...renderValue, ...newValue })
      }
    }

    const handleOptionListClose = (event, evName) => {
      if (evName === 'blur' && event) {
        setCitySearchingValue(renderValue.city_name)
      }
    }

    const handleClearState = () => {
      setRenderValue({
        ...renderValue,
        state: '',
        state_name: '',
        city: '',
        city_name: '',
        locode: '',
        latlng: { lat: '', lng: '' },
      })
      setCitySearchingValue('')
    }

    const stateOptions = Object.values(stateMap)
      .filter((state) => state.label && state?.label?.trim() !== '')
      .sort((a, b) => a.label?.localeCompare(b?.label))

    let cityOptions = useMemo(() => {
      return cities?.filter((city) => {
        return city.label?.trim() !== ''
      })
    }, [cities])

    let countryOptions = [...countries]
    if (useLocationId) {
      countryOptions = countries.map((country) => ({ ...country, value: country.id }))
    }

    let countryLabel = t('add_route_form.country_field_label')
    if (required?.country) {
      countryLabel = `${countryLabel}*`
    }

    let cityLabel = t('add_route_form.city_field_label')
    if (required?.city) {
      cityLabel = `${cityLabel}*`
    }

    return (
      <div className={['input-wrapper', className].join(' ')}>
        <div className="row">
          <TextField
            select
            name="country"
            placeholder={useNativeSelect ? countryLabel : undefined}
            label={!useNativeSelect ? countryLabel : undefined}
            onChange={handleInputChange('country')}
            value={renderValue.country || ''}
            error={errors?.country && touched?.country}
            helperText={touched?.country && errors?.country}
            SelectProps={{
              native: useNativeSelect,
            }}
            className={renderValue.country ? '' : 'unselected'}
          >
            {countryOptions.map((country, index) =>
              useNativeSelect ? (
                <option key={`country-opt-${country.value}`} value={country.value} disabled={index === 0}>
                  {country.label}
                </option>
              ) : (
                <MenuItem key={`country-opt-${country.value}`} value={country.value} className="route-point-option">
                  {country.label}
                </MenuItem>
              )
            )}
          </TextField>
          <CitySelect
            options={cityOptions}
            onSearchChange={handleCitySearchChange}
            onChange={handleInputChange('city')}
            value={cityMap[renderValue.city] || null}
            label={cityLabel}
            noOptionsText={t('add_route_form.no_city_options')}
            searchingValue={citySearchingValue}
            onClose={handleOptionListClose}
            error={touched?.city && errors?.city}
          />
        </div>
        <div className="row">
          <TextField
            select
            placeholder={useNativeSelect ? t('add_route_form.state_field_label') : undefined}
            label={!useNativeSelect ? t('add_route_form.state_field_label') : undefined}
            onChange={handleInputChange('state')}
            name="state"
            value={renderValue.state && stateMap[renderValue.state] ? renderValue.state : ''}
            error={errors?.state && touched?.state}
            helperText={touched?.state && errors?.state}
            SelectProps={{
              native: useNativeSelect,
            }}
            disabled={stateOptions.length === (useNativeSelect ? 1 : 0)}
            className={['state-select', renderValue.state ? '' : 'unselected'].join(' ')}
            InputProps={{
              endAdornment: renderValue.state ? (
                <IconButton onClick={handleClearState} className="clear-button">
                  <Close />
                </IconButton>
              ) : null,
            }}
          >
            {stateOptions.map((state, sIndex) =>
              useNativeSelect ? (
                <option key={`state-opt-${state.value}-${sIndex}`} value={state.value} disabled={sIndex === 0}>
                  {state.label}
                </option>
              ) : (
                <MenuItem key={`state-opt-${state.value}-${sIndex}`} value={state.value} className="route-point-option">
                  {state.label}
                </MenuItem>
              )
            )}
          </TextField>
          <StyledInput
            placeholder={t('add_route_form.locode_field_label')}
            onChange={handleInputChange('locode')}
            name="locode"
            value={renderValue.locode || ''}
            error={errors?.locode && touched?.locode}
            helperText={touched?.locode && errors?.locode}
            inputProps={{ maxLength: 5 }}
          />
        </div>
        {enabledResetButton && (
          <div className="row actions-wrapper">
            <Button variant="outlined" onClick={handleResetFieldsClick}>
              {resetButtonLabel || t('add_route_form.reset_fields_button_label')}
            </Button>
          </div>
        )}
      </div>
    )
  }
)(({ theme }) => ({
  '&.input-wrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 16,
    flex: 1,
    flexDirection: 'column',

    '& .row': {
      display: 'flex',
      columnGap: 40,
      flex: 1,

      '& .select-field, .MuiTextField-root, .MuiAutocomplete-root, .locode-input-field, .form-input-wrapper': {
        flex: 1,
      },

      '& label': {
        transform: 'translate(14px, 10px) scale(1)',
        color: '#BEBEBE',
      },

      '& label.MuiInputLabel-shrink, legend': {
        display: 'none',
      },

      '& .MuiSelect-select': {
        paddingTop: 10,
        paddingBottom: 10,
      },

      '& .MuiInputBase-root': {
        height: 44,
      },

      '& .city-select-field input': {
        padding: 0,
      },

      '& .unselected select': {
        color: '#BEBEBE',
      },

      '& .state-select': {
        '& .clear-button': {
          padding: 4,
          marginRight: 16,
          display: 'none',
          height: 28,

          '& svg': {
            fontSize: 20,
          },
        },

        '&:hover .clear-button': {
          display: 'flex',
        },
      },

      '& .MuiPaper-root': {
        maxWidth: '100%',
        padding: 0,

        '& .MuiAutocomplete-listbox': {
          maxHeight: 150,
        },
      },
    },
  },
}))

export default RoutePoint
