import { Dialog, styled as MUIStyled } from '@mui/material'
import AddRouteForm from 'components/AddRouteForm'

export const AddInterestedRouteDialog = MUIStyled(({ className, onSave, data, ...other }) => {
  return (
    <Dialog {...other} className={['add-interested-route-dialog', className].join(' ')}>
      <AddRouteForm onSubmit={onSave} data={data} />
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    margin: 0,
    maxWidth: 'unset',
    width: 900,
  },
})
