import { Home } from '@mui/icons-material'
import { styled as MUIStyled, Breadcrumbs as MuiBreadcrumbs, Typography, Link } from '@mui/material'

const Breadcrumbs = MUIStyled(({ className, data }) => {
  const items = [{ label: <Home />, path: '/dashboard' }, ...(data || [])]
  return (
    <MuiBreadcrumbs separator=">" className={['breadcrumb-item', className].join(' ')}>
      {items.map((item, index) => {
        return index === items.length - 1 || !item.path ? (
          <Typography key={`${index + 1}`} color="text.primary">
            {item.label}
          </Typography>
        ) : (
          <Link underline="hover" key={`${index + 1}`} href={item.path}>
            {item.label}
          </Link>
        )
      })}
    </MuiBreadcrumbs>
  )
})({
  '& li': {
    color: '#fff',
    '& a.MuiLink-root ': {
      color: '#fff',
    },

    '& > p': {
      color: '#fff',
      fontWeight: 600,
    },
  },
})

export default Breadcrumbs
