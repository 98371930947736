import { Button, Link, styled as MUIStyled } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import ProfilePicture from '../../components/ProfilePicture'
import ProtectedPage from '../../components/ProtectedPage'
import { MESSAGE_TYPES } from '../../constants/common'
import { getSubadminProfile, sendLoginLinkToAdminUser } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

const AdminProfilePage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const { adminId } = useParams()
  const [userData, setUserData] = useState(null)
  const { accessToken, adminPermissionList } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()

  const adminPermissionMap = useMemo(() => {
    let map = {}
    adminPermissionList.forEach((perm) => {
      map[perm.id] = perm
    })
    return map
  }, [adminPermissionList])

  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const response = await getSubadminProfile(adminId, accessToken)
        if (isSuccessRequest(response.status)) {
          setUserData(response.data.data)
        }
      } catch {}
    }

    if (accessToken && adminId) {
      fetchAdminProfile()
    }
  }, [adminId, accessToken])

  const handleSendLoginLinkClick = async () => {
    try {
      const response = await sendLoginLinkToAdminUser(userData?.email, accessToken)
      if (isSuccessRequest(response.status)) {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.SUCCESS,
            content: t('subadmins_management_page.sent_login_link_success'),
          })
        )
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t('subadmins_management_page.sent_login_link_failed'),
        })
      )
    }
  }

  const renderPermissions = (userPermissions) => {
    let permissionNames = userPermissions?.map((perm) => adminPermissionMap[perm]?.name).filter((perm) => Boolean(perm))
    if (permissionNames.includes('ALL')) {
      permissionNames = adminPermissionList.map((perm) => perm.name).filter((perm) => perm !== 'ALL')
    }

    return permissionNames.map((perm) => (
      <div key={`perm-${perm}`}>{t(`admin_user_form_page.${perm}_permission_label`)}</div>
    ))
  }

  const title = t('view_subadmin_page.title')

  return (
    <ProtectedPage
      className={['admin-profile-page', className].join(' ')}
      title={
        <>
          <div className="main-title">
            <span>{title}:</span>
            <span className="username">{userData?.full_name}</span>
          </div>
          <Button variant="contained" color="primary" href="/subadmins">
            {t('view_subadmin_page.back_button_label')}
          </Button>
        </>
      }
      breadcrumbs={[
        { label: t('subadmins_management_page.title'), path: '/subadmins' },
        { label: `${title}: ${userData?.full_name}` },
      ]}
    >
      {userData === null && <Loading />}
      {userData && (
        <div className="info-wrapper">
          <div className="main-info">
            <div className="field-item">
              <div className="field-label">{t('admin_user_form_page.full_name_field_label')}:</div>
              <div className="field-value">{userData.full_name}</div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('admin_user_form_page.email_address_field_label')}:</div>
              <div className="field-value">{userData.email}</div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('admin_user_form_page.phone_field_label')}:</div>
              <div className="field-value">{userData.phone}</div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('admin_user_form_page.status_field_label')}:</div>
              <div className="field-value">
                {t(`admin_user_form_page.${userData?.active ? 'active' : 'deactivated'}_status_user`)}
              </div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('admin_user_form_page.added_on_field_label')}:</div>
              <div className="field-value">{dayjs(userData.createdAt).format('MM-DD-YYYY')}</div>
            </div>
            <div className="field-item">
              <div className="field-label">{t('admin_user_form_page.permissions_field_label')}:</div>
              <div className="field-value">{renderPermissions(userData.permissions)}</div>
            </div>
          </div>
          <div className="profile-wrapper">
            <ProfilePicture src={userData.profile_picture} />
            <Link onClick={handleSendLoginLinkClick}>
              {t('subadmins_management_page.send_login_link_button_title')}
            </Link>
          </div>
        </div>
      )}
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',
    rowGap: '60px !important',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
  },

  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',

    '& .main-title': {
      display: 'flex',
      columnGap: 12,

      '& .username': {
        fontWeight: 400,
      },
    },
  },

  '& .info-wrapper': {
    display: 'flex',
    columnGap: 60,
    justifyContent: 'center',

    '& .main-info': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      minWidth: 500,
    },

    '& .profile-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      alignItems: 'center',

      '& a.MuiLink-root': {
        cursor: 'pointer',
      },
    },
  },

  '& .field-item': {
    display: 'flex',
    columnGap: 24,
    fontSize: 18,

    '& .field-label': {
      width: 150,
      fontWeight: 500,
    },
  },
})

export default AdminProfilePage
