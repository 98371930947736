import { AddBoxOutlined, Close } from '@mui/icons-material'
import { IconButton, styled as MUIStyled } from '@mui/material'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import StyledInput from './StyledInput'

export const generateHarmonizedTaxCodeDisplayString = (code) => {
  const arr = [[0, 4], [4, 6], [6]].map((pos) => {
    if (pos[1] === undefined) {
      return code.replace(/\./g, '').substring(pos[0])
    }

    return code.replace(/\./g, '').substring(pos[0], pos[1])
  })

  return arr.filter((sub) => Boolean(sub)).join('.')
}

let changedValue = false

const HarmonizedTaxCodesInput = MUIStyled(({ className, TextFieldProps, value = [], onChange, single = false }) => {
  const { t } = useTranslation()
  const [addingValue, setAddingValue] = useState('')
  const [renderValues, setRenderValues] = useState(value)
  const [error, setError] = useState('')
  const validationSchema = Yup.object().shape(
    {
      code: Yup.string()
        .numberic(t('form_validation.number_field'))
        .max(10, t('form_validation.max_length', { max: 10 }))
        .min(10, t('form_validation.length', { len: 10 })),
    },
    {
      strict: false,
      abortEarly: false,
    }
  )

  useEffect(() => {
    if (!changedValue) {
      setRenderValues(value)
    }
  }, [value])

  const validateCode = async (code) => {
    const errors = []
    const validationResults = await validationSchema.validate({ code }).catch((errors) => errors)
    if (validationResults?.errors?.length > 0) {
      errors.push([...validationResults.errors])
    }

    if (renderValues.includes(code)) {
      errors.push(t('form_validation.harmonized_tax_code_already_added'))
    }

    if (errors.length) {
      setError(errors[0])
    } else {
      setError('')
    }

    return errors
  }

  const updateChange = (newValues) => {
    if (typeof onChange === 'function') {
      onChange(null, newValues)
    }
  }

  const handleInputChange = async (event) => {
    changedValue = true
    const newValue = event.target.value.trim().replace(/\./g, '')
    setAddingValue(newValue)
    if (single) {
      const errors = await validateCode(newValue)
      if (errors.length === 0) {
        updateChange([newValue])
      }
    } else {
      validateCode(newValue)
    }
  }

  const handleKeyDown = (event) => {
    if (!single && event.keyCode === 13) {
      event.preventDefault()
      handleAddNewCode()
    }
  }

  const handleAddNewCode = async () => {
    if (addingValue) {
      const errors = await validateCode(addingValue)
      if (errors.length > 0) {
        return
      }
      const newCodes = [...renderValues, addingValue]
      setRenderValues(newCodes)
      setAddingValue('')
      updateChange(newCodes)
    }
  }

  const handleRemoveCode = (index) => () => {
    changedValue = true
    const newCodes = [...renderValues.slice(0, index), ...renderValues.slice(index + 1)]
    setRenderValues(newCodes)
    updateChange(newCodes)
  }

  return (
    <div className={['harmonized-tax-codes-input', className].join(' ')}>
      {renderValues.length > 0 && (
        <div className="added-wrapper">
          {renderValues.map((code, index) => (
            <div className="added-item" key={`tax-code-${code.toLowerCase()}`}>
              <span>{generateHarmonizedTaxCodeDisplayString(code)}</span>
              <IconButton onClick={handleRemoveCode(index)}>
                <Close />
              </IconButton>
            </div>
          ))}
        </div>
      )}
      <div className="control-wrapper">
        <StyledInput
          {...TextFieldProps}
          onChange={handleInputChange}
          value={generateHarmonizedTaxCodeDisplayString(addingValue)}
          onKeyDown={handleKeyDown}
          error={Boolean(error)}
          helperText={error}
          placeholder={t('harmonized_tax_code_input.input_field_placeholder')}
        />
        {!single && (
          <IconButton onClick={handleAddNewCode}>
            <AddBoxOutlined />
          </IconButton>
        )}
      </div>
    </div>
  )
})({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,

  '& .added-wrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 16,
    rowGap: 8,

    '& .added-item': {
      backgroundColor: '#fff',
      padding: '8px 16px',
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500,
      border: '1px solid #BEBEBE',
      display: 'flex',
      alignItems: 'center',
      columnGap: 16,
      borderRadius: 50,

      '& button': {
        padding: 0,
      },
    },
  },

  '& .control-wrapper': {
    display: 'flex',
    columnGap: 16,
  },
})

export default HarmonizedTaxCodesInput
