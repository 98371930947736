import { Alert, Button, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import Loading from '../components/Loading'
import ProtectedPage from '../components/ProtectedPage'
import StyledInput from '../components/StyledInput'
import { MESSAGE_TYPES } from '../constants/common'
import { authActions } from '../redux/slices/authSlice'
import { changePassword } from '../services/api'
import { createMessage, isSuccessRequest } from '../utils/common'

const ChangePasswordPage = MUIStyled(({ className }) => {
  const [changePasswordError, setChangePasswordError] = useState('')
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required('This field is required.'),
    new_password: Yup.string()
      .min(6, 'The password must have minimum 6 characters.')
      .required('This is required field.'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password')], 'The password does not match.')
      .required('This is required field.'),
  })
  const { handleChange, handleSubmit, isSubmitting, errors, touched, values } = useFormik({
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: async ({ current_password, new_password }, actions) => {
      actions.setSubmitting(true)

      try {
        const response = await changePassword({ current_password, new_password }, accessToken)
        if (isSuccessRequest(response.status)) {
          dispatch(authActions.logout())
          dispatch(
            createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Your password has been changed successful.' })
          )
          actions.resetForm()
        }
      } catch (error) {
        setChangePasswordError('Change password failed. Please check your inputs and try again.')
      }
      actions.setSubmitting(false)
    },
  })

  return (
    <ProtectedPage
      className={['my-pprofile-page', className].join(' ')}
      title="Change Password"
      breadcrumbs={[{ label: 'ChangePassword' }]}
    >
      {isSubmitting && <Loading />}
      {changePasswordError && <Alert severity="error">{changePasswordError}</Alert>}
      <form onSubmit={handleSubmit}>
        <StyledInput
          name="current_password"
          label="Current Password: "
          type="password"
          error={errors.current_password && touched.current_password}
          helperText={touched.current_password && errors.current_password}
          onChange={handleChange}
          value={values.current_password}
        />
        <StyledInput
          name="new_password"
          label="New Password: "
          type="password"
          error={errors.new_password && touched.new_password}
          helperText={touched.new_password && errors.new_password}
          onChange={handleChange}
          value={values.new_password}
        />
        <StyledInput
          name="confirm_password"
          label="Confirm Your Password: "
          type="password"
          error={errors.confirm_password && touched.confirm_password}
          helperText={touched.confirm_password && errors.confirm_password}
          onChange={handleChange}
          value={values.confirm_password}
        />
        <div className="action-wrapper">
          <Button variant="outlined" color="primary" href="/">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Update
          </Button>
        </div>
      </form>
    </ProtectedPage>
  )
})({
  '& .page-body .page-content .content-wrapper': {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 40,
    padding: 80,
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    minWidth: 900,

    '& label': {
      width: 200,
      minWidth: 200,
    },

    '& .action-wrapper': {
      display: 'flex',
      columnGap: 24,
      justifyContent: 'center',
      marginTop: 40,

      '& .MuiButton-root': {
        textTransform: 'none',
        width: 120,
      },
    },
  },
})

export default ChangePasswordPage
