import { Button, styled as MUIStyled } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import FAQField from '../../components/FAQField'
import Loading from '../../components/Loading'
import ProtectedPage from '../../components/ProtectedPage'
import { HTTP_STATUS, MESSAGE_TYPES } from '../../constants/common'
import { getFAQDetail, updateFAQ } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

const EditFAQPage = MUIStyled(({ className }) => {
  const params = useParams()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const [loadingData, setLoadingData] = useState(true)

  const validationSchema = Yup.object().shape({
    question: Yup.object().shape({
      question: Yup.string().required('This is required field.'),
      answer: Yup.string().required('This is required field.'),
      type: Yup.array().of(Yup.string()).min(1, 'You have select 1 type for this question.'),
    }),
  })
  const { handleSubmit, setFieldValue, errors, touched, values, isSubmitting } = useFormik({
    initialValues: {
      question: {},
    },
    validationSchema,
    onSubmit: async (formValues, actions) => {
      actions.setSubmitting(true)

      try {
        const { id, ...other } = formValues.question
        const response = await updateFAQ(id, other, accessToken)
        if (isSuccessRequest(response.status)) {
          navigate('/static-contents/faqs')
          dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'The FAQ updated success.' }))
        }
      } catch {
        dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'The FAQ update failed.' }))
      }
    },
  })

  useEffect(() => {
    const fetchFAQData = async () => {
      try {
        const response = await getFAQDetail(params.faq, accessToken)
        if (isSuccessRequest(response.status)) {
          setFieldValue('question', response.data.data)
        }
      } catch (error) {
        navigate(error.response?.status === HTTP_STATUS.NOT_FOUND ? '/404' : '/500', { replace: true })
      }
      setLoadingData(false)
    }
    if (params?.faq) {
      fetchFAQData()
    }
  }, [params?.faq, accessToken, navigate, setFieldValue])

  const handleQuestionDataChange = (changedField, newValue) => {
    const key = changedField.split('.')[1]
    setFieldValue('question', { ...values.question, [key]: newValue })
  }

  return (
    <ProtectedPage
      title="Edit FAQ"
      className={['edit-faq-page', className].join(' ')}
      breadcrumbs={[
        { label: 'Static Contents', path: '/static-contents' },
        { label: 'FAQs', path: '/static-contents/faqs' },
        { label: 'Edit FAQ' },
      ]}
    >
      {(loadingData || isSubmitting) && <Loading />}
      {!loadingData && (
        <form onSubmit={handleSubmit}>
          <div className="actions-wrapper">
            <Button variant="outlined" href="/static-contents/faqs">
              Back
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </div>
          <FAQField
            value={values.question}
            onChange={handleQuestionDataChange}
            name="question"
            error={errors.question}
            touched={touched.question}
          />
        </form>
      )}
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',
  },

  '& form': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },

  '& .actions-wrapper': {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 24,

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },
  },
})

export default EditFAQPage
