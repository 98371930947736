import { Checkbox, FormControlLabel, FormHelperText, styled as MUIStyled } from '@mui/material'
import { TRANSPORT_MODES } from 'constants/common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

let changedValues = false

const TransportModesInput = MUIStyled(({ className, name, onChange, value, error }) => {
  const defaultMap = {}
  value?.forEach((item) => {
    defaultMap[item] = true
  })
  const { t } = useTranslation()
  const [selectedBoxes, setSelectedBoxes] = useState(defaultMap)

  useEffect(() => {
    if (!changedValues) {
      let newSelectedBoxes = {}
      value?.forEach((item) => {
        newSelectedBoxes[item] = true
      })
      setSelectedBoxes(newSelectedBoxes)
    }
  }, [value])

  const handleBoxChange = (type) => (event) => {
    changedValues = true
    let newData = { ...selectedBoxes }
    newData[type] = event.target.checked
    setSelectedBoxes(newData)

    if (typeof onChange === 'function') {
      const enabledTypes = []
      Object.keys(newData).forEach((type) => {
        if (newData[type]) {
          enabledTypes.push(type)
        }
      })
      onChange(null, enabledTypes)
    }
  }

  return (
    <div className={['transport-modes-input', className].join(' ')}>
      {Object.keys(TRANSPORT_MODES).map((type) => (
        <FormControlLabel
          control={
            <Checkbox
              key={`transport-mode-${type}`}
              name={name}
              onChange={handleBoxChange(type)}
              checked={selectedBoxes[type] === true}
            />
          }
          label={t(`transport_mode.${type}`)}
          key={`transport-mode-${type}`}
        />
      ))}
      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  )
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: 24,
  padding: 0,
  justifyContent: 'space-between',

  '& .MuiFormControlLabel-root': {
    flex: 1,
    margin: 0,

    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '18px',
    },

    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: 18,

      '& svg': {
        fontSize: 24,
      },
    },
  },
  '& .MuiFormHelperText-root': {
    width: '100%',
    color: '#d32f2f',
    fontSize: 14,
  },
}))

export default TransportModesInput
