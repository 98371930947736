import { styled as MUIStyled } from '@mui/material'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '28px',
  '32px',
  '36px',
  '48px',
  '60px',
]
Quill.register(fontSizeStyle, true)
const fontFamilyStyle = Quill.import('formats/font')
fontFamilyStyle.whitelist = ['MontSerrat', 'Roboto']
Quill.register(fontFamilyStyle, true)

const defaultFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
]

const defaultToolbar = [
  [{ font: fontFamilyStyle.whitelist }],
  [{ size: fontSizeStyle.whitelist }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],

  ['clean'], // remove formatting button
]

export const QuillEditor = MUIStyled(({ className, ...props }) => {
  return (
    <div className={['quill-editor', className].join(' ')}>
      <ReactQuill theme="snow" formats={defaultFormats} modules={{ toolbar: defaultToolbar }} {...props} />
    </div>
  )
})({
  '& .ql-snow': {
    '& .ql-picker': {
      '&.ql-size, &.ql-font': {
        '& .ql-picker-label, .ql-picker-item': {
          '&::before': {
            content: 'attr(data-value) !important',
          },
        },
      },
    },

    '& .ql-editor': {
      fontSize: 16,
      fontFamily: 'MontSerrat, sans-serif',
    },
  },
})
