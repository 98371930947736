import { CircularProgress, styled as MUIStyled } from '@mui/material'

const Loading = MUIStyled(({ className }) => {
  return (
    <div className={['loading', className].join(' ')}>
      <CircularProgress />
    </div>
  )
})({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 99999,
  backgroundColor: '#00000040',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export default Loading
