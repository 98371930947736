import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  styled as MUIStyled,
} from '@mui/material'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Loading from '../../components/Loading'
import ProfilePicture from '../../components/ProfilePicture'
import ProtectedPage from '../../components/ProtectedPage'
import StyledInput from '../../components/StyledInput'
import { MESSAGE_TYPES } from '../../constants/common'
import { getSubadminProfile, saveSubadminUser } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

const AdminUserFormPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const { adminId } = useParams()
  const { adminPermissionList, accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()
  const [submitError, setSubmitError] = useState('')
  const [fetchingData, setFetchingData] = useState(false)
  const navigate = useNavigate()

  const adminPermissionsMap = useMemo(() => {
    let map = {}
    adminPermissionList.forEach((perm) => {
      map[perm.name] = perm
    })
    return map
  }, [adminPermissionList])

  const sortedPermissions = useMemo(() => {
    return [...adminPermissionList]
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        }
        return 0
      })
      .filter((permission) => permission.name !== 'ALL')
      .map((perm) => perm.name)
  }, [adminPermissionList])

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .max(50, t('form_validation.max_length', { max: 50 }))
      .required(t('form_validation.required_field')),
    email: Yup.string()
      .email(t('form_validation.email_invalid_format'))
      .max(50, t('form_validation.max_length', { max: 50 }))
      .required(t('form_validation.required_field')),
    phone: Yup.string().phone(t('form_validation.phone_invalid_format')),
    permissions: Yup.array().of(Yup.string()).min(1, t('admin_user_form_page.permissions_unselected_error')),
  })
  const { isSubmitting, handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      permissions: [],
      active: true,
      profile_picture: '',
      createdAt: Date.now(),
    },
    validationSchema,
    onSubmit: async ({ createdAt, ...submittedValues }, actions) => {
      actions.setSubmitting(true)
      try {
        let userPermissions = submittedValues.permissions
          .filter((perm) => adminPermissionsMap[perm])
          .map((perm) => adminPermissionsMap[perm].id)

        if (userPermissions.length === sortedPermissions.length) {
          userPermissions = [adminPermissionsMap['ALL'].id]
        }
        const response = await saveSubadminUser(
          adminId,
          {
            ...submittedValues,
            permissions: userPermissions,
          },
          accessToken
        )
        if (isSuccessRequest(response.status)) {
          dispatch(
            createMessage({
              type: MESSAGE_TYPES.SUCCESS,
              content: t(`admin_user_form_page.${adminId ? 'update' : 'create'}_admin_user_success`, {
                email: submittedValues.email,
              }),
            })
          )
          if (!adminId) {
            actions.resetForm()
          } else {
            navigate('/subadmins')
          }
        }
      } catch (error) {
        setSubmitError(
          error?.response?.data?.detail ||
            t(`admin_user_form_page.${adminId ? 'update' : 'create'}_admin_user_failed`, {
              email: submittedValues.email,
            })
        )
      }
      actions.setSubmitting(false)
    },
  })

  useEffect(() => {
    const fetchAdminProfile = async () => {
      setFetchingData(true)
      try {
        const response = await getSubadminProfile(adminId, accessToken)
        if (isSuccessRequest(response.status)) {
          const { full_name, profile_picture, active, email, phone, permissions, createdAt } = response.data.data
          let userPermissions = permissions
            .filter((perm) => adminPermissionsMap[perm.name])
            .map((perm) => perm.name)
            .sort((a, b) => {
              if (a < b) {
                return -1
              } else if (a > b) {
                return 1
              }
              return 0
            })

          if (userPermissions.includes('ALL')) {
            userPermissions = [...sortedPermissions]
          }
          setValues({
            full_name,
            profile_picture,
            active,
            email,
            phone,
            createdAt,
            permissions: userPermissions,
          })
        }
      } catch {}
      setFetchingData(false)
    }
    if (accessToken && adminId) {
      fetchAdminProfile()
    }
  }, [accessToken, adminId, adminPermissionsMap, setValues, sortedPermissions])

  const handlePermissionChange = (permissionName) => (event) => {
    let newPermissions = [...values.permissions]
    if (event.target.checked) {
      if (permissionName === 'ALL') {
        newPermissions = [...sortedPermissions]
      } else {
        newPermissions.push(permissionName)
      }
    } else {
      if (permissionName === 'ALL') {
        newPermissions = []
      } else {
        const permissionIndex = newPermissions.indexOf(permissionName)
        if (permissionIndex >= 0) {
          newPermissions = [...newPermissions.slice(0, permissionIndex), ...newPermissions.slice(permissionIndex + 1)]
        }
      }
    }
    setFieldValue('permissions', newPermissions)
  }

  const handleProfilePictureChange = (base64Image) => {
    setFieldValue('profile_picture', base64Image)
  }

  const title = t(`admin_user_form_page.${adminId ? 'edit' : 'add_new'}_title`)
  return (
    <ProtectedPage
      className={['admin-user-form-page', className].join(' ')}
      title={
        <>
          <div className="main-title">{title}</div>
          <Button variant="contained" color="primary" href="/subadmins">
            {t('admin_user_form_page.back_button_label')}
          </Button>
        </>
      }
      breadcrumbs={[{ label: t('subadmins_management_page.title'), path: '/subadmins' }, { label: title }]}
    >
      <div className="form-wrapper">
        {submitError && <Alert severity="error">{submitError}</Alert>}
        {(isSubmitting || fetchingData) && <Loading />}
        <form onSubmit={handleSubmit}>
          <div className="left-form">
            <StyledInput
              label={`${t('admin_user_form_page.full_name_field_label')}:`}
              name="full_name"
              onChange={handleChange}
              value={values.full_name}
              error={errors.full_name && touched.full_name}
              helperText={touched.email && errors.email}
            />
            <StyledInput
              label={`${t('admin_user_form_page.email_address_field_label')}:`}
              name="email"
              onChange={handleChange}
              value={values.email}
              error={errors.email && touched.email}
              helperText={touched.email && errors.email}
            />
            <StyledInput
              label={`${t('admin_user_form_page.phone_field_label')}:`}
              name="phone"
              onChange={handleChange}
              value={values.phone}
              error={errors.phone && touched.phone}
              helperText={touched.phone && errors.phone}
            />
            <FormControl>
              <FormLabel>{t('admin_user_form_page.status_field_label')}:</FormLabel>
              <RadioGroup value={values.active} name="active" onChange={handleChange}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t('admin_user_form_page.active_status_user')}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('admin_user_form_page.deactivated_status_user')}
                />
              </RadioGroup>
            </FormControl>
            <FormControl className="created-field">
              <FormLabel>{t('admin_user_form_page.added_on_field_label')}:</FormLabel>
              <div className="created-date">{dayjs(values.createdAt).format('MM-DD-YYYY')}</div>
            </FormControl>
            <FormControl
              className={['permissions-field', errors.permissions && touched.permissions ? 'error' : ''].join(' ')}
            >
              <FormLabel>{t('admin_user_form_page.permissions_field_label')}:</FormLabel>
              <div className="inputs-wrapper">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handlePermissionChange('ALL')}
                        indeterminate={
                          values.permissions.length > 0 && values.permissions.length < sortedPermissions.length
                        }
                        checked={values.permissions.length === sortedPermissions.length}
                      />
                    }
                    label={t(`admin_user_form_page.ALL_permission_label`)}
                  />
                  {sortedPermissions.map((permission) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handlePermissionChange(permission)}
                          checked={values.permissions.includes('ALL') || values.permissions.includes(permission)}
                        />
                      }
                      label={t(`admin_user_form_page.${permission}_permission_label`)}
                      key={`admin-permission-${permission}`}
                      className="sub-permission"
                    />
                  ))}
                </FormGroup>
                {errors.permissions && touched.permissions && (
                  <FormHelperText error>{errors.permissions}</FormHelperText>
                )}
              </div>
            </FormControl>
            <div className="actions-wrapper">
              <Button variant="contained" color="primary" type="submit">
                {t(`admin_user_form_page.${adminId ? 'update' : 'create'}_button_label`)}
              </Button>
            </div>
          </div>
          <div className="right-form">
            <ProfilePicture editing src={values.profile_picture} onChange={handleProfilePictureChange} />
          </div>
        </form>
      </div>
    </ProtectedPage>
  )
})({
  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',
  },

  '& .form-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 40,
  },

  '& .MuiButton-root': {
    textTransform: 'none',
  },

  '& form': {
    display: 'flex',
    maxWidth: 1500,
    width: '100%',
    columnGap: 100,

    '& .left-form': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,

      '& .MuiInputLabel-root, .MuiFormLabel-root': {
        minWidth: 130,
        fontWeight: 500,
      },

      '& .MuiFormControl-root': {
        columnGap: 16,
        flexDirection: 'row',

        '& > label': {
          marginTop: 12,
        },

        '&.permissions-field': {
          '&.error': {
            '& .MuiSvgIcon-root': {
              color: '#d32f2f',
            },
          },
        },

        '&.created-field > label': {
          marginTop: 0,
        },
      },

      '& .MuiButton-root': {
        width: 200,
      },
    },

    '& .sub-permission': {
      marginLeft: 20,
    },
  },
})

export default AdminUserFormPage
