import { v4 as uuid } from 'uuid'

import { HTTP_STATUS } from '../constants/common'
import { layoutActions } from '../redux/slices/layoutSlice'

export const isSuccessRequest = (status) => {
  return [HTTP_STATUS.SUCCESS, HTTP_STATUS.CREATED_SUCCESS].includes(status)
}

export const createMessage = (msg) => {
  return layoutActions.showMessage({ ...msg, id: uuid(), createdAt: Date.now() })
}

export const parseURLQuery = (queryString = '') => {
  let strQuery = queryString?.trim()
  if (strQuery.indexOf('?') === 0) {
    strQuery = strQuery.substr(1)
  }

  let params = {}
  if (strQuery) {
    strQuery.split('&').forEach((query) => {
      const [key, value] = query.split('=')
      params[key] = decodeURI(value)
    })
  }

  return params
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const CustomizationAreas = {
  'United States of America': [
    { name: 'California' },
    { name: 'Hawaii, Alaska' },
    { name: 'Midwest' },
    { name: 'Northeast' },
    { name: 'Northwest' },
    { name: 'Southeast' },
    { name: 'Southwest' },
    { name: 'Texas' },
  ],
  Canada: [{ name: 'East Canada' }, { name: 'West Canada' }],
}
export const generateCountriesMap = (countries) => {
  let map = {}
  countries.forEach((country) => {
    let { continent, region } = country

    if (region === 'South America') {
      continent = region
    } else if (['Central America', 'Caribbean', 'North America'].includes(region)) {
      continent = 'North America'
    }

    if (['Canada', 'United States of America', 'Mexico'].includes(country.name)) {
      region = country.name
      continent = 'North America'
    }

    if (!map[continent]) {
      map[continent] = {}
    }

    if (!map[continent][region]) {
      map[continent][region] = []
    }

    if (CustomizationAreas[country.name]) {
      const customizedAreas = CustomizationAreas[country.name].map((area) => ({
        ...country,
        continent,
        region,
        ...area,
      }))

      map[continent][region] = map[continent][region].concat(customizedAreas)
    } else {
      map[continent][region].push({ ...country, continent, region })
    }
  })
  return map
}

export const isTrialPlan = (plan) => {
  return plan?.types?.includes('Trial')
}

export const downloadFile = (downloadLink, savedFileName) => {
  if (downloadLink && savedFileName) {
    // create file link in browser's memory
    const href = URL.createObjectURL(downloadLink)

    // create "a" HTLM element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', savedFileName) //or any other extension
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }
}
