import { Delete } from '@mui/icons-material'
import { Button, IconButton, InputLabel, Link, MenuItem, styled as MUIStyled, TextField } from '@mui/material'
import { AddressInput } from 'components/AddressInput'
import { CargoTypesInput } from 'components/CargoTypesInput'
import { HandlingTypesInput } from 'components/HandlingTypesInput'
import HarmonizedTaxCodesInput from 'components/HarmonizedTaxCodesInput'
import Loading from 'components/Loading'
import ProtectedPage from 'components/ProtectedPage'
import StyledInput from 'components/StyledInput'
import StyledTable from 'components/StyledTable'
import TransportModesInput from 'components/TransportModesInput'
import { HTTP_STATUS, MESSAGE_TYPES, SERVED_LANGUAGE_OPTIONS } from 'constants/common'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getClientUserDetail, saveClientDetail } from 'services/api'
import { createMessage, isSuccessRequest } from 'utils/common'
import * as Yup from 'yup'
import { AddInterestedRouteDialog } from './AddInterestedRouteDialog'

const ROUTE_TABLE_COLUMNS = [
  {
    label: 'buyer_shipper_form_page.interested_route_table_origin_column_label',
    key: 'origin',
  },
  {
    label: 'buyer_shipper_form_page.interested_route_table_destination_column_label',
    key: 'destination',
  },
  {
    label: 'buyer_shipper_form_page.interested_route_table_operation_column_label',
    key: 'operations',
  },
]

const BuyerShipperRoutesInput = MUIStyled(({ className, value, onChange }) => {
  const { t } = useTranslation()
  const [enabledAddRouteDialog, setEnabledAddRouteDialog] = useState(false)
  const [routes, setRoutes] = useState(value || [])

  useEffect(() => {
    setRoutes(value || [])
  }, [value])

  const updateRoutes = (changedRoutes) => {
    setRoutes(changedRoutes)
    if (typeof onChange === 'function') {
      onChange(null, changedRoutes)
    }
  }

  const handleAddNewRouteClick = () => {
    setEnabledAddRouteDialog(true)
  }

  const handleCloseAddRouteDialog = () => {
    setEnabledAddRouteDialog(false)
  }

  const handleSaveRoute = ({ origin, destination }) => {
    updateRoutes([{ origin, destination }, ...routes])
    handleCloseAddRouteDialog()
  }

  const handleDeleteRouteClick = (deletedIndex) => () => {
    updateRoutes([...routes.slice(0, deletedIndex), ...routes.slice(deletedIndex + 1)])
  }

  const renderRouteTableColumn = (name, value, row, rowIndex) => {
    const { city_name, country_name, state_name, locode } = value || {}
    switch (name) {
      case 'origin':
      case 'destination':
        return (
          <>
            <div className="address">
              {[city_name, state_name, country_name].filter((item) => Boolean(item)).join(', ')}
            </div>
            <div className="locode">{locode || ''}</div>
          </>
        )

      case 'operations':
        return (
          <IconButton color="error" onClick={handleDeleteRouteClick(rowIndex)}>
            <Delete />
          </IconButton>
        )

      default:
        return null
    }
  }

  return (
    <div className={['buyer-shipper-routes-input', 'form-input-wrapper', className].join(' ')}>
      <div className="field-label">
        <InputLabel className="main-label">{t('buyer_shipper_form_page.interested_route_field_label')}</InputLabel>
        <Link onClick={handleAddNewRouteClick}>{t('buyer_shipper_form_page.add_interested_route_button_label')}</Link>
      </div>

      <div className="routes-wrapper">
        <StyledTable columns={ROUTE_TABLE_COLUMNS} data={routes} renderColumn={renderRouteTableColumn} />
      </div>

      <AddInterestedRouteDialog
        onClose={handleCloseAddRouteDialog}
        open={enabledAddRouteDialog}
        onSave={handleSaveRoute}
      />
    </div>
  )
})({
  flexDirection: 'column',
  rowGap: 16,

  '& .field-label': {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,

    '& .main-label': {
      fontSize: 18,
      fontWeight: 600,
    },

    '& .MuiLink-root': {
      cursor: 'pointer',
    },
  },

  '& .table-col-operations': {
    width: 120,
    textAlign: 'center',
  },

  '& .routes-wrapper': {
    '& .address': {
      fontWeight: 500,
    },

    '& .locode': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
})

let fetchedUserData = false
let updateFieldTimer = -1

export const BuyerShipperFormPage = MUIStyled(({ className }) => {
  const { userId } = useParams()
  const { t } = useTranslation()
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const title = t(`buyer_shipper_form_page.${userId ? 'edit' : 'add_new'}_buyer_page_title`)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchingUserData, setFetchingUserData] = useState(Boolean(userId))

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form_validation.email_invalid_format'))
      .max(50, t('form_validation.max_length', { max: 50 }))
      .required(t('form_validation.required_field')),
    company_info: Yup.object({
      company_name: Yup.string()
        .max(40, t('form_validation.max_length', { max: 40 }))
        .required(t('form_validation.required_field')),
      contact_name: Yup.string()
        .max(40, t('form_validation.max_length', { max: 40 }))
        .required(t('form_validation.required_field')),
      phones: Yup.array()
        .of(Yup.string().phone(t('form_validation.phone_invalid_format')))
        .min(1, t('form_validation.required_field')),
      faxes: Yup.array().of(Yup.string().phone(t('form_validation.fax_invalid_format'))),
      language_served: Yup.array()
        .min(1, t('form_validation.required_field'))
        .max(10, t('form_validation.max_selected', { max: 10 })),
      cargo_types: Yup.array().min(1, t('form_validation.unselected_cargo_types')),
      transport_modes: Yup.array().min(1, t('form_validation.unselected_transport_modes')),
    }),
    address: Yup.object({
      address: Yup.string()
        .max(200, t('form_validation.max_length', { max: 200 }))
        .required(t('form_validation.required_field')),
      country: Yup.string().required(t('form_validation.required_field')),
      city: Yup.string()
        .max(50, t('form_validation.max_length', { max: 50 }))
        .required(t('form_validation.required_field')),
      postal_code: Yup.string()
        .alphaNumeric(t('form_validation.postal_code_invalid'), false)
        .max(15, t('form_validation.max_length', { max: 15 })),
    }),
  })

  const { handleSubmit, setValues, setFieldValue, errors, touched, values, isSubmitting } = useFormik({
    initialValues: {
      email: '',
      company_info: {
        company_name: '',
        contact_name: '',
        phones: [],
        faxes: [],
        language_served: [],
        transport_modes: [],
        cargo_types: [],
        handing_types: [],
        harmonized_tax_codes: [],
      },
      address: {},
      routes: [],
    },
    validationSchema,
    onSubmit: async ({ address, company_info, ...other }, { setSubmitting }) => {
      try {
        setSubmitting(true)
        const response = await saveClientDetail(
          userId,
          { ...other, company_info: { ...company_info, ...address } },
          accessToken
        )
        setSubmitting(false)

        if (isSuccessRequest(response.status)) {
          dispatch(
            createMessage({
              type: MESSAGE_TYPES.SUCCESS,
              content: t(`buyer_shipper_form_page.${userId ? 'update' : 'create'}_buyer_success`),
            })
          )

          navigate('/users?type=BUYER_SHIPPER')
        }
      } catch {
        dispatch(
          createMessage({
            type: MESSAGE_TYPES.ERROR,
            content: t(`buyer_shipper_form_page.${userId ? 'update' : 'create'}_buyer_failed`),
          })
        )
      }
    },
  })

  useEffect(() => {
    const fetchUserDetail = async () => {
      fetchedUserData = true
      setFetchingUserData(true)
      try {
        const response = await getClientUserDetail(userId, accessToken)
        if (isSuccessRequest(response.status)) {
          const { company_info, routes, email } = response.data.data
          setValues({
            company_info,
            routes,
            email,
            address: {
              address: company_info.address,
              country: company_info.country,
              state: company_info.state,
              city: company_info.city,
              postal_code: company_info.postal_code,
            },
          })
        }
      } catch (error) {
        if (error?.response?.status === HTTP_STATUS.NOT_FOUND) {
          navigate('/404', { replace: true })
        } else {
          navigate('/500', { replace: true })
        }
      }
      setFetchingUserData(false)
    }
    if (!fetchedUserData && accessToken && userId) {
      fetchUserDetail()
    }
  }, [userId, accessToken, setValues, navigate])

  const handleFieldChange = (name) => (event, changedValue) => {
    switch (name) {
      case 'company_info.company_name':
      case 'company_info.contact_name':
      case 'email':
      case 'company_info.phones':
      case 'company_info.faxes':
        if (updateFieldTimer > 0) {
          clearTimeout(updateFieldTimer)
        }
        updateFieldTimer = setTimeout(() => {
          let newValue = event.target.value
          if (['company_info.phones', 'company_info.faxes'].includes(name)) {
            newValue = [newValue]
          }
          setFieldValue(name, newValue)
          updateFieldTimer = -1
        }, 500)
        break

      case 'company_info.language_served':
        setFieldValue(name, event.target.value)
        break

      case 'company_info.transport_modes':
      case 'company_info.cargo_types':
      case 'company_info.handing_types':
      case 'company_info.harmonized_tax_codes':
      case 'address':
      case 'routes':
        setFieldValue(name, changedValue)
        break

      default:
        break
    }
  }

  return (
    <ProtectedPage
      className={['buyer-shipper-form-page', className].join(' ')}
      title={
        <>
          <div className="main-title">{title}</div>
          <Button variant="contained" href="/users?type=BUYER_SHIPPER">
            {t('buyer_shipper_form_page.back_button_label')}
          </Button>
        </>
      }
      breadcrumbs={[{ label: t('users_management_page.title'), path: '/users' }, { label: title }]}
    >
      {(fetchingUserData || isSubmitting) && <Loading />}

      <form onSubmit={handleSubmit}>
        <div className="session company-info-session">
          <div className="session-title">{t('buyer_shipper_form_page.company_session_title')}</div>
          <div className="session-content">
            <div className="column left-col">
              <StyledInput
                onChange={handleFieldChange('company_info.company_name')}
                value={values.company_info.company_name}
                label={t('buyer_shipper_form_page.company_name_field_label')}
                helperText={touched.company_info?.company_name && errors.company_info?.company_name}
                error={errors.company_info?.company_name && touched.company_info?.company_name}
              />
              <StyledInput
                onChange={handleFieldChange('company_info.contact_name')}
                value={values.company_info.contact_name}
                label={t('buyer_shipper_form_page.contact_name_field_label')}
                helperText={touched.company_info?.contact_name && errors.company_info?.contact_name}
                error={errors.company_info?.contact_name && touched.company_info?.contact_name}
              />
              <StyledInput
                name="emails"
                onChange={handleFieldChange('email')}
                value={values.email}
                label={t('buyer_shipper_form_page.email_field_label')}
                helperText={touched.email && errors.email}
                error={errors.email && touched.email}
              />
              <StyledInput
                name="company_info.phones"
                onChange={handleFieldChange('company_info.phones')}
                value={values.company_info.phones[0] || ''}
                label={t('buyer_shipper_form_page.phone_field_label')}
                error={errors.company_info?.phones && touched.company_info?.phones}
                helperText={touched.company_info?.phones && errors.company_info?.phones}
              />
              <StyledInput
                name="company_info.faxes"
                onChange={handleFieldChange('company_info.faxes')}
                value={values.company_info.faxes[0] || ''}
                label={t('buyer_shipper_form_page.fax_field_label')}
                error={touched.company_info?.faxes && errors.company_info?.faxes}
              />
              <AddressInput
                label={t('buyer_shipper_form_page.address_field_label')}
                onChange={handleFieldChange('address')}
                error={touched.address && errors.address}
                value={values.address}
              />
              <div className="form-input-wrapper align-center">
                <InputLabel htmlFor="broker-general-info">
                  {t('buyer_shipper_form_page.languages_served_field_label')}
                </InputLabel>
                <div className="input-wrapper">
                  <TextField
                    select
                    name="company_info.language_served"
                    SelectProps={{ multiple: true }}
                    value={values.company_info.language_served}
                    onChange={handleFieldChange('company_info.language_served')}
                    error={
                      Boolean(errors.company_info?.language_served) && Boolean(touched.company_info?.language_served)
                    }
                    helperText={touched.company_info?.language_served && errors.company_info?.language_served}
                  >
                    {Object.keys(SERVED_LANGUAGE_OPTIONS).map((lang) => (
                      <MenuItem key={`lang-opt-${lang}`} value={lang}>
                        {t(`served_language.${lang}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
            <div className="column right-col"></div>
          </div>
        </div>
        <div className="session interested-area-info-session">
          <div className="session-title">{t('buyer_shipper_form_page.interested_area_session_title')}</div>
          <div className="session-content">
            <div className="form-input-wrapper">
              <InputLabel htmlFor="broker-general-info">
                {t('buyer_shipper_form_page.transport_modes_field_label')}
              </InputLabel>
              <div className="input-wrapper">
                <TransportModesInput
                  error={touched.company_info?.transport_modes && errors.company_info?.transport_modes}
                  onChange={handleFieldChange('company_info.transport_modes')}
                  value={values.company_info.transport_modes}
                />
              </div>
            </div>
            <div className="form-input-wrapper">
              <InputLabel htmlFor="broker-general-info">
                {t('buyer_shipper_form_page.cargo_types_field_label')}
              </InputLabel>
              <div className="input-wrapper">
                <CargoTypesInput
                  error={touched.company_info?.cargo_types && errors.company_info?.cargo_types}
                  onChange={handleFieldChange('company_info.cargo_types')}
                  value={values.company_info.cargo_types}
                />
              </div>
            </div>
            <div className="form-input-wrapper">
              <InputLabel htmlFor="broker-general-info">
                {t('buyer_shipper_form_page.handling_types_field_label')}
              </InputLabel>
              <div className="input-wrapper">
                <HandlingTypesInput
                  onChange={handleFieldChange('company_info.handing_types')}
                  value={values.company_info.handing_types}
                />
              </div>
            </div>
            <div className="form-input-wrapper">
              <InputLabel htmlFor="broker-general-info">
                {t('buyer_shipper_form_page.harmonized_tax_code_field_label')}
              </InputLabel>
              <div className="input-wrapper">
                <HarmonizedTaxCodesInput
                  onChange={handleFieldChange('company_info.harmonized_tax_codes')}
                  value={values.company_info.harmonized_tax_codes}
                />
              </div>
            </div>
            <BuyerShipperRoutesInput value={values.routes} onChange={handleFieldChange('routes')} />
            <div className="actions-wrapper">
              <Button variant="contained" type="submit">
                {t(`buyer_shipper_form_page.${userId ? 'update' : 'create'}_button_label`)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </ProtectedPage>
  )
})({
  '& .page-title': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .content-wrapper': {
    position: 'relative',
    overflow: 'hidden',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
  },

  '& .form-input-wrapper': {
    display: 'flex',
    columnGap: 16,

    '& > label': {
      width: 260,
      fontSize: 18,
      fontWeight: 600,
      whiteSpace: 'break-spaces',
    },

    '& .input-wrapper': {
      flex: 1,

      '& .MuiTextField-root': {
        minWidth: 250,
      },
    },

    '&.align-center': {
      alignItems: 'center',
    },

    '&.flex-column': {
      flexDirection: 'column',
      rowGap: 12,
    },

    '& .MuiSelect-select': {
      paddingTop: 12.5,
      paddingBottom: 12.5,
    },
  },

  '& .session': {
    '& .session-title': {
      backgroundColor: 'rgba(130, 151, 224, 0.33)',
      fontWeight: 600,
      fontSize: 20,
      padding: '12px 24px',
    },

    '& .session-content': {
      display: 'flex',
      padding: '16px 32px',

      '& .column': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 24,
        flex: 1,

        '&.right-col': {
          alignItems: 'center',
        },
      },
    },

    '&.interested-area-info-session': {
      '& .session-content': {
        flexDirection: 'column',
        rowGap: 24,
        maxWidth: 992,

        '& .transport-modes-input': {
          justifyContent: 'flex-start',
          columnGap: 24,

          '& label': {
            flex: 0,
          },
        },

        '& .cargo-types-input': {
          padding: 0,
          rowGap: 8,

          '& label': {
            maxWidth: 280,
            minWidth: 0,
          },
        },

        '& .handling-types-input': {
          padding: 0,
          rowGap: 8,
        },
      },
    },
  },
})
