import { Button, Dialog, styled as MUIStyled } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Loading from '../components/Loading'
import ProtectedPage from '../components/ProtectedPage'
import StyledPagination from '../components/StyledPagination'
import { MESSAGE_TYPES } from '../constants/common'
import { getUserNotifications } from '../services/api'
import { createMessage, isSuccessRequest } from '../utils/common'

const NotificationItem = MUIStyled(({ className, data, onClearNotificationClick }) => {
  const { t } = useTranslation()
  const { title, description, createdAt } = data?.notification || {}

  const minutesDiff = dayjs().diff(dayjs(createdAt), 'minute')
  let dateString = dayjs(createdAt).format('MMM DD, hh:mm:ss A')
  if (minutesDiff < 60) {
    dateString = `${minutesDiff > 0 ? `${minutesDiff} ` : ''}${t('my_notifications_page.notification_minute', {
      count: minutesDiff,
    })}`
  } else {
    const hour = Math.floor(minutesDiff / 60)
    if (hour < 24) {
      dateString = `${hour} ${t('my_notifications_page.notification_hour', { count: hour })}`
    }
  }

  return (
    <div className={['notification-item', className].join(' ')}>
      <div className="item-content-wrapper">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="time">{dateString}</div>
      </div>
      <div className="actions-wrapper">
        <Button onClick={onClearNotificationClick}>{t('my_notifications_page.clear_notifcation_button_label')}</Button>
      </div>
    </div>
  )
})({
  display: 'flex',
  padding: '8px 0',
  borderBottom: '1px solid #A0A0A0',
  columnGap: 30,

  '& .item-content-wrapper': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,

    '& .title': {
      fontWeight: 500,
      marginBottom: 8,
    },

    '& .time': {
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 300,
    },
  },

  '& .actions-wrapper': {
    '& .MuiButton-root': {
      backgroundColor: 'transparent !important',
      textTransform: 'none',
    },
  },
})

const ClearConfirmationDialog = MUIStyled(({ className, notificationId, onClearSuccess, ...other }) => {
  const { t } = useTranslation()
  const confirmMessage = t(`my_notifications_page.notification_clear_${notificationId ? 'single' : 'all'}`)
  const dispatch = useDispatch()

  const handleClearClick = async () => {
    try {
      if (typeof onClearSuccess === 'function') {
        onClearSuccess()
      }
    } catch {
      dispatch(
        createMessage({
          type: MESSAGE_TYPES.ERROR,
          content: t(`my_notifications_page.notification_clear_${notificationId ? 'single' : 'all'}_failed`),
        })
      )
    }

    if (typeof other.onClose === 'function') {
      other.onClose()
    }
  }

  return (
    <Dialog {...other} className={['confirm-clear-notification-dialog', className].join(' ')}>
      <div className="message">{confirmMessage}</div>
      <div className="actions-wrapper">
        <Button variant="outlined" color="primary" onClick={other.onClose}>
          {t('my_notifications_page.cancel_button_label')}
        </Button>
        <Button variant="contained" color="error" onClick={handleClearClick}>
          {t('my_notifications_page.clear_button_label')}
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    minWidth: 500,
    padding: '24px 48px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 32,
  },

  '& .message': {
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
  },

  '& .actions-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 24,

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 120,
    },
  },
})

const MyNotificationsPage = MUIStyled(({ className }) => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageData, setPageData] = useState({})
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [fetchingData, setFetchingData] = useState(false)
  const [fetchingTime, setFetchingTime] = useState(Date.now())
  const [clearingNotification, setClearingNotification] = useState({ open: false, notificationId: null })

  useEffect(() => {
    const fetchNotifications = async () => {
      setFetchingData(true)
      try {
        const queryString = currentPage > 1 ? `?page=${currentPage}` : ''
        const response = await getUserNotifications(accessToken, queryString)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch {
        setPageData({})
      }
      setFetchingData(false)
    }
    if (fetchingTime) {
      fetchNotifications()
    }
  }, [currentPage, accessToken, fetchingTime])

  const handlePageChange = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleCloseConfirmDialog = () => {
    setClearingNotification({ open: false, notificationId: null })
  }

  const handleClearNotificationSuccess = () => {
    if (!clearingNotification.notificationId) {
      setCurrentPage(1)
    }
    setFetchingTime(Date.now())
  }

  const handleClearAllClick = () => {
    setClearingNotification({ open: true, notificationId: null })
  }

  const handleClearNotificationClick = (notificationId) => () => {
    setClearingNotification({ open: true, notificationId })
  }

  return (
    <ProtectedPage
      className={['my-notifications-page', className].join(' ')}
      title={t('my_notifications_page.title')}
      breadcrumbs={[{ label: t('my_notifications_page.title') }]}
    >
      <Button
        variant="contained"
        color="primary"
        className="clear-all-notifications-button"
        onClick={handleClearAllClick}
      >
        {t('my_notifications_page.clear_all_button_label')}
      </Button>
      <div className="notification-list">
        {pageData.data?.map((item, index) => (
          <NotificationItem
            data={item}
            key={`notification-item-${index}`}
            onClearNotificationClick={handleClearNotificationClick(item.id)}
          />
        ))}
        {!fetchingData && !pageData.data?.length && (
          <div className="empty-message">{t('my_notifications_page.empty_list_message')}</div>
        )}
        {fetchingData && <Loading />}
      </div>
      <StyledPagination page={currentPage} onChange={handlePageChange} />
      <ClearConfirmationDialog
        {...clearingNotification}
        onClose={handleCloseConfirmDialog}
        onClearSuccess={handleClearNotificationSuccess}
      />
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    position: 'relative',

    '& .clear-all-notifications-button': {
      position: 'absolute',
      top: 24,
      right: 24,
      textTransform: 'none',
      fontWeight: 500,
    },
  },

  '& .notification-list': {
    flex: 1,
    position: 'relative',

    '& .loading': {
      backgroundColor: 'transparent',
    },

    '& .empty-message': {
      textAlign: 'center',
    },
  },
})

export default MyNotificationsPage
