import { Search, Visibility } from '@mui/icons-material'
import { Button, IconButton, styled as MUIStyled, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import DatePicker from '../../components/DatePicker'
import ProtectedPage from '../../components/ProtectedPage'
import { getRFQList } from '../../services/api'
import { isSuccessRequest } from '../../utils/common'
import StyledTable from '../../components/StyledTable'
import { useTranslation } from 'react-i18next'

const TABLE_COLUMNS = [
  { label: 'rfq_management_page.index_column_label', key: 'index' },
  { label: 'rfq_management_page.buyer_shipper_column_label', key: 'buyer' },
  { label: 'rfq_management_page.broker_column_label', key: 'broker' },
  { label: 'rfq_management_page.created_on_column_label', key: 'createdAt' },
  { label: 'rfq_management_page.actions_column_label', key: 'actions' },
]
const PAGE_SIZE = 10
const defaultFilters = {
  page: 1,
  start_date: null,
  end_date: null,
  query: '',
}

const RFQManagementPage = MUIStyled(({ className }) => {
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [pageData, setPageData] = useState({})
  const [filters, setFilters] = useState(defaultFilters)
  const [fetchingData, setFetchingData] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchRFQList = async () => {
      setFetchingData(true)

      try {
        const queryString = Object.entries(filters)
          .filter(([key, value]) => Boolean(value) && ((key === 'page' && value > 1) || key !== 'page'))
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&')
        const response = await getRFQList(accessToken, `?${queryString}`)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch {
        setPageData({})
      }
      setFetchingData(false)
    }
    if (accessToken) {
      fetchRFQList()
    }
  }, [accessToken, filters])

  const handleFilterChange = (filterName) => (event, value) => {
    let newValue

    switch (filterName) {
      case 'page':
        newValue = { ...filters, [filterName]: value }
        break

      case 'start_date':
      case 'end_date':
        newValue = { ...filters, [filterName]: value, page: 1 }
        break

      case 'query':
        newValue = { ...filters, [filterName]: event.target.value, page: 1 }
        break

      default:
        newValue = filters
    }

    setFilters(newValue)
  }

  const handleClearFilters = () => {
    setFilters(defaultFilters)
  }

  const renderColumn = (name, value, record) => {
    let cellContent

    switch (name) {
      case 'buyer':
      case 'broker':
        cellContent = (
          <>
            <div className="username">{value[0]?.company_info.company_name}</div>
            <div className="email">{`${value[0].email}`}</div>
          </>
        )
        break

      case 'createdAt':
        cellContent = dayjs(value).format('MM-DD-YYYY')
        break

      case 'actions':
        cellContent = (
          <IconButton color="primary" href={`/rfqs/${record._id}`}>
            <Visibility />
          </IconButton>
        )
        break

      default:
        cellContent = value
    }

    return cellContent
  }

  const disabledClearFilters =
    Object.entries(filters).filter(([key, value]) => key !== 'page' && Boolean(value)).length === 0

  return (
    <ProtectedPage
      title={t('rfq_management_page.title')}
      breadcrumbs={[{ label: t('rfq_management_page.title') }]}
      className={['rfqs-management-page', className].join(' ')}
    >
      <div className="top-page">
        <div className="filters-wrapper">
          <TextField
            className="search-input"
            InputProps={{ startAdornment: <Search /> }}
            placeholder={t('rfq_management_page.filter_input_placeholder')}
            onChange={handleFilterChange('query')}
            value={filters.query}
          />
          <div className="date-filters">
            <div className="date-field from-field">
              <div className="field-label">{t('rfq_management_page.filter_by_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('start_date')}
                value={filters.start_date}
              />
            </div>
            <div className="date-field">
              <div className="field-label"> {t('rfq_management_page.filter_to_label')}</div>
              <DatePicker
                inputFormat={'MM/DD/YYYY'}
                views={['year', 'month', 'day']}
                onChange={handleFilterChange('end_date')}
                value={filters.end_date}
                minDate={filters.start_date ? dayjs(filters.start_date) : undefined}
              />
            </div>
            <Button variant="contained" color="primary" onClick={handleClearFilters} disabled={disabledClearFilters}>
              {t('rfq_management_page.clear_filters_button_label')}
            </Button>
          </div>
        </div>
      </div>
      <StyledTable
        columns={TABLE_COLUMNS}
        loading={fetchingData}
        data={pageData.data
          ?.map((row, index) => ({ ...row, index: index + 1 }))
          ?.filter((row) => row.broker.length > 0 && row.buyer.length > 0)}
        pagination={{
          page: filters.page,
          count: Math.ceil((pageData.pagination?.total || 0) / PAGE_SIZE),
        }}
        onPageChange={handleFilterChange('page')}
        renderColumn={renderColumn}
        emptyMessage={t('rfq_management_page.empty_message')}
      />
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    rowGap: '40px !important',
  },

  '& .top-page': {
    display: 'flex',
    columnGap: 60,
    justifyContent: 'space-between',
    position: 'relative',

    '& .MuiButton-root': {
      textTransform: 'none',
      width: 150,
    },

    '& .filters-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1200,
      rowGap: 16,

      '& .date-filters': {
        display: 'flex',
        columnGap: 16,

        '& .date-field': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
          flex: 1,

          '&.from-field .field-label': {
            minWidth: 75,
          },

          '& .date-picker': {
            flex: 1,

            '& .MuiFormControl-root': {
              flex: 1,
              width: '100%',
            },
          },
        },
      },

      '& input': {
        padding: '12px 14px 12px 14px',
        height: 24,
      },

      '& .MuiButton-root': {
        borderWidth: 2,
        fontWeight: 500,
      },
    },

    '& .add-wrapper': {
      position: 'absolute',
      top: -80,
      right: 0,

      '& .MuiButton-root': {
        height: 56,
        fontWeight: 600,
      },
    },
  },

  '& .table-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,

    '& .table-col-actions': {
      textAlign: 'center',
    },
  },
})

export default RFQManagementPage
