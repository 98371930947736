import { Delete } from '@mui/icons-material'
import { FormHelperText, IconButton, InputLabel, styled as MUIStyled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isSuccessRequest, toBase64 } from '../utils/common'
import { FileIcon, PDFFileIcon } from './Icons'
import { uploadFile } from 'services/api'

const FilePreview = MUIStyled(({ className, file }) => {
  if (!file?.url) {
    return null
  }

  const filename = file.name || file.filename || ''
  const fileType = filename.split('.')?.at(-1)?.toLowerCase()
  let filePreview = <FileIcon />
  if (['jpg', 'jpeg', 'svg', 'png', 'bmp', 'gif'].includes(fileType)) {
    filePreview = <img src={file.url} alt="" />
  } else if (fileType === 'pdf') {
    filePreview = <PDFFileIcon />
  }

  return (
    <div className={['file-preview', className].join(' ')}>
      {filePreview}
      <div className="filename">{filename}</div>
    </div>
  )
})({
  overflow: 'hidden',
  display: 'flex',
  columnGap: 12,
  alignItems: 'center',

  '& img': {
    width: 125,
    height: 'auto',
  },
})

const FilePicker = MUIStyled(
  ({
    className,
    value,
    label,
    onChange,
    description,
    uploadButtonLabel,
    changeButtonLabel,
    single,
    maxFiles,
    autoUpload,
    uploadAPIType,
    accessToken,
    error,
    ...other
  }) => {
    const [selectedFiles, setSelectedFiles] = useState(value || [{}])
    const { t } = useTranslation()

    useEffect(() => {
      setSelectedFiles(value || [{}])
    }, [value])

    const handleFileChange = (index) => async (event) => {
      const file = event.target.files[0]
      let newFiles = [...selectedFiles]

      if (file) {
        try {
          const fileBase64 = await toBase64(file)
          newFiles[index] = { filename: file.name, name: file.name, size: file.size, type: file.type, url: fileBase64 }

          if (autoUpload) {
            const uploadFileResponse = await uploadFile(uploadAPIType || 'file', fileBase64, accessToken)
            if (isSuccessRequest(uploadFileResponse.status)) {
              newFiles[index].url = uploadFileResponse.data[`${uploadAPIType || 'file'}_url`]
            }
          }
        } catch {}
      }
      if (!single && index === newFiles.length - 1 && (!maxFiles || newFiles.length < maxFiles)) {
        newFiles.push({})
      }
      setSelectedFiles(newFiles)
      if (typeof onChange === 'function') {
        onChange(event, newFiles)
      }
    }

    const handleDeleteFile = (index) => () => {
      let newFiles = [...selectedFiles.slice(0, index), ...selectedFiles.slice(index + 1)]
      if (newFiles.length === 0) {
        newFiles = [{}]
      }
      setSelectedFiles(newFiles)
      if (typeof onChange === 'function') {
        onChange(null, newFiles)
      }
    }

    return (
      <div className={['file-picker', className].join(' ')}>
        {label && <InputLabel>{label}</InputLabel>}
        <div className="control-wrapper">
          <div className="file-items">
            {selectedFiles.map((renderFile, index) => {
              const buttonLabel =
                uploadButtonLabel ||
                (renderFile?.url
                  ? changeButtonLabel || t('file_picker.change_button_label')
                  : t('file_picker.upload_button_label'))

              return (
                <div className="file-item-wrapper" key={`${className}-${index}`}>
                  <div className="field-controller">
                    <FilePreview file={renderFile} />
                    <div className="upload-button-wrapper">
                      <label>
                        {buttonLabel}
                        <input type="file" onChange={handleFileChange(index)} style={{ display: 'none' }} {...other} />
                      </label>
                      <div className="desc">{description}</div>
                    </div>
                    {selectedFiles.length >= 1 && (index !== selectedFiles.length - 1 || Boolean(renderFile?.url)) && (
                      <IconButton color="error" onClick={handleDeleteFile(index)}>
                        <Delete />
                      </IconButton>
                    )}
                  </div>
                  {typeof error === 'object' && error[index] && <FormHelperText error>{error[index]}</FormHelperText>}
                </div>
              )
            })}
          </div>
          {error && typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
        </div>
      </div>
    )
  }
)({
  display: 'flex',
  columnGap: 16,

  '& .file-items': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },

  '& .file-item-wrapper': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .field-controller': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 16,

    '& label': {
      border: '1px solid #A0A0A0',
      borderRadius: 4,
      padding: 8,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgba(41, 46, 111, 0.04)',
      },
    },

    '& .desc': {
      marginTop: 8,
    },
  },
})

export default FilePicker
