import { Pagination, styled as MUIStyled } from '@mui/material'

const StyledPagination = MUIStyled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul': {
    justifyContent: 'flex-end',

    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      fontWeight: 600,
    },
  },
}))

export default StyledPagination
