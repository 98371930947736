import { REHYDRATE } from 'redux-persist'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { getAdminPermissions, getUserNotifications } from '../../services/api'
import { isSuccessRequest } from '../../utils/common'
import { authActions } from '../slices/authSlice'

function* fetchAdminPermissionList() {
  try {
    const { accessToken } = yield select((state) => state.auth)
    if (accessToken) {
      const response = yield call(getAdminPermissions, accessToken)
      if (isSuccessRequest(response.status)) {
        yield put(authActions.fetchAdminPermissionListSuccess(response.data.data || []))
      }
    }
  } catch {}
}

function* checkNewNotifications() {
  try {
    const { accessToken } = yield select((state) => state.auth)
    const response = yield call(getUserNotifications, accessToken, '?is_new=true')
    if (isSuccessRequest(response.status)) {
      yield put(authActions.updateNewNotifications(response.data?.pagination?.total || 0))
    } else {
      yield put(authActions.updateNewNotifications(0))
    }
  } catch {
    yield put(authActions.updateNewNotifications(0))
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.checkNewNotifications.type, checkNewNotifications)
  yield takeLatest(REHYDRATE, fetchAdminPermissionList)
  yield takeEvery(authActions.loginSuccess.type, fetchAdminPermissionList)
}
