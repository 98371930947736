import { Delete } from '@mui/icons-material'
import { Button, Dialog, IconButton, MenuItem, Pagination, styled as MUIStyled, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { EditIcon } from '../../components/Icons'
import Loading from '../../components/Loading'
import ProtectedPage from '../../components/ProtectedPage'
import { FAQ_TYPES, MESSAGE_TYPES } from '../../constants/common'
import { deleteFAQ, getFAQs } from '../../services/api'
import { createMessage, isSuccessRequest } from '../../utils/common'

const FAQFilterOptions = {
  ALL: 'All',
  ...FAQ_TYPES,
}

let FAQ_OPTS = {}
Object.keys(FAQFilterOptions).forEach((key) => {
  FAQ_OPTS[key] = key
})

const DeleteFAQConfirmDialog = MUIStyled((props) => {
  return (
    <Dialog {...props}>
      <div className="confirm-message">Do you want to delete this FAQ?</div>
      <div className="actions-wrapper">
        <Button variant="outlined" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={props.onDelete}>
          Delete
        </Button>
      </div>
    </Dialog>
  )
})({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,

    '& .confirm-message': {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 500,
    },

    '& .actions-wrapper': {
      display: 'flex',
      columnGap: 24,
      justifyContent: 'center',

      '& .MuiButton-root': {
        textTransform: 'none',
        width: 100,
      },
    },
  },
})

const FAQItem = MUIStyled(({ className, data, onActionSuccess }) => {
  const [showingDeleteDialog, setShowingDeleteDialog] = useState(false)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const dispatch = useDispatch()

  const handleDeleteFAQClick = () => {
    setShowingDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setShowingDeleteDialog(false)
  }

  const handleDeleteConfirmed = async () => {
    setShowingDeleteDialog(false)
    try {
      const response = await deleteFAQ(data.id, accessToken)
      if (isSuccessRequest(response.status)) {
        dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'The FAQ deleted success.' }))
        if (typeof onActionSuccess === 'function') {
          onActionSuccess()
        }
      }
    } catch {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'The FAQ delete failed.' }))
    }
  }

  return (
    <div className={['faq-item', className].join(' ')}>
      <div className="question-wrapper">
        <div className="question">{data.question}</div>
        <div className="answer-wrapper">
          <div className="answer">
            <span dangerouslySetInnerHTML={{ __html: (data.answer || '').replaceAll('\n', '<br/>') }}></span>
          </div>
          <div className="operations-wrapper">
            <IconButton href={`/static-contents/faqs/${data.id}/edit`}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteFAQClick}>
              <Delete />
            </IconButton>
          </div>
        </div>
      </div>
      <DeleteFAQConfirmDialog
        open={showingDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteConfirmed}
      />
    </div>
  )
})({
  display: 'flex',
  columnGap: 16,
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #A0A0A0',
  padding: '12px 16px',

  '& .question-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 4,
    flex: 1,

    '& .answer-wrapper': {
      display: 'flex',
      columnGap: 24,
      minHeight: 60,
    },

    '& .answer': {
      padding: 6,
      backgroundColor: '#cedcf5',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,

      '& > span': {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },

  '& .operations-wrapper': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,

    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
})

const FAQsManagementPage = MUIStyled(({ className }) => {
  const [filterRole, setFilterRole] = useState(FAQ_OPTS.ALL)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [pageData, setPageData] = useState({})
  const [loadingData, setLoadingData] = useState(false)
  const [requestTime, setRequestTime] = useState(Date.now())

  useEffect(() => {
    const fetchFAQs = async () => {
      setLoadingData(true)
      try {
        const queryString = filterRole !== FAQ_OPTS.ALL ? `?type=${filterRole}` : ''
        const response = await getFAQs(queryString, accessToken)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data)
        }
      } catch {}
      setLoadingData(false)
    }

    fetchFAQs()
  }, [filterRole, accessToken, requestTime])

  const handleFilterRoleChange = (event) => {
    setFilterRole(event.target.value)
  }

  const refreshFAQList = () => {
    setRequestTime(Date.now())
  }

  return (
    <ProtectedPage
      title="FAQs Management"
      className={['faqs-management-page', className].join(' ')}
      breadcrumbs={[
        { label: 'Static Contents', path: '/static-contents' },
        { label: 'FAQs', path: '/static-contents/faqs' },
      ]}
    >
      <div className="top-controller">
        <div className="filter-by-role">
          <div className="field-label">Filter Questions By role-</div>
          <TextField select onChange={handleFilterRoleChange} value={filterRole}>
            {Object.keys(FAQFilterOptions).map((opt) => (
              <MenuItem key={`filter-opt-${opt}`} value={opt}>
                {FAQFilterOptions[opt]}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Button variant="contained" href="/static-contents/faqs/add-new">
          Add New Question
        </Button>
      </div>
      <div className={['faq-list', (pageData.data || []).length === 0 ? 'empty-list' : ''].join(' ')}>
        {loadingData && <Loading />}
        {(pageData.data || []).length === 0 && !loadingData && "Don't have any FAQs."}
        {(pageData.data || []).map((question) => (
          <FAQItem key={`faq-item-${question.id}`} data={question} onActionSuccess={refreshFAQList} />
        ))}
      </div>
      <Pagination />
    </ProtectedPage>
  )
})({
  '& .content-wrapper': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .loading': {
    backgroundColor: 'transparent',
    height: 60,
  },

  '& .top-controller': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .filter-by-role': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,

      '& .field-label': {
        fontSize: 24,
        lineHeight: 1.25,
      },

      '& .MuiTextField-root': {
        minWidth: 150,

        '& .MuiOutlinedInput-root': {
          borderRadius: 12,
        },

        '& .MuiSelect-outlined': {
          padding: '3.5px 32px 3.5px 14px',
        },
      },
    },

    '& .MuiButton-root': {
      textTransform: 'none',
    },
  },

  '& .faq-list': {
    marginTop: 40,
    position: 'relative',
    flex: 1,

    '&.empty-list': {
      textAlign: 'center',
    },
  },

  '& .MuiPagination-ul': {
    justifyContent: 'flex-end',
  },
})

export default FAQsManagementPage
