import { styled as MUIStyled, Tab, Tabs } from '@mui/material'

const StyledTabs = MUIStyled(({ tabs, variant = 'contained', className, ...others }) => {
  return (
    <Tabs className={[variant, className].join(' ')} {...others}>
      {tabs.map((tab, index) => (
        <Tab {...tab} key={`tab-${index}-${tab.value}`} />
      ))}
    </Tabs>
  )
})(({ theme }) => ({
  '&.contained': {
    '& .MuiTab-root': {
      backgroundColor: '#EAECEF',
      textTransform: 'none',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
}))
export default StyledTabs
