import { Link, styled as MUIStyled } from '@mui/material'
import { MESSAGE_TYPES } from 'constants/common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Loading from '../components/Loading'
import ProtectedPage from '../components/ProtectedPage'
import { exportDashboardData, getSiteStatistic } from '../services/api'
import { createMessage, downloadFile, isSuccessRequest } from '../utils/common'

const HomePage = MUIStyled(({ className }) => {
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [pageData, setPageData] = useState(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchSiteStatistic = async () => {
      try {
        const response = await getSiteStatistic(accessToken)
        if (isSuccessRequest(response.status)) {
          setPageData(response.data?.data)
        }
      } catch {
        setPageData({})
      }
    }
    if (accessToken) {
      fetchSiteStatistic()
    }
  }, [accessToken])

  const handleExportCSVClick = (type) => async (event) => {
    event.preventDefault()
    try {
      const response = await exportDashboardData(type, accessToken)
      if (isSuccessRequest(response.status)) {
        downloadFile(response.data, `cargokwik_${type}.csv`)
        dispatch(
          createMessage({ type: MESSAGE_TYPES.SUCCESS, content: t(`dashboard_page.export_${type}_success_message`) })
        )
      }
    } catch {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: t(`dashboard_page.export_${type}_error_message`) }))
    }
  }

  return (
    <ProtectedPage
      className={['home-page', className].join(' ')}
      title={t('dashboard_page.title')}
      breadcrumbs={[{ label: 'Dashboard' }]}
    >
      {pageData !== null && (
        <div className="statistic-wrapper">
          <div className="statistic-item">
            <div className="value">{pageData?.routes || 0}</div>
            <div className="label">{t('dashboard_page.total_unique_routes_label')}</div>
            <Link onClick={handleExportCSVClick('routes')}>{t('dashboard_page.export_csv_button_label')}</Link>
          </div>
          <div className="statistic-item">
            <div className="value">{pageData?.brokers || 0}</div>
            <div className="label">{t('dashboard_page.total_brokers_label')}</div>
            <Link onClick={handleExportCSVClick('brokers')}>{t('dashboard_page.total_brokers_label')}</Link>
          </div>
          <div className="statistic-item">
            <div className="value">{pageData?.buyers || 0}</div>
            <div className="label">{t('dashboard_page.total_buyers_shippers')}</div>
            <Link onClick={handleExportCSVClick('buyers')}>{t('dashboard_page.export_csv_button_label')}</Link>
          </div>
        </div>
      )}
      {pageData === null && <Loading />}
    </ProtectedPage>
  )
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',

  '& .content-wrapper': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '& .loading': {
      backgroundColor: 'transparent',
    },

    '& .page-title': {
      width: '100%',
    },
  },

  '& .statistic-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1200,
    width: '100%',
    marginTop: 24,
    columnGap: 12,

    '& .statistic-item': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      borderRadius: 20,
      padding: 24,
      border: `1px solid ${theme.palette.primary.main}`,
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.primary.main,
      position: 'relative',
      flex: 1,
      alignItems: 'center',
      maxWidth: 320,

      '& .value': {
        fontSize: 40,
        textAlign: 'center',
        fontWeight: 700,
      },

      '& a': {
        position: 'absolute',
        right: 12,
        top: 12,
        fontSize: 14,
        cursor: 'pointer',
      },
    },
  },
}))

export default HomePage
