import { InfoRounded } from '@mui/icons-material'
import { Checkbox, ClickAwayListener, FormControlLabel, IconButton, styled as MUIStyled, Tooltip } from '@mui/material'
import { HANDLING_TYPES } from 'constants/common'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const HandlingTypeCheckBox = MUIStyled(({ type, name, onChange, checked }) => {
  const { t } = useTranslation()
  const [showingTooltip, setShowingTooltip] = useState(false)
  const showToolTipButton = useRef(null)

  const handleShowingTooltip = () => {
    setShowingTooltip(true)
  }

  const handleCloseTooltip = (event) => {
    if (!showToolTipButton.current.contains(event.target)) {
      setShowingTooltip(false)
    }
  }
  return (
    <ClickAwayListener onClickAway={handleCloseTooltip} key={`handling-type-${type}`}>
      <div className="option-wrapper">
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          disableHoverListener
          disableTouchListener
          title={t(`handling_type.${type}_tooltip`)}
          arrow
          open={showingTooltip}
          onClose={handleCloseTooltip}
        >
          <FormControlLabel
            control={
              <Checkbox key={`handling-type-${type}`} name={name} onChange={onChange} checked={checked || false} />
            }
            label={t(`handling_type.${type}`)}
          />
        </Tooltip>
        <IconButton onClick={handleShowingTooltip} ref={showToolTipButton}>
          <InfoRounded />
        </IconButton>
      </div>
    </ClickAwayListener>
  )
})({})

const generateDefaultValues = (value) => {
  let defaultMap = {}
  value?.forEach((item) => {
    defaultMap[item] = true
  })

  return defaultMap
}

export const HandlingTypesInput = MUIStyled(({ className, onChange, value }) => {
  const [selectedBoxes, setSelectedBoxes] = useState(generateDefaultValues(value))

  useEffect(() => {
    setSelectedBoxes(generateDefaultValues(value))
  }, [value])

  const handleBoxChange = (type) => (event) => {
    let newData = { ...selectedBoxes }
    newData[type] = event.target.checked
    setSelectedBoxes(newData)

    if (typeof onChange === 'function') {
      const enabledTypes = []
      Object.keys(newData).forEach((type) => {
        if (newData[type]) {
          enabledTypes.push(type)
        }
      })
      onChange(null, enabledTypes)
    }
  }
  return (
    <div className={['handling-types-input', className].join(' ')}>
      {Object.keys(HANDLING_TYPES).map((type) => {
        return (
          <HandlingTypeCheckBox
            type={type}
            key={`handling-type-${type}`}
            onChange={handleBoxChange(type)}
            checked={selectedBoxes[type]}
          />
        )
      })}
    </div>
  )
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  rowGap: 24,
  padding: '12px 18px',

  '& .option-wrapper': {
    width: '30%',
    minWidth: '30%',
    maxWidth: '30%',
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,

    '&:last-of-type': {
      maxWidth: '100%',
      width: 'auto',

      '& button': {
        marginLeft: 18,
      },
    },

    '& label': {
      margin: 0,
    },

    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '18px',
    },

    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: 18,

      '& svg': {
        fontSize: 24,
      },
    },

    '& button': {
      padding: 0,

      '& svg': {
        fontSize: 16,
      },
    },
  },
}))
