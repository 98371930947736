import { Checkbox, FormControlLabel, FormHelperText, InputLabel, styled as MUIStyled } from '@mui/material'
import { FAQ_TYPES } from '../constants/common'
import StyledInput from './StyledInput'

const QuestionType = MUIStyled(({ className, value, onChange, error, helperText }) => {
  const handleCheckboxChange = (type) => (event) => {
    let newValue = [...value]
    if (event.target.checked) {
      newValue.push(type)
    } else {
      newValue = newValue.filter((item) => item !== type)
    }

    if (typeof onChange === 'function') {
      onChange(null, newValue)
    }
  }

  return (
    <div className={['question-type-field', className].join(' ')}>
      <InputLabel>Type/For</InputLabel>
      <div className="controllers">
        <div className="types">
          {Object.entries(FAQ_TYPES).map(([key, label]) => {
            return (
              <FormControlLabel
                key={`${className}-${key}`}
                control={<Checkbox checked={value?.includes(key)} onChange={handleCheckboxChange(key)} />}
                label={label}
              />
            )
          })}
        </div>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </div>
    </div>
  )
})({
  display: 'flex',
  alignItems: 'center',
  columnGap: 16,

  '& > label': {
    minWidth: 100,
  },
})

const FAQField = MUIStyled(({ className, name, onChange, value, error, touched }) => {
  const handleInputChange = (fieldName) => (event, newValue) => {
    if (typeof onChange === 'function') {
      onChange(fieldName, event?.target?.value || newValue)
    }
  }

  return (
    <fieldset className={['question-field', className].join(' ')} name={name}>
      <StyledInput
        name={`${name}.question`}
        onChange={handleInputChange(`${name}.question`)}
        value={value.question || ''}
        label="Question"
        error={Boolean(error?.question) && touched?.question}
        helperText={touched?.question && error?.question}
      />
      <StyledInput
        name={`${name}.answer`}
        onChange={handleInputChange(`${name}.answer`)}
        value={value.answer || ''}
        label="Answer"
        multiline
        rows={3}
        error={Boolean(error?.answer) && touched?.answer}
        helperText={touched?.answer && error?.answer}
      />
      <QuestionType
        value={value.type || []}
        onChange={handleInputChange(`${name}.type`)}
        error={Boolean(error?.type) && (touched?.question || touched?.answer)}
        helperText={(touched?.question || touched?.answer) && error?.type}
      />
    </fieldset>
  )
})({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  border: 'none',
  padding: 0,
})

export default FAQField
