import { Button, Checkbox, styled as MUIStyled } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { generateCountriesMap } from 'utils/common'

const generateSelectingCountries = (selectingCountries = [], countryList = {}) => {
  let defaultCountries = {}
  let defaultContinent
  let defaultRegion
  let selectedRegions = {}

  selectingCountries.forEach((country) => {
    defaultCountries[country.country] = country
    if (!defaultContinent) {
      defaultContinent = country.continent
      defaultRegion = country.region
    }

    if (!selectedRegions[country.region]) {
      selectedRegions[country.region] = {
        continent: country.continent,
        selectedCountries: [country],
      }
    } else {
      selectedRegions[country.region].selectedCountries.push(country)
    }
  })

  let defaultSelectedAllRegions = {}
  Object.keys(selectedRegions).forEach((region) => {
    const { continent, selectedCountries } = selectedRegions[region]
    if (
      selectedCountries.length > 0 &&
      countryList[continent] &&
      countryList[continent][region]?.length === selectedCountries.length
    ) {
      defaultSelectedAllRegions[region] = region
    }
  })

  if (!defaultContinent) {
    defaultContinent = Object.keys(countryList)[0]
  }

  if (!defaultRegion) {
    defaultRegion = countryList[defaultContinent] && Object.keys(countryList[defaultContinent])[0]
  }
  return { defaultCountries, defaultContinent, defaultRegion, defaultSelectedAllRegions }
}

let changedByAction = false

export const RegionsServicedInput = MUIStyled(({ className, onChange, value = [] }) => {
  const { countries: countryList } = useSelector((state) => state.layout, shallowEqual)
  const countries = useMemo(() => {
    return generateCountriesMap(countryList)
  }, [countryList])
  const { t } = useTranslation()
  let initialSelectedValues = generateSelectingCountries(value, countries)

  const [selectedValues, setSelectedValues] = useState({
    focusingContinent: initialSelectedValues.defaultContinent,
    focusingRegion: initialSelectedValues.defaultRegion,
    selectingCountries: initialSelectedValues.defaultCountries,
    selectedAllRegions: initialSelectedValues.defaultSelectedAllRegions,
  })

  useEffect(() => {
    const defaultValues = generateSelectingCountries(value, countries)
    if (!changedByAction) {
      setSelectedValues({
        focusingContinent: defaultValues.defaultContinent,
        focusingRegion: defaultValues.defaultRegion,
        selectingCountries: defaultValues.defaultCountries,
        selectedAllRegions: defaultValues.defaultSelectedAllRegions,
      })
    }
  }, [value, setSelectedValues, countries])

  const updateValueChangedToParent = (newSelectingCountries) => {
    if (typeof onChange === 'function') {
      onChange(
        null,
        Object.values(newSelectingCountries).map(({ country, name, region, continent }) => ({
          country: country || name,
          region,
          continent,
        }))
      )
    }
  }

  const updateSelectingCountries = (selectingCountries) => {
    setSelectedValues({ ...selectedValues, selectingCountries })
    updateValueChangedToParent(selectingCountries)
  }

  const handleSelectContinent = (continent) => () => {
    changedByAction = true
    setSelectedValues({
      ...selectedValues,
      focusingContinent: continent,
      focusingRegion: Object.keys(countries[continent])[0],
    })
  }

  const handleSelectRegion = (region) => () => {
    changedByAction = true
    setSelectedValues({ ...selectedValues, focusingRegion: region })
  }

  const handleSelectAllCountriesInRegion = (region) => (event) => {
    changedByAction = true
    const { selectingCountries, focusingContinent, selectedAllRegions } = selectedValues
    let newSelectingCountries = { ...selectingCountries }
    countries[focusingContinent][region].forEach((country) => {
      if (event.target.checked) {
        newSelectingCountries[country.name] = country
      } else {
        delete newSelectingCountries[country.name]
      }
    })

    let newSelectedAllRegions = { ...selectedAllRegions }
    if (event.target.checked) {
      newSelectedAllRegions[region] = region
    } else {
      delete newSelectedAllRegions[region]
    }

    setSelectedValues({
      ...selectedValues,
      selectingCountries: newSelectingCountries,
      focusingRegion: region,
      selectedAllRegions: newSelectedAllRegions,
    })

    updateValueChangedToParent(newSelectingCountries)
  }

  const toggleCountrySelection = (country) => () => {
    changedByAction = true
    const { selectingCountries, selectedAllRegions, focusingRegion, focusingContinent } = selectedValues
    const newSelectingCountries = { ...selectingCountries }

    if (Boolean(selectingCountries[country.name])) {
      delete newSelectingCountries[country.name]
      const newSelectedAllRegions = { ...selectedAllRegions }
      delete newSelectedAllRegions[focusingRegion]
      setSelectedValues({ ...selectedValues, selectedAllRegions: newSelectedAllRegions })
    } else {
      newSelectingCountries[country.name] = country

      let allCountriesSelected = true
      const focusingRegionCountries = countries[focusingContinent][focusingRegion]
      for (let i = 0; i < focusingRegionCountries.length; i++) {
        if (!Boolean(newSelectingCountries[focusingRegionCountries[i].name])) {
          allCountriesSelected = false
          break
        }
      }
      if (allCountriesSelected) {
        setSelectedValues({
          ...selectedValues,
          selectedAllRegions: { ...selectedAllRegions, [focusingRegion]: focusingRegion },
        })
      }
    }

    updateSelectingCountries(newSelectingCountries)
  }

  return (
    <div className={['country-select', className].join(' ')}>
      <div className="col">
        <div className="col-title">
          <div className="title">{t('regions_serviced_input.continent_column_title')}</div>
        </div>
        <div className="col-content">
          {Object.keys(countries).map((continent, index) => (
            <div
              className={['item-wrapper', selectedValues.focusingContinent === continent ? 'focusing' : ''].join(' ')}
              key={`continent-${index}`}
            >
              <Button onClick={handleSelectContinent(continent)}>{t(continent)}</Button>
            </div>
          ))}
        </div>
      </div>
      <div className="col">
        <div className="col-title">
          <div className="title">{t('regions_serviced_input.region_column_title')}</div>
          <div className="subtitle">{t('regions_serviced_input.region_column_subtitle')}</div>
        </div>
        <div className="col-content">
          {Object.keys(countries[selectedValues.focusingContinent] || {}).map((region) => (
            <div className={['item-wrapper', 'focusing'].join(' ')} key={`region-${region}`}>
              <Button
                onClick={handleSelectRegion(region)}
                title={t(region)}
                className={region === selectedValues.focusingRegion ? 'selecting' : ''}
              >
                {t(region)}
              </Button>
              <Checkbox
                onChange={handleSelectAllCountriesInRegion(region)}
                checked={Boolean(selectedValues.selectedAllRegions[region])}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="col">
        <div className="col-title">
          <div className="title">{t('regions_serviced_input.country_column_title')}</div>
        </div>
        <div className="col-content">
          {countries[selectedValues.focusingContinent] &&
            countries[selectedValues.focusingContinent][selectedValues.focusingRegion]
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((country) => (
                <div className="item-wrapper" key={`region-${country.name}`}>
                  <Button title={t(country.name)} onClick={toggleCountrySelection(country)}>
                    {t(country.name)}
                  </Button>
                  <Checkbox
                    checked={Boolean(selectedValues.selectingCountries[country.name])}
                    onChange={toggleCountrySelection(country)}
                  />
                </div>
              ))}
        </div>
      </div>
    </div>
  )
})(({ theme }) => ({
  display: 'flex',
  '& .col': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '33%',

    '& .col-title': {
      backgroundColor: '#EBEBF0',
      padding: 8,
      color: '#272727',
      height: 64,
      display: 'flex',
      justifyContent: 'space-between',

      '& .title': {
        fontSize: 20,
        lineHeight: 1.4,
        fontWeight: 600,
        textAlign: 'center',
      },

      '& .subtitle': {
        fontSize: 12,
        lineHeight: '15px',
        textAlign: 'center',
        marginTop: 4,
        maxWidth: 50,
      },
    },

    '& .col-content': {
      display: 'flex',
      flexDirection: 'column',

      '& .item-wrapper': {
        borderBottom: '1px solid #EBEBF0',
        borderLeft: '1px solid #EBEBF0',
        borderRight: '1px solid #EBEBF0',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 12,

        '&.focusing': {
          backgroundColor: '#F2F5FF',
        },

        '& button': {
          textTransform: 'none',
          justifyContent: 'flex-start',
          fontSize: 16,
          lineHeight: 1.5,
          whiteSpace: 'nowrap',
          textOverflow: 'clip',
          overflow: 'hidden',
          maxWidth: '100%',
          flex: 1,
          padding: '8px 16px',
          color: theme.palette.text.primary,

          '&.selecting': {
            color: '#84a3f5',
          },
        },
        '& .MuiCheckbox-root': {
          padding: 8,
        },
      },
    },

    '&:last-of-type .item-wrapper': {
      borderRight: '1px solid #EBEBF0',
    },
  },
}))
