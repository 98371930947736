import { ArrowForwardIos, Visibility } from '@mui/icons-material'
import { IconButton, styled as MUIStyled } from '@mui/material'
import { EditIcon } from '../../components/Icons'
import ProtectedPage from '../../components/ProtectedPage'

const STATIC_PAGE_OPERATIONS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  MANAGEMENT_SUBS: 'MANAGEMENT_SUBS',
}

const OperationIcon = ({ type }) => {
  switch (type) {
    case STATIC_PAGE_OPERATIONS.VIEW:
      return <Visibility />

    case STATIC_PAGE_OPERATIONS.EDIT:
      return <EditIcon />

    case STATIC_PAGE_OPERATIONS.MANAGEMENT_SUBS:
      return <ArrowForwardIos />

    default:
      return null
  }
}

const PageList = [
  {
    title: 'About us',
    operations: [
      { type: STATIC_PAGE_OPERATIONS.VIEW, path: `${process.env.REACT_APP_CLIENT_APP_DOMAIN}/about-us` },
      // { type: STATIC_PAGE_OPERATIONS.EDIT, path: '/static-contents/about-us/edit' },
    ],
  },
  {
    title: 'Home-Brokers',
    operations: [
      { type: STATIC_PAGE_OPERATIONS.VIEW, path: `${process.env.REACT_APP_CLIENT_APP_DOMAIN}/what-is-broker` },
      // { type: STATIC_PAGE_OPERATIONS.EDIT, path: '/static-contents/brokers/edit' },
    ],
  },
  {
    title: 'Home-Buyers/Shippers',
    operations: [
      { type: STATIC_PAGE_OPERATIONS.VIEW, path: `${process.env.REACT_APP_CLIENT_APP_DOMAIN}/what-is-buyer` },
      // { type: STATIC_PAGE_OPERATIONS.EDIT, path: '/static-contents/buyers-shipper/edit' },
    ],
  },
  {
    title: 'Privacy Policy',
    operations: [
      { type: STATIC_PAGE_OPERATIONS.VIEW, path: `${process.env.REACT_APP_CLIENT_APP_DOMAIN}/privacy-policy` },
      { type: STATIC_PAGE_OPERATIONS.EDIT, path: '/static-contents/privacy-policy/edit' },
    ],
  },
  {
    title: 'Terms & Conditions',
    operations: [
      { type: STATIC_PAGE_OPERATIONS.VIEW, path: `${process.env.REACT_APP_CLIENT_APP_DOMAIN}/terms-and-conditions` },
      { type: STATIC_PAGE_OPERATIONS.EDIT, path: '/static-contents/terms-conditions/edit' },
    ],
  },
  {
    title: 'FAQs',
    operations: [{ type: STATIC_PAGE_OPERATIONS.MANAGEMENT_SUBS, path: '/static-contents/faqs' }],
  },
  {
    title: 'China Client Configs',
    operations: [{ type: STATIC_PAGE_OPERATIONS.EDIT, path: '/static-contents/china-client-configs' }],
  },
]

const StaticContentManagementPage = MUIStyled(({ className }) => {
  return (
    <ProtectedPage
      className={['static-content-management-page', className].join(' ')}
      title="Static Content Management"
      breadcrumbs={[{ label: 'Static Contents', path: '/static-contents' }]}
    >
      <div className="page-list">
        {PageList.map(({ title, operations }, index) => (
          <div className="page-item" key={`page-${index}`}>
            <div className="title">{title}</div>
            <div className="operations">
              {operations?.map(({ type, path }, opIndex) => (
                <IconButton key={`page-op-${index}-${opIndex}`} color="primary" href={path}>
                  <OperationIcon type={type} />
                </IconButton>
              ))}
            </div>
          </div>
        ))}
      </div>
    </ProtectedPage>
  )
})({
  '& .page-list': {
    padding: '0 40px',
    maxWidth: 992,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,

    '& .page-item': {
      display: 'flex',

      '& .title': {
        flex: 1,
        color: '#00000080',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 1.3,
      },

      '& .operations': {
        minWidth: 80,
      },
    },
  },

  '& .MuiFab-root': {
    position: 'fixed',
    bottom: 50,
    right: 50,
  },
})

export default StaticContentManagementPage
