import { Close } from '@mui/icons-material'
import { IconButton, InputLabel, MenuItem, styled as MUIStyled, TextField } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getStatesByCountry } from 'services/api'
import { isSuccessRequest } from 'utils/common'
import TextareaAutosize from './TextareaAutoSize'
const initialValue = { country: '', state: '', city: '', address: '', postal_code: '' }
let changedByAction = false

export const AddressInput = MUIStyled(({ value, className, label, onChange, name, error }) => {
  const { countries } = useSelector((state) => state.layout, shallowEqual)
  const { accessToken } = useSelector((state) => state.auth, shallowEqual)
  const [renderValues, setRenderValues] = useState({ ...initialValue, ...value })
  const [stateOptions, setStateOptions] = useState([])

  const countryMap = useMemo(() => {
    let map = {}
    countries.forEach((country) => {
      map[country.name] = country
    })
    return map
  }, [countries])

  const stateMap = useMemo(() => {
    let map = {}
    stateOptions.forEach((state) => {
      map[state.name] = state
    })
    return map
  }, [stateOptions])

  useEffect(() => {
    const fetchStatesByCountry = async () => {
      try {
        const response = await getStatesByCountry(countryMap[renderValues.country].id, accessToken)
        if (isSuccessRequest(response.status)) {
          setStateOptions(response.data.data?.sort((a, b) => (a.name > b.name ? 1 : -1)))
        }
      } catch {}
    }

    if (renderValues.country && accessToken) {
      fetchStatesByCountry()
    }
  }, [renderValues.country, countryMap, accessToken, setStateOptions])

  useEffect(() => {
    if (!changedByAction) {
      setRenderValues(value)
    }
  }, [value, countryMap, stateMap])

  const handleFieldChange = (fieldName) => (event) => {
    changedByAction = true
    let newValues = { ...renderValues }

    newValues[fieldName] = event.target.value

    setRenderValues(newValues)

    if (typeof onChange === 'function') {
      onChange(event, newValues)
    }
  }

  const handleClearField = (fieldName) => () => {
    changedByAction = true
    if (fieldName === 'country') {
      setStateOptions([])
    }

    setRenderValues({ ...renderValues, [fieldName]: initialValue[fieldName] })
  }

  return (
    <div className={['form-input-wrapper address-input', className].join(' ')}>
      <InputLabel>{label}</InputLabel>
      <div className="field-content inputs-wrapper">
        <div className="content-row">
          <TextareaAutosize
            name={name ? `${name}.address` : 'address'}
            placeholder={t('address_input.address_field_label')}
            minRows={3}
            onChange={handleFieldChange('address')}
            error={Boolean(error?.address)}
            helperText={error?.address}
            value={renderValues.address || ''}
          />
        </div>
        <div className="content-row">
          <TextField
            name={name ? `${name}.country` : 'country'}
            select
            label={t('address_input.country_select_label')}
            value={renderValues.country || ''}
            onChange={handleFieldChange('country')}
            InputProps={{
              endAdornment: renderValues.country ? (
                <IconButton onClick={handleClearField('country')}>
                  <Close />
                </IconButton>
              ) : null,
            }}
            error={Boolean(error?.country)}
            helperText={error?.country}
          >
            {countries.map((country) => (
              <MenuItem key={`country-item-${country.id}`} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name={name ? `${name}.state` : 'state'}
            select
            label={t('address_input.state_select_label')}
            disabled={stateOptions.length === 0}
            value={renderValues.state || ''}
            onChange={handleFieldChange('state')}
            InputProps={{
              endAdornment: renderValues.state ? (
                <IconButton onClick={handleClearField('state')}>
                  <Close />
                </IconButton>
              ) : null,
            }}
            error={Boolean(error?.state)}
            helperText={error?.state}
          >
            {stateOptions.map((state, index) => (
              <MenuItem key={`state-item-${state.id}-${index}`} value={state.name}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="content-row">
          <TextField
            label={t('address_input.city_input_label')}
            name={name ? `${name}.city` : 'city'}
            onChange={handleFieldChange('city')}
            value={renderValues.city || ''}
            error={Boolean(error?.city)}
            helperText={error?.city}
          />
          <TextField
            label={t('address_input.postal_code_input_label')}
            name={name ? `${name}.postal_code` : 'postal_code'}
            value={renderValues.postal_code || ''}
            error={Boolean(error?.postal_code)}
            helperText={error?.postal_code}
            onChange={handleFieldChange('postal_code')}
          />
        </div>
      </div>
    </div>
  )
})(({ theme }) => ({
  display: 'flex',

  '& .field-label': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    color: theme.palette.text.primary,
  },

  '& .field-content': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    flex: 1,
  },

  '& .content-row': {
    display: 'flex',
    columnGap: 10,
  },

  '& .MuiTextField-root': {
    flex: 1,

    '& .MuiSelect-select, .MuiInputBase-input': {
      paddingTop: 12.5,
      paddingBottom: 12.5,
    },
  },

  '& .textarea-autosize-field': {
    flex: 1,
  },

  '& .MuiIconButton-root': {
    marginRight: 24,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
